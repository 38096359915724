<template>
  <div class="home_page">
    <div class="title">
      <span>{{ $t('lang.swap510') }}</span>
      <div class="choose_category">
        <img @click="editionSwitchLanguage" src="@/assets/img/select_record.png" class="category_img" />
        <div v-show="editionCondition">
          <div class="multilingual">
            <div v-for="(item, index) of edition" :key="index" @click="editionSwitching(item)" :class="item.id == editionLanguage.id ? 'special' : 'prohibit'" class="multilingual_prohibit">
              <span>{{ item.text }}</span>
            </div>
          </div>
          <div class="overlay" @click="handleAction"></div>
        </div>
      </div>
    </div>
    <span></span>
    <div class="record">
      <div class="name">
        <span class="left">{{ $t('lang.swap493') }}</span>
        <span class="center">{{ $t('lang.swap511') }}</span>
        <span class="right">{{ $t('lang.swap512') }}</span>
      </div>
      <div v-if="translateList.length == 0" class="nothing">
        <img src="@/assets/img/nothingBlank.png" class="nothing_img" />
        <span>{{ $t('lang.swap118') }}</span>
      </div>
      <van-list v-else v-model="loading" :finished="finished" finished-text=" " @load="getTranslateList" class="list">
        <div v-for="(item, index) of translateList" :key="index" class="record_li" :class="index == translateList.length - 1 ? '' : 'line'">
          <div class="li_left">
            <span class="left">{{ item.created_time.substring(0, 10) }}</span>
            <span class="center">{{ item.type_txt }}</span>
            <span class="right">{{ item.release_amount }}</span>
          </div>
          <!-- <div class="li_right">
            <span class="expenses">{{item.created_time}}</span>
          </div>-->
        </div>
      </van-list>
    </div>
  </div>
</template>
  
  <script>
import { cardApi } from '../../api/index';
import { mapState } from 'vuex';
export default {
  data() {
    return {
      translateList: [],
      page: 1, //页码
      pageSize: 10, //页数
      totalPage: 0, //总页数
      loading: false,
      finished: false,
      editionCondition: false,
      editionLanguage: undefined,
      edition: []
    };
  },
  created() {
    this.init();
    this.watchLanguage();
  },
  methods: {
    init() {
      if (this.account) {
        // this.walletBehaviors();
        this.walletBehaviors();
      }
    },
    watchLanguage() {
      let _this = this;
      window.addEventListener('setItemEvent', function (e) {
        if (e.key === 'lang') {
          setTimeout(() => {
            _this.translateList = [];
            _this.page = 1;
            _this.loading = false;
            _this.finished = false;
            _this.init();
          }, 1000);
        }
      });
    },
    walletBehaviors () {
      cardApi.cardReleastTypes(this.account).then((res) => {
        if (res.code == 200) {
          this.edition = res.data
          this.editionLanguage = res.data[0]
          this.getTranslateList()
        } else {
          this.$toast(res.msg);
        }
      })
    },
    //获取账单
    getTranslateList() {
      let data = {
        page: this.page,
        page_size: this.pageSize,
        type:this.editionLanguage.id
      };
      cardApi.cardReleaseList(this.account, data).then(res => {
        if (res.code == 200) {
          this.translateList = this.translateList.concat(res.data.list);
          this.totalPage = Math.ceil(res.data.total_count / this.pageSize);
          this.page++;
          this.loading = false;
          if (this.page > this.totalPage) return (this.finished = true);
        } else {
          this.$toast(res.msg);
        }
      });
    },
    editionSwitching(item) {
      this.editionLanguage = item;
      this.editionCondition = false;
      this.translateList = [];
      this.loading = false;
      this.page = 1;
      this.finished = false;
      this.getTranslateList();
    },
    editionSwitchLanguage() {
      this.editionCondition = true;
    },
    handleAction() {
      this.editionCondition = false;
    },
  },
  watch: {
    account() {
      this.init();
    },
  },
  computed: {
    ...mapState(['account', 'net']),
  },
};
</script>
  
  <style lang="less" scoped>
.home_page {
  background: url('~@/assets/img/second_bg.png');
  background-size: 100% 100%;
  padding: 20px;
  min-height: 800px;
}
.title {
  display: flex;
  justify-content: space-between;
  color: #fff;
  // margin: 20px 0;
  .choose_category {
    position: relative;
    .category_img {
      width: 24px;
      height: 24px;
      display: block;
    }
    .multilingual {
      position: absolute;
      top: 34px;
      right: 0;
      font-size: 14px;
      width: 140px;
      color: #ffffff;
      background: #232b3e;
      box-shadow: 0px 2px 6px 0px rgba(16, 27, 82, 0.5);
      border-radius: 6px;
      text-align: center;
      padding: 4px;
      z-index: 999;
      div {
        padding: 4px 1px;
        display: flex;
        color: #ffffff;

        // justify-content: flex-start;
        // align-items: flex-start;
        align-items: center;
        justify-content: center;
        .country {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
      }
      .special {
        color: #f39714;
      }
      .prohibit {
        color: #ffffff;
      }
    }
    .overlay {
      position: fixed;
      z-index: 998;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.144);
      margin-top: 48px;
    }
  }
}
.record {
  background: #232b3e;
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.11);
  border-radius: 4px;
  padding: 10px 0;
  margin-top: 20px;
  .name {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    padding: 0 10px 10px;
    border-bottom: 1px solid #474e5f;
    span {
      font-size: 14px;
    }
    .left {
      flex: 1;
      text-align: left;
    }
    .center {
      flex: 1;
      text-align: center;
    }
    .right {
      flex: 1;
      text-align: right;
    }
  }
  .choose {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    border-bottom: 3px solid #f39714;
  }
  .line {
    border-bottom: 1px solid #d6e0f2;
  }
  .list {
    padding: 0 10px;
  }
  .record_li {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    // margin-top: 16px;
    // background-color: #232B3E;
    padding: 10px 0;
    .li_left {
      display: flex;
      justify-content: space-between;
      // flex-direction: column;
      font-size: 14px;
      color: #d1d1d1;
      .left {
        flex: 1;
        text-align: left;
      }
      .center {
        flex: 1;
        text-align: center;
      }
      .right {
        flex: 1;
        text-align: right;
      }
    }
    .li_right {
      display: flex;
      // flex-direction: column;
      // align-items: flex-end;
      font-size: 14px;
      font-weight: bold;
      .income {
        color: #f6bb31;
      }
      .expenses {
        color: #999;
        font-size: 12px;
        margin-top: 6px;
      }
    }
  }
  .nothing {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    // height: 60px;
    padding-bottom: 40px;
    .nothing_img {
      width: 200px;
    }
  }
}
</style>