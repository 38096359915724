<template>
  <div class="means">
    <div>
      <div v-if="meansList.length == 0" class="nothing">
        <img src="@/assets/img/nothingBlank.png" class="nothing_img" />
        <span>{{$t('lang.swap118')}}</span>
      </div>
      <div v-else   v-for="(item,index) of meansList"  :key="index">
      <div
        class="means_li"
        :class="index == meansList.length -1?'':'line'"
        v-if="item.usable != 0"
      >
        <span>{{item.token_name}}</span>
        <span class="expenses">{{item.usable}}</span>
        <!-- <span class="income">+ {{item.in_amount}} {{item.in_token_name}}</span> -->
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { walletApi } from '../../api/index';
export default {
  data() {
    return {
      meansList: [],
      page: 1, //页码
      pageSize: 10, //页数
      totalPage: 0, //总页数
      loading: false,
      finished: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.account) {
        this.getWalletList();
      }
    },
    getWalletList() {
      walletApi.getWalletList(this.account).then(res => {
        this.meansList = res.data;
      });
    },
  },
  watch: {
    account() {
      this.init();
    },
  },
  computed: {
    ...mapState(['account']),
  },
};
</script>

<style lang="less" scoped>
.means {
  background: #ffffff;
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.11);
  border-radius: 20px;
  padding: 10px 10px;
  margin-top: 20px;
  .means_li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // font-size: 14px;
    font-weight: bold;

    // margin-top: 16px;
    padding: 20px 0;
    .income {
      color: #f39714;
    }
    .expenses {
      color: #2fa80a;
    }
  }
  .line {
    border-bottom: 1px solid #d6e0f2;
  }
  .nothing {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    // height: 60px;
    .nothing_img {
      width: 200px;
    }
  }
}
</style>