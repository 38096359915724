<template>
  <div class="home_page">
    <div class="title">
      <div>
        <span :class="chooseIndex ==0?'choose':''"
              @click="chooseClick(0)">{{ $t('lang.swap382') }}</span>
        <span :class="chooseIndex ==1?'choose':''"
              @click="chooseClick(1)">{{ $t('lang.swap383') }}</span>
      </div>
      <span @click="switchovers">{{ $t('lang.swap279') }}</span>
    </div>
    <div class="filter">
      <span>{{ $t('lang.swap384') }}</span>
      <span>{{ $t('lang.swap385') }}</span>
    </div>
    <van-list v-model="loading"
              :finished="finished"
              finished-text=" "
              @load="marketTrades"
              class="list"
              :immediate-check="false">
      <div v-for="(item,index) of orderList"
           :key="index"
           class="li">
        <div class="universal">
          <span class="special">{{item.nickname || $t('lang.swap386')}}</span>
          <span>{{ $t('lang.swap387') }}：{{item.created_time}}</span>
        </div>
        <div class="universal">
          <span>{{ $t('lang.swap181') }}:{{item.remain_amount}}</span>
          <span>{{ $t('lang.swap90') }}</span>
        </div>
        <div class="universal">
          <div>
            <span>{{ $t('lang.swap290') }}:</span>
            <span class="special">{{chooseIndex == 0 ? item.t1_name : item.t2_name}}</span>
          </div>
          <span class="special">{{item.price}}</span>
        </div>
        <div class="universal">
          <img :src=" chooseIndex ==0 ? imageDomain + item.t1_logo : imageDomain + item.t2_logo"
               class="li_img" />
          <van-button type="primary"
                      class="button"
                      @click="sell(item)">{{chooseIndex ==0?$t('lang.swap388'):$t('lang.swap389')}}</van-button>
        </div>
      </div>
    </van-list>
    <div class="buy_and_sell">
      <div class="price">
        <span class="price_text">{{newPrice}}</span>
        <span>{{ $t('lang.swap390') }}</span>
      </div>
      <div class="right">
        <van-button type="primary"
                    class="buy_button"
                    @click="release(0)">{{ $t('lang.swap391') }}</van-button>
        <van-button type="primary"
                    class="sell_button"
                    @click="release(1)">{{ $t('lang.swap392') }}</van-button>
      </div>
    </div>
    <van-popup v-model="releaseShow"
               position="bottom">
      <ReleasesPopup :popupIndex="releaseIndex"
                     @backtrack="releaseClose"></ReleasesPopup>
    </van-popup>
    <van-popup v-model="transactionShow"
               position="bottom">
      <TransactionPopup :popupIndex="popupIndex"
                        :obj="sellObj"
                        @backtrack="backtrackPopup"></TransactionPopup>
    </van-popup>
  </div>
</template>

<script>
import ReleasesPopup from './releasesPopup.vue';
import TransactionPopup from './transactionPopup.vue';
import { c2cApi, walletApi, newsLetterApi } from '@/api/index';
import { mapState } from 'vuex';
export default {
  data () {
    return {
      chooseIndex: 0, //0:买, 1:卖
      releaseShow: false, //卖入买出弹窗状态
      transactionShow: false, //发布弹窗状态
      popupIndex: 0, //0:买, 1:卖
      orderList: [],
      page: 1, //页码
      pageSize: 10, //页数
      totalPage: 0, //总页数
      finished: false,
      loading: false,
      sellObj: undefined, //售卖价格
      releaseIndex: 0,
      newPrice: 0,
      vrtObj: undefined,
      imageDomain: '',
    };
  },
  methods: {
    init () {
      if (this.account) {
        this.basicConfig();
      }
    },
    release (index) {
      this.releaseIndex = index;
      this.releaseShow = true;
    },
    getLastPrice () {
      let data = {
        token_id: this.vrtObj.token_id,
      };
      c2cApi.latestPrice(data, this.account).then(res => {
        // console.log(this.newPrice,'this.newPrice');
        this.newPrice = res.data;
      });
    },
    // 资产列表
    getWalletList () {
      walletApi.getWalletList(this.account).then(res => {
        this.walletList = res.data;
        for (let i of this.walletList) {
          if (i.token_name == 'VRT') {
            this.vrtObj = i;
          }
        }
        this.getLastPrice();
      });
    },
    //获取订单
    marketTrades () {
      let data = {
        page: this.page,
        page_size: this.pageSize,
        conf_id: 0,
        trade_type: this.chooseIndex,
      };
      c2cApi.marketTrades(data, this.account).then(res => {
        if (res.code == 200) {
          this.orderList = this.orderList.concat(res.data.list);
          this.totalPage = Math.ceil(res.data.total_count / this.pageSize);
          this.page++;
          this.loading = false;
          if (this.page > this.totalPage) return (this.finished = true);
        } else {
          this.$toast(res.msg);
        }
      });
    },
    sell (item) {
      this.sellObj = item;
      this.transactionShow = true;
    },
    chooseClick (i) {
      this.page = 1;
      this.totalPage = 0;
      this.orderList = [];
      this.chooseIndex = i;
      this.popupIndex = i;
      this.marketTrades();
    },
    //关闭弹窗
    occlude () {
      this.releaseShow = false;
      this.transactionShow = false;
    },
    backtrackPopup () {
      this.page = 1;
      this.totalPage = 0;
      this.orderList = [];
      this.init();
      this.transactionShow = false;
    },
    releaseClose () {
      this.page = 1;
      this.totalPage = 0;
      this.orderList = [];
      this.init();
      this.releaseShow = false;
    },
    switchovers () {
      this.$router.push({ name: 'c2cRecord' });
      // this.$Router.pushPage({
      //   name: route
      // })
    },
    //图片域名
    basicConfig () {
      newsLetterApi.basicConfig().then(res => {
        if (res.code == 200) {
          // console.log(res.data);
          this.imageDomain = res.data.image_domain;
          this.marketTrades();
          this.getWalletList();
          // this.basicBanner();
        } else {
          this.$toast(res.msg);
        }
      });
    },
  },
  created () {
    this.init();
  },
  computed: {
    ...mapState(['account']),
  },
  watch: {
    account () {
      this.init();
    },
  },
  components: {
    ReleasesPopup,
    TransactionPopup,
  },
};
</script>

<style lang="less" scoped>
.home_page {
  background: url('~@/assets/img/second_bg.png');
  background-size: 100% 100%;
  padding: 20px;
  min-height: 800px;
}
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  font-size: 12px;
  div {
    display: flex;
    align-items: center;
    font-size: 16px;
    span {
      color: #999;
      border-bottom: 1px solid rgba(0, 0, 0, 0);
      margin-right: 20px;
      padding-bottom: 6px;
    }
    .choose {
      color: #fff;
      border-bottom: 2px solid #23acdd;
    }
  }
}
.filter {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
  margin-bottom: 20px;
}
.list {
  margin-bottom: 60px;
}
.li {
  border-top: 1px solid #666;
  padding: 20px 0 10px;
  .universal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    color: #999;
    margin-bottom: 10px;
  }
  .special {
    font-size: 14px;
    color: #fff;
  }
  .li_img {
    width: 30px;
    height: 30px;
    display: block;
  }
  .button {
    height: auto;
    background: linear-gradient(180deg, #1ad9f3 0%, #2a88cc 100%);
    border-radius: 2px 2px 2px 2px;
    border: 0;
    padding: 6px 30px;
  }
}
.buy_and_sell {
  position: fixed;
  bottom: 45px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #1e1e1e;
  padding: 10px;
  .price {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: #999;
    .price_text {
      font-size: 16px;
      color: #fff;
    }
  }
  .right {
    display: flex;
    .buy_button {
      height: 40px;
      font-size: 14px;
      background: #21b5e2;
      border-radius: 2px 2px 2px 2px;
      border: 0;
      padding: 6px 40px;
      margin-right: 10px;
    }
    .sell_button {
      height: 40px;
      font-size: 14px;
      background: #26a17b;
      border-radius: 2px 2px 2px 2px;
      border: 0;
      padding: 6px 40px;
    }
  }
}
</style>