<template>
  <div>
    <!-- <div @click="switchovers"
         class="mine">
      <span>{{$t('lang.swap279')}}</span>
    </div> -->

    <div class="content">
      <div class="mine">
        <span>{{merchandiseShow?$t('lang.swap288'):$t('lang.swap289')}}</span>
        <img @click="switchovers"
             src="@/assets/img/bill.png"
             class="bill_img" />
      </div>
      <div class="univalent">
        <span>{{ information.price }}</span>
        <span style="font-size: 12px">{{ information.t2_name }}/{{ information.t1_name }}</span>
      </div>
      <div class="available">
        <span>{{$t('lang.swap280')}}：{{ balance }} {{ merchandiseShow ?   information.t2_name : information.t1_name}}</span>
      </div>
      <div class="field">
        <van-field v-model="oversell"
                   :placeholder="merchandiseShow ? $t('lang.swap274') : $t('lang.swap281') "
                   type="number"
                   class="input"> </van-field>
        <div class="complete">
          <span>{{  information.t1_name}}</span>
          <!-- <span class="line"></span>
          <span @click="complete">{{$t('lang.swap23')}}</span> -->
        </div>
      </div>
      <!-- <div class="field">
        <van-field v-model="password"
                   placeholder="请输入支付密码"
                   type="number"
                   class="input"> </van-field>
        <div class="complete">
          <span @click="complete">{{$t('lang.swap172')}}</span>
        </div>
      </div> -->
      <!-- <div class="quota">
        <span>{{$t('lang.swap271')}}：{{ information.min_trade_amount }}-{{ information.max_trade_amount }} {{ information.t1_name }} </span>
      </div> -->
      <div class="quota">
        <span v-if="merchandiseShow">{{$t('lang.swap349')}}：{{oversell * information.price}} {{ information.t2_name }} </span>
        <span v-else>{{$t('lang.swap350')}}：{{oversell * information.price}} {{ information.t2_name }} </span>
      </div>
      <!-- <div class="field">
        <van-field v-model="password"
                   placeholder="请输入支付密码"
                   type="password"
                   class="input">
        </van-field>
      </div> -->
      <div class="info">
        <div>
          <span class="name">{{ information.nickname }}</span>
        </div>
        <div>
          <span>{{$t('lang.swap282')}}：{{ information.remain_amount }}</span>
        </div>
      </div>
      <div>
        <van-button v-if="merchandiseShow"
                    type="primary"
                    class="immediately"
                    @click="buy">{{$t('lang.swap283')}}</van-button>
        <van-button v-else
                    type="primary"
                    class="immediately"
                    @click="sell">{{$t('lang.swap284')}}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { walletApi, c2cApi } from '../../api/index';
import { mapState } from 'vuex';
export default {
  data () {
    return {
      oversell: 0,
      password: undefined,
      merchandiseShow: false,
      information: {}, //路由值
      balance: '', //余额
      password: '', //密码
      needPay: 0
    };
  },
  created () {
    this.merchandiseShow = this.$route.query.merchandiseShow;
    this.information = JSON.parse(this.$route.query.item);
    this.init();
  },
  methods: {
    init () {
      if (this.account) {
        this.getWalletInfo();
      }
    },
    //全部
    complete () {
      this.oversell = this.information.max_trade_amount * 1;
    },
    buy () {
      if (this.oversell == 0) {
        this.$toast(this.$t('lang.swap274'));
        return;
      } else if (this.oversell > this.information.remain_amount) {
        this.$toast(this.$t('lang.swap369'));
        return;
      } else if (this.oversell * this.information.price > this.balance) {
        this.$toast(this.$t('lang.swap276'));
        return;
      } else {
        const domain = {
          name: 'SER',
          version: 'v1.0',
          chainId: this.net,
          verifyingContract: '',
        };

        const EIP712Domain = [
          { name: 'name', type: 'string' },
          { name: 'version', type: 'string' },
          { name: 'chainId', type: 'uint256' },
        ];

        const Primary = [
          { name: 'from', type: 'address' },
          { name: 'content', type: 'string' },
        ];

        const message = {
          from: this.account,
          content: 'C2C Buy',
        };

        const data = JSON.stringify({
          types: {
            EIP712Domain,
            Primary,
          },
          domain,
          primaryType: 'Primary',
          message,
        });
        try {
          const from = this.account;
          ethereum
            .request({
              method: 'eth_signTypedData_v4',
              params: [from, data],
            })
            .then(splitSignature => {
              let data = {
                signature: splitSignature,
                id: this.information.id * 1,
                amount: this.oversell * 1,
              };
              c2cApi.buy(data, this.account).then(res => {
                if (res.code == 200) {
                  this.$toast(this.$t('lang.swap277'));
                  // this.init();
                  this.$router.go(-1);
                } else {
                  this.$toast(res.msg);
                }
              });
            });
        } catch (err) {
          console.error(err);
        }
      }
    },
    sell () {
      if (this.oversell == 0) {
        this.$toast(this.$t('lang.swap274'));
        return;
      }  else if (this.oversell > this.balance) {
        this.$toast(this.$t('lang.swap276'));
        return;
      } else {
        const domain = {
          name: 'SER',
          version: 'v1.0',
          chainId: this.net,
          verifyingContract: '',
        };

        const EIP712Domain = [
          { name: 'name', type: 'string' },
          { name: 'version', type: 'string' },
          { name: 'chainId', type: 'uint256' },
        ];

        const Primary = [
          { name: 'from', type: 'address' },
          { name: 'content', type: 'string' },
        ];

        const message = {
          from: this.account,
          content: 'C2C Sell',
        };

        const data = JSON.stringify({
          types: {
            EIP712Domain,
            Primary,
          },
          domain,
          primaryType: 'Primary',
          message,
        });
        try {
          const from = this.account;
          ethereum
            .request({
              method: 'eth_signTypedData_v4',
              params: [from, data],
            })
            .then(splitSignature => {
              let data = {
                signature: splitSignature,
                id: this.information.id * 1,
                amount: this.oversell * 1,
              };
              c2cApi.sell(data, this.account).then(res => {
                if (res.code == 200) {
                  this.$toast(this.$t('lang.swap278'));
                  // this.init();
                  this.$router.go(-1);
                } else {
                  this.$toast(res.msg);
                }
              });
            });
        } catch (err) {
          console.error(err);
        }
      }
    },
    //获取单个币种信息
    getWalletInfo () {
      let data = {
        token_id: this.merchandiseShow ? this.information.t2_id : this.information.t1_id,
      };
      walletApi.getWalletInfo(data, this.account).then(res => {
        if (res.code == 200) {
          this.balance = res.data.usable;
        } else {
          this.$toast(res.msg);
        }
      });
    },
    //跳转
    switchovers (route) {
      this.$router.push({ name: 'ordersRecord' });
    },
  },
  watch: {
    account () {
      this.init();
    },
  },
  computed: {
    ...mapState(['account', 'net']),
  },
};
</script>

<style lang="less" scoped>
.content {
  background: #ffffff;
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.11);
  border-radius: 20px;
  padding: 20px 10px;
  margin-top: 20px;
  .mine {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    // margin: 20px 0 10px;
    font-size: 18px;
    margin-bottom: 20px;
    .bill_img {
      display: block;
      width: 24px;
      height: 24px;
    }
  }
  .univalent {
    font-size: 18px;
    font-weight: bold;
    color: #1263f1;
  }
  .available {
    font-size: 12px;
    margin-bottom: 30px;
  }
  .field {
    display: flex;
    align-items: center;
    margin-top: 10px;
    .complete {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      margin-left: 10px;
      .line {
        width: 1px;
        height: 22px;
        display: block;
        background: #e5e5e5;
        margin: 0 6px;
      }
    }

    .input {
      flex: 3;
      background: #f7f6f6;
      border-radius: 6px;
      color: #666;
      padding: 4px 6px !important;
    }
  }
  .quota {
    font-size: 14px;
    color: #666;
    margin-top: 4px;
  }
  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    color: #666666;
    border: 1px solid #1263f1;
    border-radius: 6px;
    padding: 4px 10px;
    margin-top: 30px;
    .name {
      font-size: 16px;
      color: #333333;
    }
    div {
      display: flex;
      align-items: center;
    }
  }
  .immediately {
    width: 100%;
    height: auto;
    border-radius: 30px;
    padding: 10px 0;
    margin-top: 40px;
  }
}
</style>