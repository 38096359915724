<template>
  <div class="home-page">
    <span class="title">1.{{$t('lang.swap434')}}</span>
    <div class="feedback">
      <van-field
        v-model="contentInput"
        rows="5"
        :autosize="{ maxHeight: 100, minHeight: 100 }"
        type="textarea"
        maxlength="800"
        :placeholder="$t('lang.swap437')"
        show-word-limit
      />
    </div>

    <span class="title">2.{{$t('lang.swap435')}}</span>
    <div :style="{'margin-top':'10px'}">
      <van-uploader
        v-model="fileList"
        :after-read="afterRead"
        :before-delete="del"
        multiple
        :max-count="3"
      />
    </div>
    <div class="button">
      <van-button type="primary" class="feedbackBtn" @click="toFeedBack">{{$t('lang.swap436')}}</van-button>
    </div>
  </div>
</template>
  
<script>
import { mapState } from 'vuex';
import { usApi, basicApi } from '../../api/index';
import Web3 from 'web3';
export default {
  data() {
    return {
      contentInput: '', //内容
      addressInput: '',
      fileList: [],
      imgList: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.account) {
      }
    },
    toFeedBack() {
      if (this.imgList.length == 0) {
        this.$toast(this.$t('lang.swap438'));
        return;
      }
      if (this.contentInput == '') {
        this.$toast(this.$t('lang.swap439'));
        return;
      }
      let web3 = new Web3(window.ethereum);
      web3.eth.personal.sign(web3.utils.fromUtf8('Feedback Post'), this.account, (err, res) => {
        let data = {
          cate_id: 1,
          content: this.contentInput,
          img_arr: this.imgList,
          signature: res,
        };
        basicApi.basicFeedbackDo(data, this.account).then(res => {
          if (res.code == 200) {
            this.$toast(this.$t('lang.swap203'));
            this.$router.go(-1);
          } else {
            this.$toast(res.msg);
          }
        });
      });
    },
    del(eve, detail) {
      this.fileList.splice(detail.index, 1);
      this.imgList.splice(detail.index, 1);
    },
    //上传图片
    afterRead(event) {
      // 此时可以自行将文件上传至服务器
      if (event.file.type !== 'image/jpeg' && event.file.type !== 'image/bmp' && event.file.type !== 'image/png' && event.file.type !== 'image/gif') {
        this.fileList.splice(this.fileList.length - 1, 1);
        return this.$toast(this.$t('lang.swap130'));
      }
      event.status = 'uploading';
      // 状态提示
      event.message = this.$t('lang.swap131');

      let fd = new FormData();
      fd.append('file', event.file);
      fd.append('type', 4);
      // let data = {
      //   type: 4,
      //   file: fd
      // };
      usApi.uploadImage(fd, this.account).then(res => {
        // this.avatar = res.data;
        if (res.code) {
          event.status = 'done';
          event.message = this.$t('lang.swap132');
          this.imgList.push(res.data.save_path);
        } else {
          event.status = 'failed';
          event.message = this.$t('lang.swap133');
          this.$toast(res.msg);
        }
      });
    },
  },
  computed: {
    ...mapState(['account']),
  },
  watch: {
    account() {
      this.init();
    },
  },
};
</script>
      
  <style lang="less" scoped>
.home-page {
  background: url('~@/assets/img/second_bg.png');
  background-size: 100% 100%;
  padding: 20px;
  position: relative;
  height: 800px;
  
  display: flex;
  flex-direction: column;
  .title {
    font-size: 16px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #ffffff;
    margin-top: 20px;
  }
  .feedback {
    background: #232B3E;
    border-radius: 4px 4px 4px 4px;
    padding: 5px;
    margin-top: 10px;
  }

  /deep/ .van-cell {
    color: #fff;
    background-color: #232B3E;
  }
  /deep/ .van-field__control {
    color: rgba(255,255,255,0.4);
    padding: 5px;
  }
  /deep/ .van-field__word-limit {
    color: #818181;
  }
  /deep/ .van-uploader__upload {
    border-radius: 3px 3px 3px 3px;
    opacity: 1;
    border: 2px solid #818181;
    background-color:#818181;
    opacity: 0.3;
  }

  .button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    .feedbackBtn {
      width: 100%;
      height: auto;
      font-size: 14px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #fff;
      background: linear-gradient(180deg, #1ad9f3 0%, #2a88cc 100%);
      border-radius: 2px 2px 2px 2px;
      padding: 6px 0;
    }
  }
}
</style>