<template>
  <div class="home_page">
    <div class="title">
      <span>{{ $t('lang.swap404') }}</span>
    </div>
    <div>
      <div v-if="noticeList.length == 0"
           class="bare">
        <img src="@/assets/img/barea.png"
             class="bare_img" />
        <span>{{ $t('lang.swap405') }}</span>
      </div>
      <van-list v-else
                v-model="loading"
                :finished="finished"
                finished-text=" "
                @load="getNoticeList">
        <div v-for="(item,index) of noticeList"
             :key="index"
             class="li"
             @click="switchovers('noticeDetail',item)">
          <div class="li_top">
            <div class="li_left">
              <span>{{item.title}}</span>
              <span class="hint">{{item.author}}</span>
            </div>
            <img src="@/assets/img/rightArrows.png"
                 class="li_img" />
          </div>
          <div class="text">
            <span>{{item.content}}</span>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { basicApi } from '@/api/index';
export default {
  data () {
    return {
      noticeList: [],
      page: 1,
      pageSize: 10, //页数
      totalPage: 0, //总页数
      loading: false,
      finished: false,
    };
  },
  methods: {
    //跳转
    init () {
      if (this.account) {
        this.getNoticeList();
      }
    },
    getNoticeList () {
      let data = {
        page: this.page,
        page_size: this.pageSize,
      };
      basicApi.noticeList(data).then(res => {
        if (res.code == 200) {
          this.noticeList = this.noticeList.concat(res.data.list);
          this.totalPage = Math.ceil(res.data.total_count / this.pageSize);
          this.page++;
          this.loading = false;
          if (this.page > this.totalPage) return (this.finished = true);
        } else {
          this.$toast(res.msg);
        }
      });
    },
    switchovers (route, item) {
      if (route == '') return this.$toast(this.$t('lang.swap109'));
      this.$router.push({ name: route, query: { item: JSON.stringify(item) } });
      // this.$Router.pushPage({
      //   name: route
      // })
    },
  },
  computed: {
    ...mapState(['account']),
  },
  created () {
    this.init();
  },
  watch: {
    account () {
      this.init();
    },
  },
};
</script>

<style lang="less" scoped>
.home_page {
  background: url('~@/assets/img/second_bg.png');
  background-size: 100% 100%;
  padding: 20px;
  min-height: 800px;
}
.title {
  color: #fff;
  margin: 20px 0;
}
.li {
  background: #232b3e;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 20px;
  .li_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #2a272a;
    padding-bottom: 10px;
    .li_left {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      color: #fff;
      .hint {
        color: #999;
        font-size: 12px;
        margin-top: 6px;
      }
    }
    .li_img {
      width: 10px;
      height: 10px;
    }
  }
  .text {
    color: #999;

    font-size: 12px;
    margin-top: 10px;
  }
}
.bare {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  padding: 40px 0;
  .bare_img {
    width: 120px;
    height: 100px;
    margin-bottom: 8px;
  }
}
</style>