<template>
  <div class="record">
    <div>
      <div v-if="recordsList.length == 0"
           class="nothing">
        <img src="@/assets/img/nothingBlank.png"
             class="nothing_img">
        <span>{{$t('lang.swap118')}}</span>
      </div>
      <van-list v-else
                v-model="loading"
                :finished="finished"
                finished-text=" "
                @load="tradeRecords">
        <div v-for="(item,index) of recordsList"
             :key="index"
             class="record_li"
             :class="index == recordsList.length-1?'':'line'">
          <div class="li_left">
            <span>{{item.behavior_txt}}</span>
            <span class="time">{{item.created_time}}</span>
          </div>
          <div class="li_right">
            <span class="expenses" v-if="item.usable_change < 0">{{item.usable_change}} {{item.token_name}}</span>
            <span class="income" v-else>+{{item.usable_change}} {{item.token_name}}</span>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { c2cApi,walletApi } from '../../api/index';
import { mapState } from 'vuex';
export default {
  data () {
    return {
      merchandiseShow: true,//true:买, false:卖
      recordsList: [],//订单列表
      page: 1, //页码
      pageSize: 10, //页数
      totalPage: 0, //总页数
      loading: false,
      finished: false,
    }
  },
  created () {
    this.init();
  },
  methods: {
    init () {
      if (this.account) {
        this.recordsList = []
        this.tradeRecords()
      }
    },
    tradeRecords () {
      if (!this.account) return
      let data = {
        page: this.page,
        page_size: this.pageSize,

      }
      walletApi.getWalletRecordList(data, this.account).then((res) => {
        if (res.code == 200) {
          console.log(res.data.list);
          this.recordsList = this.recordsList.concat(res.data.list)
          this.totalPage = Math.ceil(res.data.total_count / this.pageSize);
          this.page++;
          this.loading = false;
          if (this.page > this.totalPage) return (this.finished = true);
        } else {
          this.$toast(res.msg);
        }
      })
    }
  },
  watch: {
    account () {
      this.init();
    },
  },
  computed: {
    ...mapState(['account', 'net']),
  },
}
</script>

<style lang="less" scoped>
.record {
  background: #ffffff;
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.11);
  border-radius: 20px;
  padding: 10px 10px;
  margin-top: 20px;
  .title {
    display: inline-block;
    font-size: 14px;
    color: #999999;
    padding: 0 0 4px;
    margin-right: 20px;
  }
  .choose {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    border-bottom: 3px solid #f39714;
  }
  .line {
    border-bottom: 1px solid #d6e0f2;
  }
  .record_li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin-top: 16px;
    padding: 20px 0;
    .li_left {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      .time {
        font-size: 12px;
        color: #999999;
        margin-top: 4px;
      }
    }
    .li_right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      font-size: 14px;
      font-weight: bold;
      .income {
        color: #f39714;
      }
      .expenses {
        color: #2fa80a;
      }
    }
  }
  .nothing {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    // height: 60px;
    .nothing_img {
      width: 200px;
    }
  }
}
</style>