<template>
  <div id="app">
    <Header @reload="reload"></Header>
    <router-view class="router"
                 v-if="loadRouter" />
    <BottomNavigation></BottomNavigation>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { simpleRpcProvider } from '@/utils/provider';
import Header from '@/components/Header.vue';
import BottomNavigation from '@/components/BottomNavigation.vue';
import Sidebar from '@/components/Sidebar.vue';
export default {
  name: 'App',
  data () {
    return {
      loadRouter: true,
    };
  },
  components: {
    Header,
    BottomNavigation,
    Sidebar,
  },
  beforeCreate () {
    this.$store.dispatch('setWebProvider');
    // localStorage.setItem('lang', "zh-CN");
  },
  computed: {
    ...mapState(['account', 'provider', 'gasPrice', 'slippage', 'deadline', 'transactions', 'block', 'expert']),
  },
  async created () {
    const blockNumber = await simpleRpcProvider.eth.getBlockNumber();
    this.$store.commit('SETBLOCK', blockNumber);
    this.timer = setInterval(async () => {
      const blockNumber = await simpleRpcProvider.eth.getBlockNumber();
      this.$store.commit('SETBLOCK', blockNumber);
    }, 6000);
  },
  watch: {
    block () {
      this.handleUpdateTransactions();
    },
  },
  beforeDestroy () {
    window.clearInterval(this.timer);
  },
  methods: {
    async handleUpdateTransactions () {
      if (!this.account) {
        return;
      }
      const allTransactions = Object.values(this.transactions);
      const pendTransactions = allTransactions.filter(item => {
        return !item.receipt && item.from.toLowerCase() == this.account.toLowerCase();
      });
      pendTransactions.forEach((item, index) => {
        simpleRpcProvider.eth.getTransactionReceipt(item.hash).then(receipt => {
          if (receipt) {

            this.$store.commit('SETTRANSACTIONSRECEIPT', {
              hash: item.hash,
              receipt,
            });
            if (document.documentElement.clientWidth < 400) {
              if (receipt.status) {
                this.$message({ type: 'success', title: item.title, message: item.message, hash: receipt.hash });
              } else {
                this.$message({ type: 'error', title: item.title, message: item.message, hash: receipt.hash });
              }
            } else {
              if (receipt.status) {
                this.$message({
                  position: 'top-right',
                  type: 'success',
                  title: item.title,
                  message: item.message,
                  hash: receipt.hash,
                });
              } else {
                this.$message({
                  position: 'top-right',
                  type: 'error',
                  title: item.title,
                  message: item.message,
                  hash: receipt.hash,
                });
              }
            }
          }
        });
      });
    },
    async reload () {
      this.loadRouter = false;
      this.$nextTick(() => {
        this.loadRouter = true;
      });
    },
  },
};
</script>

<style lang="less" scoped>
#app {
  font-family: Roboto, Segoe UI, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
  background: #fff;
  height: calc(100% - 50px);
  image-rendering: -webkit-optimize-contrast;
  .router {
  }
}
</style>
