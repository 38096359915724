import request from '../utils/request';

export default {
    //平台配置
    basicConfig() {
        return request.request({
            url: '/basic/config',
            method: "get",
            headers: {
                'Lang': localStorage.getItem('lang'),
            }
        })
    },

    //多语言
    basicLangList() {
        return request.request({
            url: '/basic/lang/list',
            method: "get",
            headers: {
                'Lang': localStorage.getItem('lang'),
            }
        })
    },


    //首页banner
    basicBanner() {
        return request.request({
            url: '/basic/banner',
            method: "get",
            headers: {
                'Lang': localStorage.getItem('lang') || '',
            }
        })
    },

    //关于我们
    basicAboutus() {
        return request.request({
            url: '/basic/about-us',
            method: "get",
            headers: {
                'Lang': localStorage.getItem('lang') || '',
            }
        })
    },
}