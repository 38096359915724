import request from '../utils/request';

export default {
    //当前币种提币条件
    condition(data, address) {
        return request.request({
            url: '/withdraw/order/condition',
            method: "get",
            headers: {
                'Lang': localStorage.getItem('lang') || '',
                Authorization: address
            },
            params:data
        })
    },
    //提币
    walletOut(data, address) {
        return request.request({
            url: '/withdraw/order/wallet-out',
            method: "post",
            headers: {
                'Lang': localStorage.getItem('lang') || '',
                Authorization: address
            },
            data
        })
    },
    //提币订单
    orderList(data, address) {
        return request.request({
            url: '/withdraw/order/list',
            method: "get",
            headers: {
                'Lang': localStorage.getItem('lang') || '',
                Authorization: address
            },
            data
        })
    }
}