import request from '../utils/request';

export default {
  //闪兑条件
  exchangeCondition(address) {
    return request.request({
      url: '/exchange/condition',
      method: "get",
      headers: {
        Authorization: address,
        'Lang': localStorage.getItem('lang') || '',
      },
    })
  },
  exchangeDo(data, address) {
    return request.request({
      url: '/exchange/do',
      method: "post",
      headers: {
        Authorization: address,
        'Lang': localStorage.getItem('lang') || '',
      },
      data: data,
    })
  },
  //闪兑列表
  exchangeList(data, address) {
    return request.request({
      url: '/exchange/list',
      method: "get",
      headers: {
        Authorization: address,
        'Lang': localStorage.getItem('lang') || '',
      },
      params: data,
    })
  }
}