<template>
  <div>
    <div class="liquidity">
      <span>{{ $t("lang.swap101") }}{{ $t("lang.swap19") }}</span>
      <span @click="liquidity('2')"
            class="toggle">{{ $t("lang.swap54") }}{{ $t("lang.swap19") }}</span>
    </div>
    <div class="liquidity_list">

      <!-- <div>
        <div class="headWrap">
          <div>
            <span>{{ $t("lang.swap52") }}</span>
          </div>
        </div>
        <div class="remove">
          <span>{{ $t("lang.swap71") }}</span>
        </div>
      </div> -->

      <div class="liquidity_explain">
        <div class="liquidity_data">
          <van-collapse v-model="activeNames"
                        v-for="(item, index) of list"
                        :key="index">
            <van-collapse-item :name="index">
              <template #title>
                <div class="title">
                  <!-- <img class="busd" src="@/assets/img/busd.png" alt />
                <img class="busd" src="@/assets/img/busd.png" alt />-->
                  <span>{{ item.fromSymbol }}</span>/
                  <span>{{ item.toSymbol }}</span>
                </div>
                <div>{{ significantDigits((item.lpBalance) / Math.pow(10,18)) }}</div>
              </template>
              <div class="content">
                <div class="detail">
                  <div class="detail_left">
                    <img :src="item.fromLogoURI"
                         class="busd" />
                    <span>{{ $t("lang.swap53") }} {{ item.fromSymbol }}:</span>
                  </div>
                  <div class="detail_amount">
                    <span>{{ (item.fromBalance*1).toFixed(9) }}</span>
                  </div>
                </div>
                <div class="detail">
                  <div class="detail_left">
                    <img :src="item.toLogoURI"
                         class="busd" />
                    <span>{{ $t("lang.swap53") }} {{ item.toSymbol }}:</span>
                  </div>
                  <div class="detail_amount">
                    <span>{{ (item.toBalance*1).toFixed(9) }}</span>
                  </div>
                </div>
                <div class="detail">
                  <div class="detail_left">
                    <span>{{ $t("lang.swap65") }}</span>
                  </div>
                  <div class="detail_amount">
                    <span v-if="item.percentage < 0.01">{{ percentageMin }}</span>
                    <span v-else-if="item.percentage >= 100">
                      {{
                    percentageMax
                    }}
                    </span>
                    <span v-else>{{ item.percentage }}%</span>
                  </div>
                </div>
                <div class="mobility_button">
                  <van-button type="primary"
                              class="swapBtn"
                              @click="handleSwap(item)">{{ $t("lang.swap55") }}</van-button>
                </div>
              </div>
            </van-collapse-item>
          </van-collapse>
        </div>
        <div class="tips">
          <div v-show="mobilityList == []">
            <span>{{ $t("lang.swap100") }}</span>
          </div>
          <div class="interval">
            <span>{{ $t("lang.swap57") }}</span>
            <span @click="liquidity('1')"
                  class="view_other">{{ $t("lang.swap58") }}</span>
          </div>
          <!-- <div 
             @click="liquidity('1')">
          <span>{{ $t("lang.swap102") }}</span>
        </div> -->
        </div>
      </div>

      <div class="button_swapBtn">
        <van-button type="primary"
                    class="swapBtn"
                    @click="liquidity('2')">+ {{ $t("lang.swap56") }}</van-button>
      </div>
      <van-popup v-model="transactionRecordShow"
                 round>
        <transaction-record @listenClose="handleTransactionRecordClose" />
      </van-popup>
      <van-popup v-model="settingShow"
                 round>
        <SettingsModal @listenClose="handleSettingClose" />
      </van-popup>
    </div>
  </div>

</template>

<script>
import TransactionRecord from '@/views/swap/TransactionRecord.vue';
import SettingsModal from '@/components/SettingsModal.vue';
import { getJackPairContract, getJackFactoryContract } from '@/utils/contractHelp';
import { formatAmount, parseAmount, accMul, accDiv, toFixed, accAdd, accSub, accGt, significantDigits, gasProcessing } from '@/utils/format.js';
import { mapState } from 'vuex';
// import DEFAULT_TOKEN_LIST from '@/config/constants/tokenList';
// import DEFAULT_TOKEN_DEFI_LIST from '@/config/constants/tokenDEFIList.json';
// import DEFAULT_TOKEN_SWAN_LIST from '@/config/constants/tokenSWANList.json';
import { BNB } from '@/config/constants/bnb';
import axios from 'axios';

export default {
  data () {
    return {
      transactionRecordShow: false,
      settingShow: false,
      mobilityList: [],
      activeNames: ['0'],
      list: [],
      storeLpList: [],
      percentageMin: '<0.01%',
      percentageMax: '>100%',
      switchVersionShow: '0',
      swanList: [],
      defiList: [],
      normalList: [],
    };
  },
  created () {
    this.getStoreList()

  },
  computed: {
    ...mapState(['account', 'provider', 'gasPrice', 'slippage', 'deadline', 'multipath', 'pool']),
  },
  methods: {
    getStoreList () {
      try {
        if (this.pool.jackFactory == '0xca143ce32fe78f1f7019d7d551a6402fc5350c73') {
          this.storeLpList = localStorage.getItem('v1LpList') ? JSON.parse(localStorage.getItem('v1LpList')) : [];
        } else {
          this.storeLpList = localStorage.getItem('v2LpList') ? JSON.parse(localStorage.getItem('v2LpList')) : [];
        }
        if (this.storeLpList) {
          this.getLpList(this.storeLpList);
        }
      } catch (e) {
        this.storeLpList = [];
      }
    },
    watchLpList () {
      let _this = this;
      window.addEventListener('setItemEvent', function (e) {
        //e.key : 是值发生变化的key
        //例如 e.key==="token";
        //e.newValue : 是可以对应的新值
        if (e.key === 'v1LpList' || e.key === 'v2LpList') {
          _this.getStoreList()
        }
      });
    },
    async getLpList (storeLpList) {
      this.list = [];
      await this.currencyList()
      if (storeLpList.length != 0) {
        for (let i of storeLpList) {
          let detail = {};
          let jackFactoryContract = getJackFactoryContract();
          let pairAddress = await jackFactoryContract.methods.getPair(i.fromAddress, i.toAddress).call();
          let jackPairContract = getJackPairContract(pairAddress);
          let lpBalance = await jackPairContract.methods.balanceOf(this.account).call();
          let showLpBalance = formatAmount(lpBalance) * 1;
          let allBalance = await jackPairContract.methods.totalSupply().call();
          let scale = accDiv(lpBalance, allBalance); //当前比例
          let reserves = await jackPairContract.methods.getReserves().call(); //获取当前总额
          let fromBalance;
          let toBalance;
          if (i.fromAddress.toLowerCase() < i.toAddress.toLocaleLowerCase()) {
            fromBalance = (reserves._reserve0 / Math.pow(10, i.fromDecimals)) * scale;
            toBalance = (reserves._reserve1 / Math.pow(10, i.toDecimals)) * scale;
          } else {
            fromBalance = (reserves._reserve1 / Math.pow(10, i.fromDecimals)) * scale;
            toBalance = (reserves._reserve0 / Math.pow(10, i.toDecimals)) * scale;
          }
          detail['lpAddress'] = pairAddress; //lp地址
          detail['lpBalanceShow'] = showLpBalance; //lp展示余额
          detail['lpBalance'] = lpBalance; //lp余额
          detail['fromAddress'] = i.fromAddress; //form地址
          detail['fromSymbol'] = i.fromSymbol;
          detail['fromDecimals'] = i.fromDecimals;
          detail['toDecimals'] = i.toDecimals;
          detail['fromBalance'] = fromBalance; //from 余额
          detail['toAddress'] = i.toAddress; //to地址
          detail['toSymbol'] = i.toSymbol;
          detail['toBalance'] = toBalance; //to 余额
          detail['percentage'] = accDiv(lpBalance, allBalance) * 100; //份额
          detail['fromLogoURI'] = require('@/assets/img/default.png')
          detail['toLogoURI'] = require('@/assets/img/default.png')


          let currencyList = [...this.normalList, ...this.defiList, ...this.swanList, BNB];
          for (let i = 0; i < currencyList.length; i++) {
            if (currencyList[i].address == detail['fromAddress']) {
              detail['fromLogoURI'] = currencyList[i].logoUrl
            }
            if (currencyList[i].address == detail['toAddress']) {
              detail['toLogoURI'] = currencyList[i].logoUrl
            }
          }
          if (lpBalance != 0) {
            this.list.push(detail);
          }
          console.log(BNB, 'BNB');
        }
      }
    },
    significantDigits,
    handleSettingShow () {
      this.settingShow = true;
    },
    handleSettingClose () {
      this.settingShow = false;
    },
    handleRecordShow () {
      this.transactionRecordShow = true;
    },
    handleTransactionRecordClose () {
      this.transactionRecordShow = false;
    },
    //
    liquidity (i) {
      // if (i == '2') {
      //   this.$router.push({ name: 'Liquidity' });
      // } else if (i == '1') {
      //   this.$router.push({ name: 'importMining' });
      // }
    },
    handleSwap (item) {
      this.$router.push({ name: 'Remove', query: { item: JSON.stringify(item) } });
    },
    currencyList () {
      axios.get('https://api.swanfinance.cc/swan.json').then(res => {
        this.swanList = res.data.tokens
        console.log(this.swanList, "swan");
      });
      axios.get('https://api.swanfinance.cc/defi.json').then(res => {

        this.defiList = res.data.tokens
        console.log(this.defiList, "this.defiList");
      });
      axios.get('https://api.swanfinance.cc/normal.json').then(res => {
        this.normalList = res.data.tokens
      });
    }
  },
  components: {
    TransactionRecord,
    SettingsModal,
  },
};
</script>

<style lang="less" scoped>
/deep/ .van-button--primary {
  color: #000000 !important;
  background: linear-gradient(321deg, #fdde34 0%, #65fce4 100%);
  border: 0;
}
/deep/ .van-button--disabled {
  color: #878995 !important;
  background: #1e242b;
}
.liquidity {
  display: flex;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  span {
    display: block;
    font-size: 14px;
    color: #ffffff;
    background: #232B3E;
    border: 2px solid #232B3E;
    padding: 10px 20px;
  }
  .toggle {
    background: #000;
    color: rgba(255,255,255,0.6);
  }
}
.liquidity_list {
  border-radius: 20px;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 8px 8px;
  color: #e8e7f0;
  background: #232B3E;
  padding: 18px 0;
  margin-bottom: 10px;
  .headWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    font-weight: bold;
    color: #e8e7f0;
    padding: 8px 16px;
    .iconSet {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
    .iconRecord {
      width: 20px;
      height: 20px;
      margin-left: 15px;
      cursor: pointer;
    }
    .iconHash {
      width: 20px;
      height: 20px;
    }
    .headWrap_img {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background: rgba(199, 199, 199, 0.2);
      border-radius: 14px;
      padding: 4px 14px;
    }
  }
  .remove {
    font-size: 10px;
    color: #e8e7f0;
    padding: 0 16px;
  }
  .liquidity_explain {
    border-radius: 15px;

    padding: 14px 20px 34px;
    margin-top: 20px;
    .liquidity_data {
      /deep/ .van-hairline--top-bottom {
        overflow: hidden;
        background: #1e242b !important;
        // box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.3);

        border-radius: 15px;
        padding: 0;
        position: static;
        margin-bottom: 20px;
      }
      /deep/ .van-collapse-item__title {
        background: #1e242b !important;

        padding: 12px 16px !important;
        font-size: 10px;
        color: #e8e7f0;
      }
      /deep/ .van-collapse-item__content {
        background: #1e242b !important;
      }
      .title {
        display: flex;
        align-items: center;
        font-size: 14px;
      }
      .busd {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-right: 6px;
      }
      .content {
        .detail {
          display: flex;
          justify-content: space-between;
          margin-bottom: 16px;
          .detail_left {
            display: flex;
            align-items: center;
            font-size: 12px;
            color: #e8e7f0;
            // margin-left: 8px;
          }
          .detail_amount {
            font-size: 12px;
            color: #e8e7f0;
          }
        }
        .mobility_button {
          .swapBtn {
            width: 100%;
            font-size: 14px;
            color: #e8e7f0;
            border-radius: 15px;
            font-weight: bold;
            padding: 4px 0;
            // margin-top: 22px;
          }
        }
      }
    }

    .tips {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 16px;
      color: #e8e7f0;
      .view_other {
        color: #18D7FC ;
        // margin-top: 52rpx;
      }
      .interval {
        margin: 32px 0 26px;
      }
    }
  }
  .button_swapBtn {
    padding: 0 24px;
    margin-top: 26px;
    .swapBtn {
      width: 100%;
      font-size: 17px;
      color: #e8e7f0;
      font-weight: bold;
      padding: 10px 0;
    }
  }
}

/deep/ .van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
  border-width: 0px 0;
}
</style>