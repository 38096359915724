<template>
  <div class="home_page">
    <div class="title">
      <span>{{$t('lang.swap442')}}</span>
      <img src="@/assets/img/add.png" class="add" @click="addFeedBack" />
    </div>
    <div class="list">
      <div v-if="list.length == 0" class="bare">
        <img src="@/assets/img/barea.png" class="bare_img" />
        <span>{{$t('lang.swap443')}}</span>
      </div>
      <van-list
        v-else
        v-model="loading"
        :finished="finished"
        finished-text=" "
        @load="basicFeedbackList"
      >
        <div v-for="(item,index) in list" :key="index" class="li" @click="toFeedBackDetail(item)">
          <div class="li_top">
            <div class="li_left">
              <span>{{item.content}}</span>
              <span class="hint">{{item.created_time}}</span>
            </div>
            <img src="@/assets/img/rightArrows.png" class="li_img" />
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>
  
  <script>
import { mapState } from 'vuex';
import { basicApi } from '@/api/index';
export default {
  data() {
    return {
      list: [],
      page: 1,
      pageSize: 10, //页数
      totalPage: 0, //总页数
      loading: false,
      finished: false,
      page: 1, //快讯页码
      pageSize: 10, //页数
      totalPage: 0, //总页数
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.account) {
        this.basicFeedbackList();
      }
    },
    //反馈列表
    basicFeedbackList() {
      let data = {
        page: this.page,
        page_size: this.pageSize,
      };
      basicApi.basicFeedbackList(data, this.account).then(res => {
        if (res.code == 200) {
          console.log(this.list, 'aaaa', res.data.list);
          // this.feedBackList = res.data.list
          this.list = this.list.concat(res.data.list);
          this.totalPage = Math.ceil(res.data.total_count / this.pageSize);
          this.page++;
          this.loading = false;
          if (this.page > this.totalPage) return (this.finished = true);
        } else {
          this.$toast(res.msg);
        }
      });
    },
    toFeedBackDetail(item) {
      this.$router.push({ name: 'feedBackDetail', query: { item: JSON.stringify(item) } });
    },
    addFeedBack() {
      this.$router.push({ name: 'editFeedback' });
    },
  },
  computed: {
    ...mapState(['account']),
  },
  watch: {
    account() {
      this.list = [];
      this.init();
    },
  },
};
</script>
  
  <style lang="less" scoped>
.home_page {
  background: url('~@/assets/img/second_bg.png');
  background-size: 100% 100%;
  padding: 20px;
  min-height: 800px;
  // position: relative;
  // height: 800px;
  // margin-bottom: 100px;
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  margin: 20px 0;
  .add {
    height: 30px;
    width: 30px;
  }
}
.list {
  // padding-bottom: 50px;
  // height: 600px;
  // background-color: red;
  // overflow: hidden;
  // height: 700px;
  margin-bottom: 50px;
}
.li {
  background: #232b3e;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 20px;
  .li_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border-bottom: 1px solid #2a272a;
    // padding-bottom: 10px;
    .li_left {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      color: #fff;
      .hint {
        color: #999;
        font-size: 12px;
        margin-top: 6px;
      }
    }
    .li_img {
      width: 10px;
      height: 10px;
    }
  }
  .text {
    color: #999;

    font-size: 12px;
    margin-top: 10px;
  }
}
.bare {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  padding: 40px 0;
  .bare_img {
    width: 120px;
    height: 100px;
    margin-bottom: 8px;
  }
}
</style>