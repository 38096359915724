import rechargeAbi from '../config/abi/recharge.json'
import Web3 from 'web3'


//充值
function rechange(pid, amount, address) {
    return new Promise(async function (resolve, reject) {
        let web3 = new Web3(window.ethereum);
        let crossingOkContractInstance = new web3.eth.Contract(rechargeAbi, '0xaC07A33cf39AEc6A0771f7F0E835F4c16B722913');
        const estimateGas = await  crossingOkContractInstance.methods.startRechage(pid, amount).estimateGas({ from: address })
        const GasPrice = await web3.eth.getGasPrice()
        crossingOkContractInstance.methods.startRechage(pid, amount).send({ from: address,gas:estimateGas,gasPrice:GasPrice }).on('transactionHash', res => {
            resolve(res)
        }).on('error', res => {
            reject(res)
        })
    })
}



export default {
    rechange
}