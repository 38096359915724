<template>
  <div class="home-page">
    <div class="user">
      <div class="user-top">
        <img :src="arr.avatar || default_img" class="user-portrait" />
        <span>{{ account.substring(0, 4) + '*****' + account.substring(account.length - 7, account.length) || $t('lang.swap386')}}</span>
      </div>
      <div class="user-feedback">
        <span class="title">{{arr.content}}</span>
        <span class="time">{{arr.created_time}}</span>
        <div class="arr">
          <img
            v-for="(e,i) of arr.image_arr"
            :key="i"
            :src="imageDomain + e"
            @click="arrClick(e)"
            class="arr_img"
          />
        </div>
      </div>
    </div>

    <div class="user">
      <div class="user-top">
        <img src="@/assets/img/poolBill.png" class="user-portrait" />
        <span>{{$t('lang.swap440')}}</span>
      </div>
      <div class="user-feedback">
        <span class="title" v-if="!content">{{$t('lang.swap41')}}</span>
        <span class="title" v-else>{{content}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { newsLetterApi, basicApi } from '@/api/index';
import { mapState } from 'vuex';
import { ImagePreview } from 'vant';
export default {
  data() {
    return {
      arr: undefined,
      default_img: require('@/assets/img/default_user.png'),
      imageDomain: '',
      content: undefined,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.account) {
        newsLetterApi.basicConfig().then(res => {
          if (res.code == 200) {
            // console.log(res.data);
            this.imageDomain = res.data.image_domain;
            this.arr = JSON.parse(this.$route.query.item);
            this.getReplyDetail();
            console.log(this.arr);
          } else {
            this.$toast(res.msg);
          }
        });
      }
    },
    getReplyDetail() {
      let data = {
        id: this.arr.id,
      };
      basicApi.basicFeedbackReplyList(data, this.account).then(res => {
        if (res.code == 200) {
          this.content = res.data[0].content;
          console.log(res.data);
        } else {
          this.$toast(res.msg);
        }
      });
    },
    //图片预览
    arrClick(img) {
      ImagePreview([this.imageDomain + img]);
    },
  },
  computed: {
    ...mapState(['account']),
  },
  watch: {
    account() {
      this.init();
    },
  },
};
</script>
    
<style lang="less" scoped>
.home-page {
  background: url('~@/assets/img/second_bg.png');
  background-size: 100% 100%;
  padding: 20px;
  min-height: 800px;
  .user {
    background: #232B3E;;
    border-radius: 4px 4px 4px 4px;
    margin-top: 20px;
    .user-top {
      padding: 20px 6px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      span {
        font-size: 12px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #ffffff;
        margin-left: 5px;
      }
    }
    .user-feedback {
      padding: 20px 6px;
      display: flex;
      flex-direction: column;
      .title {
        font-size: 14px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #ffffff;
      }
      .time {
        font-size: 10px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.6);
        margin-top: 5px;
      }
      .arr {
        margin-top: 10px;
        .arr_img {
          width: 60px;
          height: 60px;
          margin-right: 6px;
        }
      }
    }
  }
  .user-portrait {
    width: 40px;
    height: 40px;

  }
}
</style>