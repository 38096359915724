<template>
  <div class="release">
    <div class="closure" @click="close">
      <img src="@/assets/img/closure.png" class="closure_img" />
    </div>
    <div class="release_title">
      <span>{{popupIndex==0?$t('lang.swap391'):$t('lang.swap392')}}</span>
    </div>
    <div>
      <span class="field_title">{{$t('lang.swap181')}}</span>
      <van-field type="number" v-model="quantity" placeholder class="van-field">
        <template #button>
          <span class="field_button">VRT</span>
        </template>
      </van-field>
    </div>
    <div>
      <span class="field_title">{{$t('lang.swap90')}}</span>
      <van-field type="number" v-model="price" placeholder class="van-field">
        <template #button>
          <span class="field_button">USDT</span>
        </template>
      </van-field>
    </div>
    <div>
      <span class="field_title">{{$t('lang.swap426')}}</span>
      <van-field type="number" v-model="total" placeholder readonly class="van-field">
        <template #button>
          <span class="field_button">USDT</span>
        </template>
      </van-field>
    </div>
    <div class="balance">
      <span>{{$t('lang.swap280')}}: {{balance}}{{popupIndex==0?'USDT':'VRT'}}</span>
    </div>
    <div class="detail">
      <span>{{$t('lang.swap430',{buyFee:buy_fee_rate * 100,sellFee:sell_fee_rate * 100})}}</span>
    </div>
    
    <div>
      <van-button type="primary" class="defray_button" @click="payPool">{{$t('lang.swap429')}}</van-button>
    </div>
  </div>
</template>

<script>
import { walletApi, c2cApi } from '@/api/index';
import { mapState } from 'vuex';
import Web3 from 'web3';
export default {
  props: ['popupIndex'],
  data() {
    return {
      quantity: 0, //数量
      price: 0, //单价
      balance: 0,
      vrtObj: undefined,
      usdtObj: undefined,
      usdtList: [],
      conditionId: 0,
      sell_fee_rate:0,
      buy_fee_rate:0,

    };
  },
  created() {
    this.getWalletList();
    this.init();
  },
  methods: {
    init() {
      if (this.account) {
        this.getCondition()
      }
    },
    close() {
      this.quantity = 0;
      this.price = 0;
      this.getWalletList();
      this.$emit('backtrack');
    },
    //获取单个币种信息
    getWalletInfo() {
      let data = {
        token_id: this.popupIndex == 0 ? this.usdtObj.token_id : this.vrtObj.token_id,
      };
      walletApi.getWalletInfo(data, this.account).then(res => {
        if (res.code == 200) {
          this.balance = res.data.usable;
        } else {
          this.$toast(res.msg);
        }
      });
    },
    //发布c2c
    payPool() {
      let web3 = new Web3(window.ethereum);
      web3.eth.personal.sign(web3.utils.fromUtf8('C2C Publish'), this.account, (err, res) => {
        let data = {
          signature: res,
          trade_type: this.popupIndex,
          amount: this.quantity * 1,
          price: this.price * 1,
          conf_id: this.conditionId,
        };
        c2cApi.publish(data, this.account).then(res => {
          if (res.code == 200) {
            this.$toast(this.$t('lang.swap319'));
            this.close();
          } else {
            this.$toast(res.msg);
          }
        });
      });
    },
    // 资产列表
    getWalletList() {
      walletApi.getWalletList(this.account).then(res => {
        this.walletList = res.data;
        for (let i of this.walletList) {
          if (i.token_name == 'VRT') {
            this.vrtObj = i;
          }
          if (i.token_name == 'USDT') {
            this.usdtObj = i;
          }
        }
        this.getWalletInfo();
        this.getCondition();
      });
    },
    getCondition() {
      c2cApi.condition(this.account).then(res => {
        this.list = res.data.token_list;
        this.c2cMap = res.data.trade_map;
        this.usdtList = res.data.trade_map['USDT'];
        for (let i of this.usdtList) {
          if ((i.t2_id == this.usdtObj.token_id && i.t1_id == this.vrtObj.token_id) || (i.t1_id == this.usdtObj.token_id && i.t2_id == this.vrtObj.token_id)) {
            this.conditionId = i.id;
            this.sell_fee_rate = i.sell_fee_rate
            this.buy_fee_rate = i.buy_fee_rate
          }
        }
      });
    },
  },
  computed: {
    ...mapState(['account']),
    total() {
      if (this.quantity != 0 && this.price != 0) {
        return this.quantity * this.price;
      } else {
        return 0;
      }
    },
  },
  watch: {
    popupIndex() {
      this.balance = 0;
      this.getWalletList();
    },
    account() {
      this.getWalletList();
    },
  },
};
</script>

<style lang="less" scoped>
.release {
  color: #000;
  padding: 20px;
  .closure {
    display: flex;
    justify-content: flex-end;
    .closure_img {
      width: 20px;
      height: 20px;
      display: block;
    }
  }
  .release_title {
    font-weight: bold;
  }
  .field_title {
    display: block;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    margin: 20px 0 10px;
  }
  .van-field {
    background: #f3f3f3 !important;
    border-radius: 4px;
    padding: 6px 10px !important;
    .field_button {
      display: block;
      font-size: 12px;
      color: #999;
    }
  }
  .balance {
    font-size: 12px;
    color: #999;
    margin-top: 10px;
  }
  .defray_button {
    width: 100%;
    height: auto;
    border: 0;
    border-radius: 4px;
    background: linear-gradient(180deg, #1ad9f3 0%, #2a88cc 100%);
    padding: 10px 0;
    margin-top: 20px;
  }
}

.detail {
  margin-top: 20px;
  font-size: 12px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(35, 176, 223, 0.6);
}
</style>