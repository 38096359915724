<template>
  <div class="home_page">
    <div class="headWrap">
      <span @click="checkedClick(0)"
            :class="checkedIndex == 0 ? 'jump' : 'checked'">{{ $t('lang.swap255') }}</span>
      <span @click="checkedClick(1)"
            :class="checkedIndex == 1 ? 'jump' : 'checked'">{{ $t('lang.swap256') }}</span>
      <span @click="checkedClick(2)"
            :class="checkedIndex == 2 ? 'jump' : 'checked'">{{ $t('lang.swap175') }}</span>
      <!-- <span class="jump">{{$t('lang.swap35')}}</span>
      <span @click="switchovers('FlashExchangeBill')"
      class="checked">{{$t('lang.swap139')}}</span>-->
    </div>
    <div class="recharge">
      <!-- 充值 -->
      <div v-show="checkedIndex == 0">
        <div class="box">
          <div v-if="fromCur.token_name"
               @click="handleGetCurrency"
               class="detail">
            <div>
              <!-- <img :src="fromCur.logo" class="currency_img" /> -->
              <span>{{ fromCur.token_name }}</span>
            </div>

            <img src="@/assets/img/arrows.png"
                 class="arrows_img" />
          </div>
          <div v-else
               @click="handleGetCurrency"
               class="detail">
            <span>{{ $t('lang.swap24') }}</span>
            <img src="@/assets/img/arrows.png"
                 class="arrows_img" />
          </div>
        </div>
        <div class="recharge_field">
          <span>{{ $t('lang.swap362') }}</span>
          <van-field v-model="recharge"
                     :placeholder="$t('lang.swap258')"
                     class="amount"
                     type="number" />
        </div>
        <div class="balance">
          <span>{{ $t('lang.swap280') }}: {{ rechargeBalance }} {{ fromCur.token_name }}</span>
        </div>
        <div class="balance">
          <span>{{ $t('lang.swap338') }}: {{ walletBalance }} {{ fromCur.token_name }}</span>
        </div>
        <van-button type="primary"
                    class="button"
                    v-if="!isApprove"
                    @click="approve"
                    loading-text="Waiting..."
                    :loading="showApprove">{{ $t('lang.swap255') }}</van-button>
        <van-button type="primary"
                    class="button"
                    v-else
                    @click="rechargeMethod"
                    loading-text="Waiting..."
                    :loading="showRecharge">{{ $t('lang.swap402') }}</van-button>
               
      </div>
      <!-- 提币 -->
      <div v-show="checkedIndex == 1">
        <div class="box">
          <div v-if="toCur.token_name"
               @click="handleGetCurrency"
               class="detail">
            <div>
              <!-- <img :src="toCur.logo" class="currency_img" /> -->
              <span>{{ toCur.token_name }}</span>
            </div>

            <img src="@/assets/img/arrows.png"
                 class="arrows_img" />
          </div>
          <div v-else
               @click="handleGetCurrency"
               class="detail">
            <span>{{ $t('lang.swap24') }}</span>
            <img src="@/assets/img/arrows.png"
                 class="arrows_img" />
          </div>
        </div>
        <div class="recharge_field">
          <span>{{ $t('lang.swap81') }}</span>
          <van-field v-model="withdrawNumber"
                     :placeholder="$t('lang.swap259')"
                     class="amount"
                     type="number" />
        </div>
        <div class="balance">
          <span>{{ $t('lang.swap280') }}: {{ toBalance }} {{ toCur.token_name }}</span>
        </div>
        <van-button type="primary"
                    class="button"
                    @click="withdraw"
                    loading-text="Waiting..."
                    :loading="showWithDraw">{{ $t('lang.swap320') }}</van-button>
      </div>
      <!-- 转账 -->
      <div v-show="checkedIndex == 2">
        <div class="box">
          <div v-if="transferCur.token_name"
               @click="handleGetCurrency"
               class="detail">
            <div>
              <!-- <img :src="transferCur.logo" class="currency_img" /> -->
              <span>{{ transferCur.token_name }}</span>
            </div>

            <img src="@/assets/img/arrows.png"
                 class="arrows_img" />
          </div>
          <div v-else
               @click="handleGetCurrency"
               class="detail">
            <span>{{ $t('lang.swap24') }}</span>
            <img src="@/assets/img/arrows.png"
                 class="arrows_img" />
          </div>
        </div>
        <div class="recharge_field">
          <span>{{ $t('lang.swap363') }}</span>
          <van-field v-model="postbox"
                     :placeholder="$t('lang.swap364')"
                     class="amount"
                     type="text" />
        </div>
        <div class="recharge_field">
          <span>{{ $t('lang.swap365') }}</span>
          <van-field v-model="transferPay"
                     :placeholder="$t('lang.swap177')"
                     class="amount"
                     type="number" />
        </div>
        <div class="balance">
          <span>{{ $t('lang.swap280') }}: {{ transferBalance }} {{ transferCur.token_name }}</span>
        </div>
        <van-button type="primary"
                    class="button"
                    @click="transfer">{{ $t('lang.swap175') }}</van-button>
        <!-- <div class="illustrate">
          <span class="illustrate_title">{{ $t('lang.swap366') }}</span>
          <span>* {{ $t('lang.swap367') }}。</span>
          <span>* {{ $t('lang.swap368') }}</span>
        </div>-->
      </div>
      <van-popup v-model="tokenListShow"
                 position="bottom">
        <div class="currency_popup">
          <div v-for="(item,index) of currencyList"
               :key="index"
               class="li"
               @click="handlerSelecteCurrency(item)">
            <img :src="item.logo"
                 class="li_logo" />
            <span>{{item.token_name}}</span>
          </div>
          <div>
            <van-button type="primary"
                        class="cancel">{{ $t('lang.swap48') }}</van-button>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getErc20Contract } from '@/utils/contractHelp';
import { useErc20Contract } from '@/utils/useContract';
import { transferApi, walletApi, tokenApi, withdrawApi, newsLetterApi } from '../../api/index';
import { significantDigits, parseAmount, formatAmount } from '@/utils/format.js';
import { MaxUint256 } from '@ethersproject/constants';
import rechangeApi from '../../methods/rechange';
import Web3 from 'web3';
export default {
  data () {
    return {
      checkedIndex: 0,
      recharge: 0, //充值数量
      withdrawNumber: 0, //提币数量
      transferPay: 0, //转账数量
      postbox: '', //手机号或者邮箱
      tokenListShow: false,
      fromCur: {}, //充值币种
      toCur: {}, //提币币种
      transferCur: {}, //转账币种
      currencyList: [],
      fromCurrencyList: [], //充值币种列表
      toCurrencyList: [], //提币币种列表
      transferList: [], //转账币种列表
      transferBalance: 0, //转账币种余额
      toBalance: 0, //提币余额
      rechargeBalance: 0, //充值币种余额
      walletBalance: 0, //钱包余额
      showWithDraw: false,
      contractAddress: '0xaC07A33cf39AEc6A0771f7F0E835F4c16B722913',
      isApprove: false,
      showApprove: false,
      showRecharge: false,
      imageDomain: '',
    };
  },
  created () {
    this.checkedIndex = this.$route.query.index ? this.$route.query.index : 0;
    this.init();
  },
  watch: {
    account () {
      this.init();
    },
  },
  methods: {
    init () {
      if (this.account) {
        this.basicConfig()
      }
    },
    //图片域名
    basicConfig () {
      newsLetterApi.basicConfig().then(res => {
        if (res.code == 200) {
          this.imageDomain = res.data.image_domain;
          this.getTransferListCondition();
          this.tokenRechargeConfigs();
          this.tokenWithdrawConfigs();
        } else {
          this.$toast(res.msg);
        }
      });
    },
    //充值
    rechargeMethod () {
      if (this.recharge == 0) {
        this.$toast(this.$t('lang.swap258'));
        return;
      }
      if (this.recharge > this.walletBalance) {
        this.$toast(this.$t('lang.swap375'));
        return;
      } else {
        let amount = parseAmount(this.recharge, 18);
        this.showRecharge = true;
        rechangeApi
          .rechange(1, amount, this.account)
          .then(res => {
            let data = {
              tx_hash: res,
            };
            walletApi.entryMark(data, this.account).then(res => {
              this.$toast(this.$t('lang.swap376'));
              this.checkedClick(this.checkedIndex);
              this.showRecharge = false;
            });
          })
          .catch(res => {
            this.showRecharge = false;
          });
      }
    },
    //授权
    async approve () {
      let web3 = new Web3(window.ethereum);
      if (!this.account) {
        this.$toast(this.$t('lang.swap323'));
        return;
      }
      this.showApprove = true;

      const erc20Contract = useErc20Contract(this.fromCur.contract);
      const amo = MaxUint256.toString();
    const estimateGas = await  erc20Contract.methods.approve(this.contractAddress, amo).estimateGas({  from: this.account})
    const GasPrice = await web3.eth.getGasPrice()
    console.log('estimateGas',estimateGas);
      erc20Contract.methods
        .approve(this.contractAddress, amo)
        .send({
          from: this.account,
          gas:estimateGas,
          gasPrice:GasPrice
        })
        .on('confirmation', res => {
          this.showApprove = false;
          this.isApprove = true;
        })
        .on('error', (error, receipt) => {
          this.showApprove = false;
          this.$toast(this.$t('lang.swap324'));
        });
    },
    //充值币种列表
    tokenRechargeConfigs () {
      tokenApi.tokenRechargeConfigs(this.account).then(res => {
        for (let i of res.data) {
          i.logo = this.imageDomain + i.logo;
        }
        this.fromCurrencyList = res.data;
        this.checkedClick(this.checkedIndex);
      });
    },
    //获取转账币种
    getTransferListCondition () {
      transferApi.transferCondition(this.account).then(res => {
        console.log(res.data);
        for (let i of res.data) {
          i.logo = this.imageDomain + i.logo;
        }
        this.transferList = res.data;
        this.checkedClick(this.checkedIndex);
      });
    },
    //提现币种列表
    tokenWithdrawConfigs () {
      tokenApi.tokenWithdrawConfigs(this.account).then(res => {
        for (let i of res.data) {
          i.logo = this.imageDomain + i.logo;
        }
        this.toCurrencyList = res.data;
        this.checkedClick(this.checkedIndex);
      });
    },
    withdraw () {
      if (this.withdrawNumber > this.toBalance) {
        this.$toast(this.$t('lang.swap263'));
        return;
      }
      let data = {
        token_id: this.toCur.token_id,
      };
      withdrawApi.condition(data, this.account).then(res => {
        if (res.data.withdraw_status != 1) {
          this.$toast(this.$t('lang.swap264'));
          return;
        }
        if (res.data.max_withdraw < this.toQuantity) {
          this.$toast(this.$t('lang.swap265'));
          return;
        }

        let web3 = new Web3(window.ethereum);
        web3.eth.personal.sign(web3.utils.fromUtf8('Withdraw Order'), this.account, (err, res) => {
          let da = {
            chain_id: 13,
            token_id: this.toCur.token_id,
            amount: this.withdrawNumber * 1,
            address: this.account,
            signature: res,
          };
          this.showWithDraw = true;
          withdrawApi.walletOut(da, this.account).then(res => {
            this.showWithDraw = false;
            if (res.code == 200) {
              this.$toast(this.$t('lang.swap266'));
              this.withdrawNumber = 0;
              let data = {
                token_id: this.toCur.token_id,
              };
              walletApi.getWalletInfo(data, this.account).then(res => {
                if (res.code == 200) {
                  this.toBalance = res.data.usable;
                } else {
                  this.$toast(res.msg);
                }
              });
            } else {
              this.$toast(res.msg);
            }
          });
        });
      });
    },
    //获取转账条件
    transfer () {
      if (this.transferPay > this.transferBalance) {
        this.$toast(this.$t('lang.swap29'));
        return;
      } else if (this.transferPay == 0) {
        this.$toast(this.$t('lang.swap177'));
        return;
      } else if (this.postbox == '') {
        this.$toast(this.$t('lang.swap178'));
        return;
      } else if (this.transferCur.token_id == undefined) {
        this.$toast(this.$t('lang.swap141'));
        return;
      } else {
        let web3 = new Web3(window.ethereum);
        web3.eth.personal.sign(web3.utils.fromUtf8('Transfer Out'), this.account, (err, res) => {
          let data = {
            token_id: this.transferCur.token_id,
            to_address: this.postbox,
            amount: this.transferPay * 1,
            signature: res,
          };
          transferApi.transferOut(data, this.account).then(res => {
            if (res.code == 200) {
              this.$toast(this.$t('lang.swap179'));
              this.getWalletInfo();
            } else {
              this.$toast(res.msg);
            }
          });
        });
      }
    },
    checkedClick (i) {
      this.checkedIndex = i;
      if (i == 0) {
        this.currencyList = this.fromCurrencyList;
      } else if (i == 1) {
        this.currencyList = this.toCurrencyList;
      } else {
        this.currencyList = this.transferList;
      }
    },
    handleGetCurrency (tokenSource) {
      this.tokenListShow = true;
    },
    handleTokenListClose () {
      this.tokenListShow = false;
    },
    async handlerSelecteCurrency (currency) {
      this.tokenListShow = false;
      if (this.checkedIndex == 0) {
        this.fromCur = currency;
        let data = {
          token_id: this.fromCur.token_id,
        };
        walletApi.getWalletInfo(data, this.account).then(res => {
          if (res.code == 200) {
            this.rechargeBalance = res.data.usable;
          } else {
            this.$toast(res.msg);
          }
        });
        const erc20Contract = getErc20Contract(this.fromCur.contract);
        let bal = await erc20Contract.methods.balanceOf(this.account).call();
        this.walletBalance = formatAmount(bal, 18);
        this.handleApproveBtnShow();
      } else if (this.checkedIndex == 1) {
        this.toCur = currency;
        let data = {
          token_id: this.toCur.token_id,
        };
        walletApi.getWalletInfo(data, this.account).then(res => {
          if (res.code == 200) {
            this.toBalance = res.data.usable;
          } else {
            this.$toast(res.msg);
          }
        });
      } else {
        this.transferCur = currency;
        let data = {
          token_id: this.transferCur.token_id,
        };
        walletApi.getWalletInfo(data, this.account).then(res => {
          if (res.code == 200) {
            this.transferBalance = res.data.usable;
          } else {
            this.$toast(res.msg);
          }
        });
      }
    },
    //获取单个币种信息
    getWalletInfo () {
      let data = {
        token_id: this.transferCur.token_id,
      };
      walletApi.getWalletInfo(data, this.account).then(res => {
        if (res.code == 200) {
          this.transferBalance = res.data.usable;
        } else {
          this.$toast(res.msg);
        }
      });
    },
    async handleApproveBtnShow () {
      if (this.account) {
        const to = this.contractAddress;
        if (this.account) {
          let erc20ContractTo = getErc20Contract(this.fromCur.contract);
          const allowanceTo = await erc20ContractTo.methods.allowance(this.account, to).call();
          if (allowanceTo == 0) {
            this.isApprove = false;
          } else {
            this.isApprove = true;
          }
        }
      }
    },
  },
  computed: {
    ...mapState(['account', 'net']),
    // showBalance () {
    //   if (this.account && this.fromCur && this.walletList)
    //     for (let i of this.walletList) {
    //       if ((this.fromCur.token_id = i.token_id)) {
    //         return i.usable;
    //       }
    //     }
    // },
    // showToBalance () {
    //   if (this.account && this.toCur && this.walletList)
    //     for (let i of this.walletList) {
    //       if ((this.toCur.token_id = i.token_id)) {
    //         return i.usable;
    //       }
    //     }
    // },
  },
};
</script>

<style lang="less" scoped>
.home_page {
  background: url('~@/assets/img/second_bg.png');
  background-size: 100% 100%;
  padding: 20px;
  min-height: 800px;
}
.headWrap {
  display: flex;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  // width: 100%;

  // position: absolute;
  // right: 0;
  // display: flex;
  // align-items: center;
  // justify-content: flex-end;
  // padding: 0 16px 0 30px;
  // margin-top: 20px;
  .jump {
    display: block;
    font-size: 14px;
    color: #ffffff;
    background: #232b3e;
    border: 2px solid #232b3e;
    padding: 10px 20px;
  }
  .checked {
    // width: 70px;
    display: inline-block;
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;

    border: 2px solid #232b3e;
    // background: #000;

    text-align: center;
    padding: 10px 20px;
  }
}
.recharge {
  background: #232b3e;
  box-shadow: 0px 0px 20px 6px rgba(0, 0, 0, 0.11);
  border-radius: 0 0 10px 10px;
  padding: 10px 20px 20px;
  // margin-top: 20px;
  .title {
    display: flex;
    font-size: 18px;
    color: #333333;
    span {
      max-width: 33%;
      display: block;
      font-weight: bold;
      // border-bottom: 2px solid #fff;
      padding: 10px;
      // margin: 0 10px;
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      white-space: nowrap; //溢出不换行
    }
    .checked {
      background: #ffdb1c;
      padding: 10px;
      border-radius: 0 0 10px 10px;
      // margin: 0 10px;
    }
  }
  .box {
    display: flex;
    justify-content: center;
    // width: 44%;
    font-size: 12px;
    background: #232b3e;
    border-radius: 2px 2px 2px 2px;
    border: 1px solid #474e5f;
    border-radius: 2px;
    padding: 8px 10px;
    margin: 20px 0;
    color: #999;
    .detail {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      div {
        display: flex;
        align-items: center;
      }
      .currency_img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 4px;
      }
      .arrows_img {
        display: block;
        width: 10px;
        height: 6px;
        margin: 4px 0 0 4px;
      }
    }
  }
  .code {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #666666;
    margin: 20px 0;
    .weight_img {
      width: 70px;
      height: 70px;
      margin: 0 0 10px;
    }
    .wallat {
      width: 100%;
      display: block;
      font-size: 12px;
      text-align: center;
      margin-top: 10px;
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      white-space: nowrap; //溢出不换行
    }
    .replicate_button {
      width: 150px;
      height: auto;
      color: #f39714;
      background: #ffeed6;
      border-radius: 6px;
      border: 0;
      padding: 8px 0;
      margin-top: 10px;
    }
  }
  .recharge_field {
    display: flex;
    // align-items: center;
    flex-direction: column;
    font-size: 14px;
    // border-bottom: 1px solid #d6e0f2;1
    padding: 6px 0;
    margin-top: 20px;
    span {
      display: block;
      font-weight: bold;
      white-space: nowrap; //溢出不换行
      margin-right: 10px;
    }
  }
  .amount {
    background: #474e5f;
    border-radius: 2px;
    padding: 4px 10px !important;
    margin-top: 6px;
    /deep/ .van-field__control {
      color: #fff;
    }
  }
  .balance {
    font-size: 12px;
    color: #999999;
    margin-top: 6px;
  }
  .button {
    width: 100%;
    height: auto;
    font-size: 14px;
    background: linear-gradient(180deg, #1ad9f3 0%, #2a88cc 100%);
    border: 0;
    border-radius: 6px;
    margin-top: 40px;
    padding: 10px 0;
  }
  .illustrate {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: #666666;
    margin-top: 30px;
    .illustrate_title {
      font-size: 14px;
      font-weight: bold;
      color: #333;
    }
    span {
      margin-bottom: 6px;
    }
  }
}

.currency_popup {
  color: #000;
  padding: 10px 20px;
  .li {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);

    // background: rgba(0,0,0,0.06);
    padding: 14px 0 14px;
    .li_logo {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-right: 6px;
    }
  }
  .cancel {
    width: 100%;
    height: auto;
    color: #999;
    border-radius: 4px;
    background: #fffeff;
    border: 0;
    // padding: 10px 0;
    margin: 20px 0 10px;
  }
}
</style>