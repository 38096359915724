import request from '../utils/request';

export default {
	//矿机购买
	poolBuy(data, address) {
		return request.request({
			url: '/pool/buy',
			method: "post",
			headers: {
				'Lang': localStorage.getItem('lang') || '',
				// 'signature': ''
				'Authorization': address
			},
			data: data
		})
	},

	//矿机列表
	poolList(address) {
		return request.request({
			url: '/pool/list',
			method: "get",
			headers: {
				'Lang': localStorage.getItem('lang') || '',
				// 'signature': ''
				'Authorization': address
			}
		})
	},

	//优惠券列表
	poolCoupons(address) {
		return request.request({
			url: '/pool/coupons',
			method: "get",
			headers: {
				'Lang': localStorage.getItem('lang') || '',
				// 'signature': ''
				'Authorization': address
			},
		})
	},

	//矿机产能预测
	poolHForecast(data, address) {
		return request.request({
			url: '/pool/forecast',
			method: "get",
			headers: {
				'Lang': localStorage.getItem('lang') || '',
				// 'signature': ''
				'Authorization': address
			},
			params: data
		})
	},

	//持有列表
	poolHoldList(data, address) {
		return request.request({
			url: '/pool/hold-list',
			method: "get",
			headers: {
				'Lang': localStorage.getItem('lang') || '',
				// 'signature': ''
				'Authorization': address
			},
			params: data
		})
	},

	//数量统计
	poolStatistics(address) {
		return request.request({
			url: '/pool/statistics',
			method: "get",
			headers: {
				'Lang': localStorage.getItem('lang') || '',
				// 'signature': ''
				'Authorization': address
			},
		})
	},

	//收益统计
	poolIncomeStatistics(address) {
		return request.request({
			url: '/pool/income/statistics',
			method: "get",
			headers: {
				'Lang': localStorage.getItem('lang') || '',
				// 'signature': ''
				'Authorization': address
			},
		})
	},
}