import Vue from 'vue';
import Vuex from 'vuex';
import Web3 from 'web3';
import {blacklist } from '@/config/constants/blacklist.js'
import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
//模块化store
// import Sys from '../components/modal/store';
Vue.use(Vuex);
import { usApi } from '../api/index';

import axios from "axios";
export default new Vuex.Store({
  state: {
    provider: {},
    account: '',
    net: 0,
    gasPrice: 5000000000,
    slippage: 5,
    deadline: 20,
    multipath: 0,
    transactions: {},
    block: 0,
    expert: false,
  },
  mutations: {
    SETPROVIDER: (state, provider) => {
      state.provider = provider;
    },
    SETBLOCK: (state, block) => {
      state.block = block;
    },
    SETEXPORT: (state, provider) => {
      state.expert = provider
    },

    DELETETRANSACTIONS: state => {
      state.transactions = {};
    },
    SETTRANSACTIONS: (state, transactionsDetail) => {
      let tempObj = JSON.parse(JSON.stringify(state.transactions));
      tempObj[transactionsDetail.hash] = transactionsDetail;
      state.transactions = tempObj;
    },
    SETTRANSACTIONSRECEIPT: (state, transactionsDetail) => {
      let tempObj = JSON.parse(JSON.stringify(state.transactions));
      tempObj[transactionsDetail.hash].receipt = transactionsDetail.receipt;
      state.transactions = tempObj;
    },
    SETACCOUNTS: (state, account) => {
      state.account = account;
      if (account) {
        // const domain = {
        //   name: 'Butterfly',
        //   version: 'v1.0',
        //   chainId: '56',
        //   verifyingContract: '',
        // };

        // const EIP712Domain = [
        //   { name: 'name', type: 'string' },
        //   { name: 'version', type: 'string' },
        //   { name: 'chainId', type: 'uint256' },
        // ];

        // const Primary = [
        //   { name: 'from', type: 'address' },
        //   { name: 'content', type: 'string' },
        // ];

        // const message = {
        //   from: account,
        //   content: 'Register User',
        // };

        // const data = JSON.stringify({
        //   types: {
        //     EIP712Domain,
        //     Primary,
        //   },
        //   domain,
        //   primaryType: 'Primary',
        //   message,
        // });
        let data = {
          address: account,
          // signature: splitSignature
        }
        usApi.ucRegister(data).then(res => {
          if (res.code == 200) {
          }
        });
        // try {
        //   const from = account;
        //   ethereum
        //     .request({
        //       method: 'eth_signTypedData_v4',
        //       params: [from, data],
        //     })
        //     .then(splitSignature => {
        //       let data = {
        //         address: account,
        //         signature: splitSignature
        //       }

        //     })
        // } catch (err) {
        //   console.error(err);
        // }
        // let data = {
        //   address: account,
        //   signature:''
        // }
        // usApi.ucRegister(data).then(res => {
        //   if (res.code == 200) {
        //   }
        // });
      }
    },
    SETNET: (state, net) => {
      state.net = net;
    },
    SET_GASPRICE(state, gasPrice) {
      state.gasPrice = gasPrice;
    },
    SET_SLIPPAGE(state, slippage) {
      state.slippage = slippage;
    },

    SET_POOL: (state, pool) => {
      state.pool = pool
    },

    SET_DEADLINE(state, deadline) {
      state.deadline = deadline;
    },
    SET_MULTIPATH(state, multipath) {
      state.multipath = multipath;
    },

    SET_EXPORT(state, ex) {
      state.expert = ex
    }
  },
  actions: {
    async setWallet() {
      var web3Provider;
      if (window.web3) {
        web3Provider = window.web3;
        try {
          await web3Provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '0x38',
                chainName: 'Binance Smart Chain Mainnet',
                nativeCurrency: {
                  name: 'BNB',
                  symbol: 'bnb',
                  decimals: 18,
                },
                rpcUrls: ['https://bsc-dataseed4.defibit.io/'],
                blockExplorerUrls: [`https://bscscan.com/`],
              },
            ],
          });
          await web3Provider.enable();
        } catch (error) {
          console.error('User denied account access');
        }
      }
    },
    async setWebProvider({ commit }) {
      var web3Provider;
      if (window.ethereum) {
        web3Provider = window.ethereum;
        try {
          await web3Provider.request({
            method: 'eth_requestAccounts',
            params: [
              {
                chainId: '0x38',
                chainName: 'Binance Smart Chain Mainnet',
                nativeCurrency: {
                  name: 'BNB',
                  symbol: 'bnb',
                  decimals: 18,
                },
                rpcUrls: ['https://bsc-dataseed4.defibit.io/'],
                blockExplorerUrls: [`https://bscscan.com/`],
              },
            ],
          });
          await web3Provider.enable();
        } catch (error) {
          console.error('User denied account access');
        }
        const web3js = new Web3(web3Provider);
        commit('SETPROVIDER', web3js);
        web3js.eth.net.getId(function (error, result) {
          if (!error) {
            console.log(result, '网络');
            commit('SETNET', result);
          }
        });
        web3js.eth.getAccounts(function (error, result) {
          if (!error) {
            console.log(String(result[0]).toUpperCase(), '账号');
            let IsBlack = false
            for(var item of blacklist){
                 if(String(item).toUpperCase() == String(result[0]).toUpperCase()){
                  IsBlack = true
                 }
            }
            console.log('IsBlack',IsBlack);
            if(IsBlack){
              commit('SETACCOUNTS', '');
            }else{
              commit('SETACCOUNTS', result[0]);
            }
           
          }
        });
        web3Provider.on('networkChanged', function (networkIDstring) {
          commit('SETNET', networkIDstring);
        });
        web3Provider.on('accountsChanged', function (accounts) {
         
          let IsBlack = false
          console.log('accounts[0]',accounts[0]);
          for(var item of blacklist){
            console.log('item',String(item).toUpperCase(),String(accounts[0]).toUpperCase());

               if(String(item).toUpperCase() == String(accounts[0]).toUpperCase()){

                console.log('accounts[0]',accounts[0]);
                IsBlack = true
               }
          }
          console.log('IsBlack2',IsBlack);
          if(IsBlack){
            commit('SETACCOUNTS', '');
          }else{
            commit('SETACCOUNTS', accounts[0]);
          }
          console.log('切换账号', accounts);
        });
      }
    },
  },
  plugins: [
    createPersistedState({
      paths: ['gasPrice', 'slippage', 'deadline', 'multipath', 'transactions', 'block', "pool", "expert"],
    }),
  ],
});
