export const lang = {
  swap1: '输出为估值，您将获得至少{inputOutMin} {symbol},或者交易将被撤',
  swap2: '输入为估值，您将出售最多{inputInMax} {symbol},或者交易将被撤回',
  swap3: '输出为估值。如果价格变化超过 {slippage}%，则您的交易将被撤回',
  swap4: '输出为估值。如果价格变化超过 {slippage}%，则您的交易将被撤回',
  swap5: '确认交换',
  swap6: '正在等待确认',
  swap7: '正在将',
  swap8: '交换为',
  swap9: '在你的钱包种确认此交易',
  swap10: '已提交交易',
  swap11: '在BscScan上查看',
  swap12: '将Swan-LP添加到Metamsak',
  swap13: '交易拒绝',
  swap14: '关闭',
  swap15: '正在移除',
  swap16: '和',
  swap17: '正在供应',
  swap18: '兑换',
  swap19: '流动性',
  swap20: '从',
  swap21: '估计',
  swap22: '余额',
  swap23: '最大',
  swap24: '选择通证',
  swap25: '到',
  swap26: '连接钱包',
  swap27: '此交易的流动性不足',
  swap28: '输入数量',
  swap29: '余额不足',
  swap30: '选择代币',
  swap31: '授权',
  swap32: '包装',
  swap33: '解包',
  swap34: '价格影响过高',
  swap35: '闪兑',
  swap36: '价格',
  swap37: '滑点容差',
  swap38: '价格影响',
  swap39: '路由',
  swap40: '最小获得量',
  swap41: '流动性供应商费用',
  swap42: '交易收据',
  swap43: '闪对',
  swap44: '您将获得',
  swap45: '最大出售量',
  swap46: '此交换的价格影响至少为 10%,请确认此交换',
  swap47: '确认',
  swap48: '取消',
  swap49: '最近交易',
  swap50: '全部清除',
  swap51: '最近没有交易',
  swap52: '您的流动性',
  swap53: '已入池',
  swap54: '添加',
  swap55: '移除',
  swap56: '增加流动性',
  swap57: '没有看到您加入的池？',
  swap58: '导入它',
  swap59: '如果您将XXX代币质押在农场中，请在此处取消质押以查看它们',
  swap60: '您是第一个流动性供应商。',
  swap61: '您添加代币的比列，将决定该流动性池的初始价格。',
  swap62: '如果您对汇率满意，请点击“供应”以进行检查。',
  swap63: '输入',
  swap64: '兑换率和流动池份额',
  swap65: '流动性池中的份额',
  swap66: '供应',
  swap67: '接收',
  swap68: '金额',
  swap69: '你将获得',
  swap70: '您的钱包中的LP代币',
  swap71: '移除流动性以收回代币',
  swap72: '导入池',
  swap73: '选择代币以查找您的流动性。',
  swap74: '您在该流动性池中还没有提供流动性。',
  swap75: '已入金',
  swap76: '汇率',
  swap77: '确认供应',
  swap78: '已焚毁',
  swap79: '资产变更',
  swap80: '提币账单',
  swap81: '提币数量',
  swap82: '手续费',
  swap83: '实际到账',
  swap84: '已通过',
  swap85: '已驳回',
  swap86: '我要买',
  swap87: '我要卖',
  swap88: '订单',
  swap89: '需求发布',
  swap90: '单价',
  swap91: '管理代币',
  swap92: '导入代币',
  swap93: '搜索名称或粘贴地址',
  swap94: '导入',
  swap95: '管理Tokens',
  swap96: '自定义代币',
  swap97: '首页',
  swap98: '交易',
  swap99: '资讯',
  swap100: '社区',
  swap101: '我的',
  swap102: '未连接',
  swap103: '申请中',
  swap104: '进入',
  swap105: '行情',
  swap106: '币种',
  swap107: '涨跌幅',
  swap108: '蝴蝶部落是⼀群有共同兴趣、认知、价值观的社区，联合在一起互动、交流、协作、感染而产⽣的新的信任和价值链接平台，由这种建⽴在社群之间的情感关联、信任产生新的部落型社区。区块链时代的来临让社群发挥了难以想象的⼒量，通过社群和社群组织之间更有效率的连接和价值交换，形成一个⾼效率且高效益的全新运转经济体。',
  swap109: '暂未开放',
  swap110: '蝴蝶社区',
  swap111: 'SER & SES 双币联动 · 轮回重生即将开启权益认筹',
  swap112: '社区公告',
  swap113: '联系人',
  swap114: '消息',
  swap115: '圈子',
  swap116: '搜索联系人',
  swap117: '暂无联系人',
  swap118: '暂无数据',
  swap119: '屏蔽该用户',
  swap120: '举报该内容',
  swap121: '快转',
  swap122: '引用',
  swap123: '分享',
  swap124: '新的朋友',
  swap125: '群组',
  swap126: '频道',
  swap127: '附近的人和群',
  swap128: '发表',
  swap129: '请输入发表内容',
  swap130: '只接受 JPG、BMP、PNG、GIF 格式',
  swap131: '上传中...',
  swap132: '上传成功',
  swap133: '上传失败',
  swap134: '请输入内容',
  swap135: '请添加图片',
  swap136: '表布成功',
  swap137: '深度',
  swap138: '快讯',
  swap139: '闪兑账单',
  swap140: '当前余额不足',
  swap141: '请选择币种',
  swap142: '兑换成功',
  swap143: '已赚取',
  swap144: '年化利率',
  swap145: '收割',
  swap146: '质押',
  swap147: '进行中',
  swap148: '已结束',
  swap149: '我参与的',
  swap150: '质押lp代币',
  swap151: '赎回lp代币',
  swap152: '赎回',
  swap153: '确实',
  swap154: '分享链接',
  swap155: '新手资料',
  swap156: '更多',
  swap157: '发图素材',
  swap158: '常用工具',
  swap159: '常见问题',
  swap160: '市场机制',
  swap161: '发展规划',
  swap162: '上一张',
  swap163: '下一张',
  swap164: '发圈素材',
  swap165: '发圈海报',
  swap166: '发圈视频',
  swap167: '发圈文案',
  swap168: '市场活动',
  swap169: '节日祝福',
  swap170: '好友邀请',
  swap171: '项目介绍',
  swap172: '全部',
  swap173: '转入',
  swap174: '转出',
  swap175: '转账',
  swap176: '收款',
  swap177: '请输入转账数量',
  swap178: '请输入接收人地址',
  swap179: '转账成功',
  swap180: '接收人',
  swap181: '数量',
  swap182: '可转',
  swap183: '请选择',
  swap184: '请输入数量',
  swap185: '最大转账',
  swap186: '最小转账',
  swap187: '交易值扣除',
  swap188: '手续费扣减',
  swap189: '请输入要修改的昵称',
  swap190: '保存',
  swap191: '请先上传图片',
  swap192: '修改成功',
  swap193: '我要反馈',
  swap194: '回复',
  swap195: '反馈标题',
  swap196: '标题',
  swap197: '请填写标题',
  swap198: '反馈内容',
  swap199: '请填写内容',
  swap200: '附件',
  swap201: '提交',
  swap202: '暂无回复',
  swap203: '提交成功',
  swap204: '您的问题',
  swap205: '您在使用app过程中遇见什么问题，或者对平台有什么建议都可以写在这里哦',
  swap206: '请填写回复内容',
  swap207: '蝴蝶学院',
  swap208: '邀请好友',
  swap209: '星级中心',
  swap210: '资产',
  swap211: '可用',
  swap212: '冻结',
  swap213: '贡献值',
  swap214: '查看矿池',
  swap215: '资产明细',
  swap216: '请先链接账号',
  swap217: '去邀请',
  swap218: '我的推荐人',
  swap219: '暂无上级',
  swap220: '我的推荐',
  swap221: '暂无推荐',
  swap222: '复制成功',
  swap223: '不支持复制,该浏览器不支持自动复制',
  swap224: '划转',
  swap225: '持有矿池',
  swap226: '领取',
  swap227: '阅读',
  swap228: '成长区',
  swap229: '赠送区',
  swap230: '首发中心',
  swap231: '蝴蝶部落是⼀群有共同兴趣、认知、价值观的社区，联合在一起互动、交流、协作、感染而产⽣的新的信任和价值链接平台，由这种建⽴在社群之间的情感关联、信任产生新的部落型社区。区块链时代的来临让社群发挥了难以想象的⼒量，通过社群和社群组织之间更有效率的连接和价值交换，形成一个⾼效率且高效益的全新运转经济体。',
  swap232: '当前已完成进度百分比',
  swap233: '会超过100%',
  swap234: '总需捐献',
  swap235: '您已捐献',
  swap236: '说明',
  swap237: '本次xxxx邀请人招募信息由xxxx提供并负责',
  swap238: '本次招募可超额进行，由xxxx社区进行考核，未予通过的邀请人席位将自动退回捐献资金、',
  swap239: '会员',
  swap240: '联合合伙人',
  swap241: '股东合伙人',
  swap242: '恭喜您，您已获得',
  swap243: '列表',
  swap244: '进行中',
  swap245: '已完成',
  swap246: '次数',
  swap247: '总产能',
  swap248: '号矿池',
  swap249: '号赠送',
  swap250: '总奖励',
  swap251: '消耗',
  swap252: '完成次数',
  swap253: '已奖励',
  swap254: '剩余次数',
  swap255: '充值',
  swap256: '提现',
  swap257: '复制',
  swap258: '请输入充值数量',
  swap259: '请输入提现数量',
  swap260: '平台余额',
  swap261: '充值成功',
  swap262: '转账失败',
  swap263: '提现数量不可可用余额',
  swap264: '当前不可提币',
  swap265: '超过当前最大可提币量',
  swap266: '提币成功',
  swap267: '总资产',
  swap268: '代币',
  swap269: '成交',
  swap270: '成交率',
  swap271: '限额',
  swap272: '去购买',
  swap273: '去出售',
  swap274: '请输入购买数量',
  swap275: '购买数量须在限额内',
  swap276: '超过余额',
  swap277: '购买成功',
  swap278: '出售成功',
  swap279: '我的订单',
  swap280: '可用余额',
  swap281: '请输入出售数量',
  swap282: '剩余可成交',
  swap283: '立即购买',
  swap284: '立即出售',
  swap285: '我的发布',
  swap286: '发布订单',
  swap287: '发布类型',
  swap288: '购买',
  swap289: '出售',
  swap290: '支付方式',
  swap291: '请选择支付方式',
  swap292: '请输入单价',
  swap293: '发布数量',
  swap294: '请输入发布数量',
  swap295: '选择币种',
  swap296: '最大限额',
  swap297: '请输入最大限额',
  swap298: '最小限额',
  swap299: '请输入最小限额',
  swap300: '发布',
  swap301: '输入最大价格应为',
  swap302: '最小价格应为',
  swap303: '输入最大数量应为',
  swap304: '最小数量应为',
  swap305: '买',
  swap306: '卖',
  swap307: '订单号',
  swap308: '取消订单',
  swap309: '撤销成功',
  swap310: '单价不在范围内',
  swap311: '数量不在范围内',
  swap312: '请选择发布币种',
  swap313: '请选择最小限额',
  swap314: '请选择最大限额',
  swap315: '最小限额不得大于最大限额',
  swap316: '最大限额不能超过发布数量',
  swap317: '购买价格不能超过余额',
  swap318: '发布数量不能超过余额',
  swap319: '发布成功',
  swap320: '提币',
  swap321: '你已加入',
  swap322: '加入',
  swap323: '请先登录',
  swap324: '授权失败',
  swap325: '加入成功',
  swap326: '实际兑换数量',
  swap327: '收到数量',
  swap328: '请输入兑换数量',
  swap329: '请先添加邀请人',
  swap330: '升级',
  swap331: 'SER是DEFI领域基于DAO+ iGaming 3.0的生态型代币，子币SES。通过全新的双币联动的市场推动计划，给予用户娱乐性体验的同时保障价值的持续稳定增长。浴火涅槃、轮回重生、生生不息，价值不灭，SER权益认筹启动中！',
  swap332: '我的奖励',
  swap333: '推荐列表',
  swap334: '地址',
  swap335: '确定将',
  swap336: '划转到资产吗',
  swap337: '矿池账户',
  swap338: '钱包账户',
  swap339: '贡献值余额',
  swap340: '消耗贡献值',
  swap341: '确认划转',
  swap342: '增加贡献值',
  swap343: '转入成功',
  swap344: '贡献值不足',
  swap345: '转出成功',
  swap346: '当前暂无可用余额',
  swap347: '已超过最大持有',
  swap348: '即将开放',
  swap349: '需支付',
  swap350: '将得到',
  swap351: '邀请奖励计划',
  swap352: '邀请用户共同助力Nebula基础设施建设，成为Nebula共同建设者，即可获得多重收益。',
  swap353: '我的邀请链接',
  swap354: '矿机列表',
  swap355: '持有列表',
  swap356: '持有数量',
  swap357: '总产出',
  swap358: '今日产出',
  swap359: '产出进度',
  swap360: '矿机',
  swap361: '预计产出',
  swap362: '充值数量',
  swap363: '对方账户',
  swap364: '请输入对方账户',
  swap365: '转账数量',
  swap366: '转账说明',
  swap367: '内部互转不走区块，不扣手续费，即时到账，不可逆请仔细核对对方账户。',
  swap368: '需要转到其他平台请通过提币方式', 
  swap369: '超过可成交数',
  swap370:'盲盒',
  swap371:'我的资产',
  swap372:'账单列表',
  swap373:'请绑定邀请人',
  swap374:'发单总价需大于30USDT',
  swap375:'超出了钱包余额',
  swap376:'入账中，请稍后查看',
  swap377:'新用户优惠',
  swap378:'----首次注册即可获赠价值10 USDT HM',
  swap379:'挂单',
  swap380:'钱包地址',
  swap381:'挖矿',
  swap382:'买单',
  swap383:'卖单',
  swap384:'购买价格',
  swap385:'购买时间',
  swap386:'用户',
  swap387:'发布时间',
  swap388:'卖出',
  swap389:'买入',
  swap390:'最新成交价',
  swap391:'发布买单',
  swap392:'发布卖单',
  swap393:'敬请期待',
  swap394:'今日收入',
  swap395:'累计收益',
  swap396:'冻结余额',
  swap397:'应用',
  swap398:'充币',
  swap399:'资金记录',
  swap400:'帮助中心',
  swap401:'空投',
  swap402:'支付',
  swap403:'类型',
  swap404:'公告',
  swap405:'暂无公告',
  swap406:'今日价格',
  swap407:'今日收益',
  swap408:'领取星球收益',
  swap409:'领取分享收益',
  swap410:'金星',
  swap411:'土星',
  swap412:'水星',
  swap413:'木星',
  swap414:'火星',
  swap415:'当前不可领取静态收益',
  swap416:'暂无收益领取',
  swap417:'领取成功',
  swap418:'当前不可领取推荐奖励',
  swap419:'订单记录',
  swap420:'已发布',
  swap421:'已成交',
  swap422:'已取消',
  swap423:'暂无订单',
  swap424:'单',
  swap425:'总量',
  swap426:'总价',
  swap427:'已完成数量',
  swap428:'可撤销数量',
  swap429:'确定',
  swap430:'说明：交易VRT收取税收手续费， 买入VRT收取{buyFee}%税收， 卖出VRT收取{sellFee}%税收',
  swap431:'当前剩余可交易数量',
  swap432:'个人账户数量余额',
  swap433:'可得',
  swap434:'用户反馈',
  swap435:'添加图片（最多三张）',
  swap436:'反馈',
  swap437:'请输入留言',
  swap438:'请上传图片',
  swap439:'请输入反馈内容',
  swap440:'线上客服',
  swap441:'暂无答复',
  swap442:'反馈列表',
  swap443:'暂无反馈',
  swap444:'当前等级',
  swap445:'星球用户',
  swap446:'下一等级',
  swap447:'好友推荐计划',
  swap448:'绑定关系',
  swap449:'邀请好友加入Venus,一起领取丰厚奖励，邀请朋友参与星球共识，您将获得更多收益',
  swap450:'推荐链接',
  swap451:'复制链接',
  swap452:'分享人数',
  swap453:'我的战队',
  swap454:'我的业绩',
  swap455:'等级',
  swap456:'总业绩',
  swap457:'业绩',
  swap458:'直推',
  swap459:'我的团队',
  swap460:'输入地址',
  swap461:'暂无邀请人',
  swap462:'团队',
  swap463:'输入地址绑定关系',
  swap464:'请输入邀请人地址',
  swap465:'属性',
  swap466:'幸运',
  swap467:'努力',
  swap468:'非凡',
  swap469:'成就',
  swap470:'霸业',
  swap471:'价值',
  swap472:'产能',
  swap473:'点击购买',
  swap474:'导入现有流动性池',
  swap475:'运转中',
  swap476:'已耗尽',
  swap477:'星球产出',
  swap478:'推荐奖励',
  swap479:'社区收益',
  swap480:'星球站点',
  swap481:'认购时间',
  swap482:'总价值',
  swap483:'已产值',
  swap484:'挖矿进度',
  swap485:'支付成功',
  swap486:'免费空投',
  swap487:'共享价值',
  swap488:'贡献空投',
  swap489:'已空投',
  swap490:'可领取',
  swap491:'领取',
  swap492:'领取记录',
  swap493:'时间',
  swap494:'个人',
  swap495:'获取进度',
  swap496:'邀请',
  swap497:'星球',
  swap498:'幸运转盘',
  swap499:'市场公告',
  swap500:'问题反馈',
  swap501:'了解',
  swap502:'社区治理',
  swap503:'复制',
  swap504:'星球待释放',
  swap505:'分享待释放',
  swap506:'总待释放',
  swap507:'星球已释放',
  swap508:'分享已释放',
  swap509:'总释放',
  swap510:'收益明细',
  swap511:'来源类型',
  swap512:'奖励数量',
  swap513:'推荐账单',
  swap514:'完成时间',
  swap515:'应付'
};
