import request from '../utils/request';

export default {
  //转账条件
  transferCondition(address) {
    return request.request({
      url: '/transfer/condition',
      method: "get",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
        'Authorization': address
      },
    })
  },

  //转账
  transferOut(data, address) {
    return request.request({
      url: '/transfer/out',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
        'Authorization': address 
      },
      data
    })
  },

  //转账记录
  transferRecordList(data, address) {
    return request.request({
      url: '/transfer/record-list',
      method: "get",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
        'Authorization': address
      },
      params: data
    })
  }
}