import request from '../utils/request';

export default {
    //绑定推荐人
    usBind(data, address) {
        return request.request({
            url: '/uc/bind',
            method: "post",
            headers: {
                'Lang': localStorage.getItem('lang') || '',
                'Authorization': address
            },
            data
        })
    },

    //编辑信息
    usEdit(data, address) {
        return request.request({
            url: '/uc/edit',
            method: "post",
            headers: {
                'Lang': localStorage.getItem('lang') || '',
                'Authorization': address
            },
            data
        })
    },

    //邀请信息
    usInviteInfo(address) {
        return request.request({
            url: '/uc/invite/info',
            method: "get",
            headers: {
                'Lang': localStorage.getItem('lang') || '',
                // 'signature': ''
                'Authorization': address
            }
        })
    },
    
    //邀请信息
    usInviteChildList(data,address) {
        return request.request({
            url: '/uc/children-list',
            method: "get",
            headers: {
                'Lang': localStorage.getItem('lang') || '',
                // 'signature': ''
                'Authorization': address
            },
            params:data
        })
    },

    //个人信息
    ucInfo(address) {
        return request.request({
            url: '/uc/info',
            method: "get",
            headers: {
                Authorization: address,
                'Lang': localStorage.getItem('lang') || '',
                // 'signature':''
            }
        })
    },

    //邀请人数详情
    ucInviteInfo(address) {
        return request.request({
            url: '/uc/invite/info',
            method: "get",
            headers: {
                Authorization: address,
                'Lang': localStorage.getItem('lang') || '',
                // 'signature':''
            }
        })
    },

    //注册用户
    ucRegister(data) {
        return request.request({
            url: '/register',
            method: "post",
            headers: {
                'Lang': localStorage.getItem('lang') || '',
                // 'signature':''
            },
            data
        })
    },

    //上传图片
    uploadImage(data, address) {
        return request.request({
            url: '/upload/image',
            method: "post",
            headers: {
                'Lang': localStorage.getItem('lang') || '',
                // 'signature':''
                Authorization: address,

            },
            data: data
        })
    }
}