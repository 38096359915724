<template>
  <div class="currencyList">
    <div class="van-dialog__header">
      <div>
        <div class="text">{{$t('lang.swap30')}}</div>
      </div>

      <div class="cancel"
           @click="handleClose"></div>
    </div>
    <div class="btContent">
      <div class="currencyWrap">
        <div @click="handleSelect(item)"
             v-for="(item, index) in currencyList"
             class="currency"
             :key="index">
          <div class="leftItem">
            <!-- <van-image round
                       class="tokenImg"
                       :src="item.logo" /> -->
            <div>
              <div class="symbol">{{ item.token_name }}</div>
              <div class="name"></div>
            </div>
          </div>
          <!-- <div>{{ significantDigits(item.balance) }}</div> -->
        </div>
      </div>
      <!-- <div class="manageTokens" @click="hanldeManageTokens">管理Tokens</div> -->
    </div>
  </div>
</template>

<script>
import { simpleRpcProvider } from '@/utils/provider';
import { mapState } from 'vuex';
import { getErc20Contract } from '@/utils/contractHelp';
import { formatAmount, significantDigits } from '@/utils/format';
import { BNB } from '@/config/constants/bnb';
import web3 from 'web3';
export default {
  name: 'TokenList',
  data () {
    return {
      currencyList: [],
      currencyAllList: [], //所有币种列表
    };
  },
  props: ['show', 'list'],
  computed: {
    ...mapState(['account']),
  },
  async created () {
    this.currencyList = this.list;
  },
  mounted () {

  },
  watch: {
    account () {

    },
  },
  methods: {

    handleClose () {
      this.$emit('listenClose');
    },
    handleSelect (item) {
      this.$emit('listenSelecteCurrency', item);
    },
    // },
    significantDigits,
  },
};
</script>

<style lang="less" scoped>
.currencyList {
  width: 350px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #171717;
  .van-dialog__header {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: #171717;
    border-bottom: 1px solid #fee4bf;
    font-weight: bold;
    font-size: 20px;
    color: #fff;
    position: relative;
    .cancel {
      width: 20px;
      height: 20px;
      background-image: url('~@/assets/img/cancel.png');
      background-size: contain;
      background-repeat: no-repeat;
      cursor: pointer;
    }
    .headerLeft {
      display: flex;
      align-items: center;
    }
    .turnleft {
      // position: absolute;
      // left: 10px;
      display: block;
      width: 14px;
      height: 20px;
      margin-right: 10px;
      cursor: pointer;
    }
  }
  .btContent {
    width: 100%;
    flex: 1;
    .currencyWrap {
      margin: 10px 0;
      height: 350px;
      overflow-y: auto;
      padding: 0 16px;
    }
    .currencyWrapManage {
      height: auto;
      overflow-y: visible;
    }
    .inputWrap {
      padding: 0 16px;
      .tokenInput {
        height: 40px;
        background: #f8f4ff;
        border: 1px solid #f39714;
        margin-top: 15px;
        border-radius: 20px;

        /deep/.van-field__body {
          height: 40px;
          font-size: 14px;
          padding-left: 16px;
        }
      }
    }
    .classification {
      display: flex;
      justify-content: space-around;
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      background: #f8f7f7;
      border-radius: 16px;
      margin: 18px 16px 30px;
      .currencys {
        display: flex;
        justify-content: center;
        flex: 1;
        align-items: center;
        border-radius: 16px;
        padding: 6px 0;
      }
      .special {
        background-color: #f39714;
      }
    }

    .currency {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
      cursor: pointer;
      &:first-child {
        margin-top: 0;
      }
      .leftItem {
        align-items: center;
        display: flex;
        .tokenImg {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }
        .symbol {
          font-weight: bold;
          color: #fff;
        }
        .name {
          color: #fff;
          font-size: 14px;
          line-height: 17px;
        }
      }
      .rightItem {
        display: flex;
        align-items: center;
        .delete {
          height: 10px;
          width: 10px;
        }
        .viewHash {
          margin-left: 10px;
          height: 10px;
          width: 10px;
        }
      }
      .importToken {
        width: 65px;
        // height: 30px;
        // line-height: 40px;
      }
    }
    .manageTokens {
      padding: 16px;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: #f39714;
      text-align: center;
      cursor: pointer;
    }
    .clearTokens {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      .tipText {
        font-weight: 800;
        font-size: 14px;
        line-height: 17px;

        color: #372590;
      }
      .clearAll {
        font-weight: 900;
        font-size: 16px;
        line-height: 22px;
        color: #f39714;
      }
    }
  }

  // background: red;
}
</style>
