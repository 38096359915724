<template>
  <div class="home_page">
    <div class="functional">
      <div>
        <img src="@/assets/img/vrt_banner.png"
             class="galaxy_img">
      </div>
      <div class="functional_region">
        <div class="region">
          <div v-for="(item,index) of functionOption"
               :key="index">
            <span :class="index ==regionIndex?'special':''"
                  @click="switchovers(index,item.route)">{{$t(item.name)}}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div>
      <router-view v-if="isRouterAlive" />
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // 功能列表
      functionOption: [{ name: 'lang.swap18', route: 'Swap' }, { name: 'lang.swap19', route: 'LiquidityIndex' }, { name: 'lang.swap379', route: 'pendingOrder' }],
      regionIndex: '0',
      switchVersionShow: '0',
      isRouterAlive: true
    };
  },
  created () {
    // this.getBasicList();
    this.regionIndex = this.$route.name == 'Swap' ? '0' : this.$route.name == 'LiquidityIndex' ? '1' : '2';
  },
  mounted () { },
  methods: {
    switchovers (index, route) {
      if (route != '') {
        this.regionIndex = index;
        this.$router.push({ name: route });
      } else {
        this.$toast(this.$t('lang.swap109'));
      }

    },

    // getBasicList () {
    //   let data = {
    //     page: 1,
    //     page_size: 20,
    //   };
    //   basic.getNoticeList(data, '0x3D12Bd39bB936a73575ea97dFbf308b08b84e76B', 'zh-cn').then(res => {
    //     if (res.code === 200) {
    //     }
    //   });
    // },
    // switchVersion (i) {
    //   let a = {};
    //   if (i == 0) {
    //     a = {
    //       jackRouter: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
    //       jackFactory: '0xca143ce32fe78f1f7019d7d551a6402fc5350c73',
    //     };
    //   } else {
    //     a = {
    //       jackRouter: '0x6D327b4cc271652D2bB9765A4d079a3258964a35',
    //       jackFactory: '0x04E61DF94148dD533f7f180d0B9dAC00e6E68Be0',
    //     };
    //   }
    //   localStorage.setItem('pool', JSON.stringify(a));
    //   this.$store.commit('SET_POOL', a);
    //   this.switchVersionShow = i;
    //   this.isRouterAlive = false;
    //   this.$nextTick(() => {
    //     this.isRouterAlive = true
    //   })
    // },
  },
};
</script>

<style lang="less" scoped>
.home_page{
  background: url('~@/assets/img/second_bg.png');
  background-size: 100% 100%;
  padding: 20px;
  min-height: 800px;
}
.functional {
  // background: #171717;
  // border: 1px solid #1e242b;
}
.galaxy_img {
  width: 100%;
  height: 120px;
  margin: 10px 0 0;
}
.functional_region {
  width: 100%;
  display: flex;
  justify-content: center;

  border-radius: 16px 16px 0 0;

  .region {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    line-height: 22px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    color: #fff;
    font-weight: bold;
    background: #232B3E;
    border-radius: 4px;
    margin: 12px 0;
    // padding: 2px;
    div {
      flex: 1;
    }
    span {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      padding: 8px 0;
      cursor: pointer;
      z-index: 6;
    }
    .special {
      font-size: 14px;

      color: #000000;
      border-radius: 4px;
      background:  #18D7FC;
    }
    img {
      display: none;
    }
  }
}
.support {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #a8a7ac;
  margin: 0 0 12px;
  .support_img {
    width: 18px;
    height: 18px;
    margin-left: 10px;
  }
}
.bscScan {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 0;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #eceef0;
  background: #171717;
  border-radius: 12px;
  padding: 10px 20px;
  margin-top: 20px;
  .search_img {
    width: 24px;
    height: 24px;
  }
}
</style>