<template>
  <div class="currencyList">
    <div class="van-dialog__header">
      <div v-if="status === 0">
        <div class="text">{{ $t('lang.swap30') }}</div>
      </div>
      <div v-else-if="status === 1"
           class="headerLeft"
           @click="turnLeft">
        <van-image round
                   class="turnleft"
                   :src="require('@/assets/img/leftArrow.png')" />
        <div class="text">{{ $t('lang.swap91') }}</div>
      </div>
      <div v-else-if="status === 2"
           class="headerLeft"
           @click="turnLeft">
        <van-image round
                   class="turnleft"
                   :src="require('@/assets/img/leftArrow.png')" />
        <div class="text">{{ $t('lang.swap92') }}</div>
      </div>
      <div class="cancel"
           @click="handleClose"></div>
    </div>
    <div class="btContent"
         v-if="status === 0">
      <div class="inputWrap">
        <van-field class="tokenInput"
                   v-model="searchValue"
                   :placeholder="$t('lang.swap93')"
                   @input="handleSearchValue" />
      </div>
      <div class="currencyWrap">
        <div @click="handleSelect(item)"
             v-for="(item, index) in currencyList"
             class="currency"
             :key="index">
          <div class="leftItem">
            <van-image round
                       class="tokenImg"
                       :src="item.logoUrl" />
            <div>
              <div class="symbol">{{ item.symbol }}</div>
              <div class="name">{{ item.name }}</div>
            </div>
          </div>
          <div>{{ significantDigits(item.balance) }}</div>
        </div>
        <div v-if="searchErc20.address"
             class="currency">
          <div class="leftItem">
            <van-image round
                       class="tokenImg"
                       :src="searchErc20.logoUrl" />
            <div>
              <div class="symbol">{{ searchErc20.symbol }}</div>
              <div class="name">{{ searchErc20.name }}</div>
            </div>
          </div>
          <van-button @click="importToken"
                      type="primary"
                      class="importToken">{{ $t('lang.swap94') }}</van-button>
        </div>
      </div>
      <div class="manageTokens"
           @click="hanldeManageTokens">{{ $t('lang.swap95') }}</div>
    </div>
    <div class="btContent"
         v-if="status === 1">
      <div class="inputWrap">
        <van-field class="tokenInput"
                   v-model="searchValue"
                   :placeholder="$t('lang.swap93')"
                   @input="handleSearchValueStore" />
      </div>
      <div class="currencyWrap currencyWrapManage">
        <div v-for="(item, index) in storeCurList"
             class="currency"
             :key="index">
          <div class="leftItem">
            <van-image round
                       class="tokenImg"
                       :src="item.logoUrl" />
            <div>
              <div class="symbol">{{ item.symbol }}</div>
              <div class="name">{{ item.name }}</div>
            </div>
          </div>
          <div class="rightItem">
            <van-image @click="handleDeleteCur(item, index)"
                       class="delete"
                       :src="require('@/assets/img/cancel.png')" />
            <van-image class="viewHash"
                       @click="viewToken(item.address)"
                       :src="require('@/assets/img/share.png')" />
          </div>
        </div>
      </div>
      <div class="clearTokens">
        <div class="tipText">{{ storeCurList.length }} {{ $t('lang.swap96') }}</div>
        <van-button @click="clearAll"
                    type="info"
                    class="clearAll">{{ $t('lang.swap50') }}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { simpleRpcProvider } from '@/utils/provider';
import { mapState } from 'vuex';
import { getErc20Contract } from '@/utils/contractHelp';
import { formatAmount, significantDigits } from '@/utils/format';
import { BNB } from '@/config/constants/bnb';
import web3 from 'web3';
export default {
  name: 'TokenList',
  data () {
    return {
      currencyList: [],
      currencyAllList: [], //所有币种列表
      showBnb: true,
      searchValue: '',
      searchErc20: {
        name: '',
        symbol: '',
        address: '',
        chainId: 56,
        decimals: '',
        balance: '',
        logoUrl: 'https://swap.model-swap.com/tokenImg/default.png',
      },
      status: 0,
      storeCurList: [], //自定义代币
      currencysSpecial: 0,
      defaultTokenList: [
        {
          name: 'PancakeSwap Token',
          symbol: 'CAKE',
          address: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
          chainId: 56,
          decimals: 18,
          logoURI: 'https://tokens.pancakeswap.finance/images/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82.png',
        },
        {
          name: 'Binance Pegged USDT',
          symbol: 'USDT',
          address: '0x55d398326f99059fF775485246999027B3197955',
          chainId: 56,
          decimals: 18,
          logoURI: 'https://tokens.pancakeswap.finance/images/0x55d398326f99059fF775485246999027B3197955.png',
        },
        {
          name: 'Binance Pegged Bitcoin',
          symbol: 'BTCB',
          address: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
          chainId: 56,
          decimals: 18,
          logoURI: 'https://tokens.pancakeswap.finance/images/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c.png',
        },
        {
          name: 'Ethereum Token',
          symbol: 'ETH',
          address: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
          chainId: 56,
          decimals: 18,
          logoURI: 'https://img.kjdsy.com/swan/logo/ETH.png',
        },
        {
          name: 'Filecoin',
          symbol: 'FIL',
          address: '0x0d8ce2a99bb6e3b7db580ed848240e4a0f9ae153',
          chainId: 56,
          decimals: 18,
          logoURI: 'https://img.kjdsy.com/swan/logo/FIL.png',
        },
        {
          name: 'Dogecoin',
          symbol: 'DOGE',
          address: '0xbA2aE424d960c26247Dd6c32edC70B295c744C43',
          chainId: 56,
          decimals: 8,
          logoURI: 'https://img.kjdsy.com/swan/logo/DOGE.png',
        },

        {
          name: 'BUSD Token',
          symbol: 'BUSD',
          address: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
          chainId: 56,
          decimals: 18,
          logoURI: 'https://img.kjdsy.com/swan/logo/BUSD.png',
        },
      ],
    };
  },
  props: ['show'],
  computed: {
    ...mapState(['account']),
  },
  async created () {
    try {
      this.storeCurList = localStorage.getItem('curList') ? JSON.parse(localStorage.getItem('curList')) : [];
    } catch (e) {
      this.storeCurList = [];
    }
    this.currencyList = [BNB, ...this.defaultTokenList, ...this.storeCurList];
    this.currencyAllList = [BNB, ...this.defaultTokenList, ...this.storeCurList];
  },
  mounted () {
    this.handleCurrencyList();
  },
  watch: {
    account () {
      this.handleCurrencyList();
    },
  },
  methods: {
    // 当前分类
    // classification(index) {
    //   this.currencysSpecial = index;
    //   if (index == '0') {
    //     // 主流币
    //     this.currencyList = [BNB, ...this.normalList, ...this.storeCurList];
    //   } else if (index == '1') {
    //     // DEFI
    //     this.currencyList = [...this.defiList];
    //   } else {
    //     // SWAN
    //     this.currencyList = [...this.swanList];
    //   }
    // },
    clearAll () {
      this.storeCurList = [];
      localStorage.setItem('curList', JSON.stringify([]));
      this.handleCurrencyList();
    },
    turnLeft () {
      this.searchValue = '';
      this.status = 0;
    },
    handleDeleteCur (item, index) {
      this.storeCurList.splice(index, 1);
      localStorage.setItem('curList', JSON.stringify(this.storeCurList));
      this.currencyList = this.currencyList.filter((curItem, index) => {
        return curItem.address.toLowerCase() != item.address.toLowerCase();
      });
      this.currencyAllList = this.currencyAllList.filter((curItem, index) => {
        return curItem.address.toLowerCase() != item.address.toLowerCase();
      });
    },
    hanldeManageTokens () {
      this.searchValue = '';
      this.status = 1;
    },

    handleClose () {
      this.$emit('listenClose');
    },
    importToken () {
      try {
        let storeCurList = localStorage.getItem('curList') ? JSON.parse(localStorage.getItem('curList')) : [];
        storeCurList.push(this.searchErc20);
        localStorage.setItem('curList', JSON.stringify(storeCurList));
      } catch (e) {
        let storeCurList = JSON.stringify([this.searchErc20]);
        localStorage.setItem('curList', storeCurList);
      }

      this.handleSelect(this.searchErc20);
    },
    async getErc20AllInfo (address) {
      const erc20Contract = getErc20Contract(address);
      const decimals = await erc20Contract.methods.decimals().call();
      const name = await erc20Contract.methods.name().call();
      const symbol = await erc20Contract.methods.symbol().call();
      this.searchErc20.address = address;
      this.searchErc20.decimals = decimals;
      this.searchErc20.name = name;
      this.searchErc20.symbol = symbol;
    },
    handleSelect (item) {
      this.$emit('listenSelecteCurrency', item);
      this.searchValue = '';
    },
    async handleSearchValueStore (value) {
      const lowerValue = value.toLowerCase();
      this.currencyList = this.filterList(this.currencyAllList, lowerValue);
    },
    async handleSearchValue (value) {
      const lowerValue = value.toLowerCase();
      if (!lowerValue && this.currencysSpecial == '0') {
        // 主流币
        this.currencyList = [BNB, ...this.normalList, ...this.storeCurList];
        return;
      } else if (!lowerValue && this.currencysSpecial == '1') {
        // DEFI
        // this.currencyList = [...this.defiList];
        return;
      } else if (!lowerValue && this.currencysSpecial == '2') {
        // SWAN
        // this.currencyList = [...this.swanList];
        return;
      }
      this.currencyList = this.filterList(this.currencyAllList, lowerValue);

      if (web3.utils.isAddress(lowerValue) && this.currencyList.length == 0) {
        this.getErc20AllInfo(web3.utils.toChecksumAddress(lowerValue));
      }
    },
    async handleCurrencyList () {
      let storeCurList;
      try {
        storeCurList = localStorage.getItem('curList') ? JSON.parse(localStorage.getItem('curList')) : [];
      } catch (e) {
        storeCurList = [];
      }
      const tokenList = await this.getTokensBalance([...this.defaultTokenList, ...storeCurList]);
      this.currencyList = await this.getBnbBalance(tokenList);
      this.currencyAllList = this.currencyList;
    },
    deteleObject (obj) {
      var uniques = [];
      var stringify = {};
      for (var i = 0; i < obj.length; i++) {
        var keys = Object.keys(obj[i]);
        keys.sort(function (a, b) {
          return Number(a) - Number(b);
        });
        var str = '';
        for (var j = 0; j < keys.length; j++) {
          str += JSON.stringify(keys[j]);
          str += JSON.stringify(obj[i][keys[j]]);
        }
        if (!stringify.hasOwnProperty(str)) {
          uniques.push(obj[i]);
          stringify[str] = true;
        }
      }
      uniques = uniques;
      return uniques;
    },
    filterList (list, value) {
      if (value) {
        const newList = list.filter(item => {
          return item.address.toLowerCase().includes(value) || item.name.toLowerCase().includes(value) || item.symbol.toLowerCase().includes(value);
        });

        return newList;
      }
      return list;
    },
    async getBnbBalance (list) {
      const ethObject = BNB;
      if (this.showBnb) {
        if (this.account) {
          const bnbBalance = await simpleRpcProvider.eth.getBalance(this.account);
          ethObject.balance = formatAmount(bnbBalance);
        }
        return [ethObject, ...list];
      }
      return list;
    },
    async getTokensBalance (list) {
      if (this.account) {
        const p = list.map(item => {
          const erc20Contract = getErc20Contract(item.address);
          return erc20Contract.methods.balanceOf(this.account).call();
        });
        const balances = await Promise.all(p);

        return list.map((item, index) => {
          return { ...item, balance: formatAmount(balances[index], item.decimals) };
        });
      }

      return list.map(item => {
        return { ...item, balance: '' };
      });
    },
    // },
    significantDigits,
  },
};
</script>

<style lang="less" scoped>
.currencyList {
  width: 350px;
  display: flex;
  align-items: center;
  flex-direction: column;

  background: #171717;
  .van-dialog__header {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    color: #fff;
    background: #171717;
    border-bottom: 1px solid #fee4bf;
    font-weight: bold;
    font-size: 20px;
    position: relative;
    .cancel {
      width: 20px;
      height: 20px;
      background-image: url('~@/assets/img/cancel.png');
      background-size: contain;
      background-repeat: no-repeat;
      cursor: pointer;
    }
    .headerLeft {
      display: flex;
      align-items: center;
    }
    .turnleft {
      // position: absolute;
      // left: 10px;
      display: block;
      width: 14px;
      height: 20px;
      margin-right: 10px;
      cursor: pointer;
    }
  }
  .btContent {
    width: 100%;
    flex: 1;
    .currencyWrap {
      margin-top: 30px;
      height: 350px;
      overflow-y: auto;
      padding: 0 16px;
    }
    .currencyWrapManage {
      height: auto;
      overflow-y: visible;
    }
    .inputWrap {
      padding: 0 16px;
      .tokenInput {
        height: 40px;
        background: #333333;
        border: 1px solid #f39714;
        margin-top: 15px;
        border-radius: 20px;

        /deep/.van-field__body {
          height: 40px;
          font-size: 14px;
          padding-left: 16px;
        }
      }
    }
    .classification {
      display: flex;
      justify-content: space-around;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      background: #f8f7f7;
      border-radius: 16px;
      margin: 18px 16px 30px;
      .currencys {
        display: flex;
        justify-content: center;
        flex: 1;
        align-items: center;
        border-radius: 16px;
        padding: 6px 0;
      }
      .special {
        background-color: #f39714;
      }
    }

    .currency {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
      color: #fff;

      cursor: pointer;
      &:first-child {
        margin-top: 0;
      }
      .leftItem {
        align-items: center;
        display: flex;
        .tokenImg {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }
        .symbol {
          font-weight: bold;
          color: #fff;
        }
        .name {
          color: #fff;
          font-size: 14px;
          line-height: 17px;
        }
      }
      .rightItem {
        display: flex;
        align-items: center;
        .delete {
          height: 10px;
          width: 10px;
        }
        .viewHash {
          margin-left: 10px;
          height: 10px;
          width: 10px;
        }
      }
      .importToken {
        width: 65px;
        // height: 30px;
        // line-height: 40px;
      }
    }
    .manageTokens {
      padding: 16px;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: #f39714;
      text-align: center;
      cursor: pointer;
    }
    .clearTokens {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      .tipText {
        font-weight: 800;
        font-size: 14px;
        line-height: 17px;

        color: #372590;
      }
      .clearAll {
        font-weight: 900;
        font-size: 16px;
        line-height: 22px;
        background: #f39714;
        border: 0;
        color: #fff;
      }
    }
  }

  // background: red;
}
</style>
