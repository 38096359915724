<template>
  <div class="miner">
    <div class="miner_img">
      <img src="@/assets/img/miner_bg.gif" class="galaxy_img" />
      <!-- <img src="@/assets/img/nebula.png" class="nebula_img" /> -->
    </div>
    <div class="detail_list">
      <div class="detail_top">
        <div class="universal">
          <div>
            <img src="@/assets/img/framr.png" class="universal_img" />
          </div>
          <div class="digital">
            <span class="span">{{ $t('lang.swap475') }}</span>
            <div>
              <span class="special">{{ team_active }}</span>
              <!-- <span>台</span> -->
            </div>
          </div>
          <div class="digital">
            <span class="span">{{ $t('lang.swap476') }}</span>
            <div>
              <span class="special">{{ team_stop }}</span>
              <!-- <span>台</span> -->
            </div>
          </div>
        </div>
        <div class="universal">
          <div>
            <img src="@/assets/img/frams.png" class="universal_img" />
          </div>
          <div class="digital">
            <span class="span">{{ $t('lang.swap475') }}</span>
            <div>
              <span class="special">{{ self_active }}</span>
              <!-- <span>台</span> -->
            </div>
          </div>
          <div class="digital">
            <span class="span">{{ $t('lang.swap476') }}</span>
            <div>
              <span class="special">{{ self_stop }}</span>
              <!-- <span>台</span> -->
            </div>
          </div>
        </div>
      </div>
      <div class="ling"></div>
      <div class="detail_button">
        <div class="digital">
          <span class="span">{{ $t('lang.swap477') }}</span>
          <div>
            <span class="special">{{ static_reward }}</span>
            <span>VRT</span>
          </div>
          <!-- <div>
            <span class="special">{{ ql_recommend_reward }}</span>
            <span>VRT</span>
          </div>-->
        </div>
        <div class="digital">
          <span class="span">{{ $t('lang.swap478') }}</span>
          <div class="digital-div">
            <div>
              <span class="special">{{ r_reward }}</span>
              <span>VRT</span>
            </div>
            <div>
              <span class="special">{{ r_u_reward }}</span>
              <span>USDT</span>
            </div>
          </div>
          <!-- <div>
            <span class="special">{{ ql_speed_reward }}</span>
            <span>VRT</span>
            <span>/</span>
            <span class="special">{{ hm_speed_reward }}</span>
            <span>USDT</span>
          </div>-->
        </div>
        <div class="digital">
          <span class="span">{{ $t('lang.swap479') }}</span>
          <!-- <div>
            <span class="special">{{ hm_static_reward }}</span>
            <span>USDT</span>
          </div>-->
          <div>
            <span class="special">{{ community_reward }}</span>
            <span>USDT</span>
          </div>
        </div>
      </div>
    </div>
    <div class="detailed">
      <div class="detailed_li">
        <span class="detailed_title">{{ $t('lang.swap504') }}</span>
        <span>{{card_freeze}} VRT</span>
      </div>
      <div class="detailed_li">
        <span class="detailed_title">{{ $t('lang.swap505') }}</span>
        <span>{{rr_freeze}} VRT</span>
      </div>
      <div class="detailed_li">
        <span class="detailed_title">{{ $t('lang.swap506') }}</span>
        <span>{{total_freeze}} VRT</span>
      </div>
      <div class="detailed_li_two" @click="toRecord">
        <img src="@/assets/img/minerBill.png" class="miner-img" />
      </div>

      <!-- <div class="detailed_li">
        <span class="detailed_title">市场业绩</span>
        <span>4</span>
      </div>-->
    </div>

    <div class="detailed">
      <div class="detailed_li">
        <span class="detailed_title">{{ $t('lang.swap507') }}</span>
        <span>{{card_released}} VRT</span>
      </div>
      <div class="detailed_li">
        <span class="detailed_title">{{ $t('lang.swap508') }}</span>
        <span>{{rr_released}} VRT</span>
      </div>
      <div class="detailed_li">
        <span class="detailed_title">{{ $t('lang.swap509') }}</span>
        <span>{{total_released}} VRT</span>
      </div>
      <div class="detailed_li_two">
        <img src="@/assets/img/up.png" class="miner-img" />
      </div>
    </div>

    <div class="choose">
      <span
        @click="chooseClick(0)"
        :class="chooseIndex == 0 ? 'checked' : ''"
      >{{ $t('lang.swap480') }}</span>
      <span
        @click="chooseClick(1)"
        :class="chooseIndex == 1 ? 'checked' : ''"
      >{{ $t('lang.swap475') }}</span>
      <span
        @click="chooseClick(2)"
        :class="chooseIndex == 2 ? 'checked' : ''"
      >{{ $t('lang.swap476') }}</span>
    </div>

    <div v-if="chooseIndex == 0" class="mining">
      <div v-if="marketList.length == 0" class="bare">
        <img :src="imageDomain + item.logo" class="bare_img" />
        <span>{{ $t('lang.swap118') }}</span>
      </div>
      <div class="mining_li" v-for="(item, index) of marketList" :key="index">
        <div class="planets_bg">
          <van-image :src="imageDomain + item.logo" class="li_img" fit="cover" />
        </div>

        <!-- <img :src="imageDomain + item.logo" class="li_img" /> -->
        <div class="mining_right">
          <div>
            <span style="color: #ffffff">{{ item.name }}</span>
            <span
              v-if="item.level == 1"
              class="create-time"
            >{{ $t('lang.swap465') }}：{{ $t('lang.swap466') }}</span>
            <span
              v-else-if="item.level == 2"
              class="create-time"
            >{{ $t('lang.swap465') }}：{{ $t('lang.swap467') }}</span>
            <span
              v-else-if="item.level == 3"
              class="create-time"
            >{{ $t('lang.swap465') }}：{{ $t('lang.swap468') }}</span>
            <span
              v-else-if="item.level == 4"
              class="create-time"
            >{{ $t('lang.swap465') }}：{{ $t('lang.swap469') }}</span>
            <span
              v-else-if="item.level == 5"
              class="create-time"
            >{{ $t('lang.swap465') }}：{{ $t('lang.swap470') }}</span>
          </div>
          <div class="worth">
            <span>{{ $t('lang.swap472') }}: {{ item.capacity }} VRT</span>
          </div>
          <div class="bottom">
            <span>{{ $t('lang.swap471') }}：{{ item.price }}</span>
            <div @click="buyPool(item)">
              <span>{{ $t('lang.swap288') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="chooseIndex == 1" class="mining">
      <div v-if="conductList.length == 0" class="bare">
        <img src="@/assets/img/barea.png" class="bare_img" />
        <span>{{ $t('lang.swap118') }}</span>
      </div>
      <van-list
        v-else
        v-model="conductLoading"
        :finished="conductFinished"
        finished-text=" "
        @load="getConductList"
      >
        <div class="mining_li" v-for="(item, index) of conductList" :key="index">
          <div class="planets_bg">
            <van-image :src="imageDomain + item.logo" class="li_img" fit="cover" />
          </div>
          <div class="mining_right">
            <div>
              <span style="color: #ffffff">{{ item.name }}</span>
              <span class="create-time">{{ $t('lang.swap481') }}：{{ item.created_time }}</span>
            </div>
            <div class="worth">
              <span>{{ $t('lang.swap482') }}: {{ item.capacity }}</span>
              <span>{{ $t('lang.swap483') }}: {{ item.released }}</span>
            </div>
            <div class="schedule_title">
              <span>{{ $t('lang.swap495') }}</span>
            </div>
            <div class="schedule">
              <van-progress
                :percentage="(item.released / item.capacity).toFixed(2) * 100"
                :show-pivot="false"
                stroke-width="8"
                color="#1AD9F3"
              />
              <span>{{ (item.released / item.capacity).toFixed(4) * 100 }}%</span>
            </div>
          </div>
        </div>
      </van-list>
    </div>

    <div v-if="chooseIndex == 2" class="mining">
      <div v-if="endList.length == 0" class="bare">
        <img src="@/assets/img/barea.png" class="bare_img" />
        <span>{{ $t('lang.swap118') }}</span>
      </div>
      <van-list
        v-else
        v-model="endLoading"
        :finished="endFinished"
        finished-text=" "
        @load="getEndList"
      >
        <div class="mining_li" v-for="(item, index) of endList" :key="index">
          <div class="planets_bg">
            <van-image :src="imageDomain + item.logo" class="li_img" fit="cover" />
          </div>
          <div class="mining_right">
            <div>
              <span style="color: #ffffff">{{ item.name }}</span>
              <span class="create-time">{{ $t('lang.swap481') }}：{{ item.created_time }}</span>
            </div>
            <div class="worth">
              <span>{{ $t('lang.swap482') }}: {{ item.capacity }}</span>
              <span>{{ $t('lang.swap483') }}: {{ item.released }}</span>
            </div>
            <div class="schedule_title">
              <span>{{ $t('lang.swap484') }}</span>
            </div>
            <div class="schedule">
              <van-progress
                :percentage="(item.released / item.capacity).toFixed(2) * 100"
                :show-pivot="false"
                stroke-width="8"
                color="#1AD9F3"
              />
              <span>{{ (item.created_time / item.capacity).toFixed(4) * 100 }}%</span>
            </div>
          </div>
        </div>
      </van-list>
    </div>

    <!-- 购买 -->
    <van-action-sheet v-model="sheetShow" :title="$t('lang.swap288')">
      <div class="content" v-if="buyPoolDetail">
        <!-- <div class="name">
          <span>1111</span>
        </div>
        <div>
          <span class="field_title">售价</span>
          <van-field type="number" v-model="buyPoolDetail.hm_price" placeholder class="van-field">
            <template #button class="field_button">
              <span>HM</span>
            </template>
          </van-field>
        </div>-->
        <div>
          <span class="field_title">{{ $t('lang.swap291') }}</span>
          <van-radio-group v-model="checked">
            <van-radio
              v-for="(item, index) of payTokenList"
              :key="index"
              checked-color="#ffc840"
              :class="checked == item.checkekId ? 'checkboxs' : ''"
              :name="item.checkekId"
            >
              <div class="radio">
                <span>{{ item.token }}</span>
                <img :src="imageDomain + item.logo" class="radio_img" />
              </div>
            </van-radio>
          </van-radio-group>
        </div>
        <div>
          <span class="field_title">{{ $t('lang.swap349') }}</span>
          <van-field type="number" v-model="needPay" placeholder class="van-field">
            <template #button class="field_button">
              <span>{{ payTokenList[checked].token }}</span>
            </template>
          </van-field>
        </div>
        <div class="balance">
          <span>{{ $t('lang.swap280') }}:{{ payTokenList[checked].token_id == vrtObj.token_id ? vrtObj.usable : usdtObj.usable }}</span>
        </div>
        <div>
          <van-button type="primary" class="defray_button" @click="payPool">{{ $t('lang.swap402') }}</van-button>
        </div>
      </div>
    </van-action-sheet>

    <!-- 成功 -->
    <van-popup v-model="popupShow" round>
      <div class="popup">
        <img src="@/assets/img/pay_success.png" class="popup_img" />
        <span>{{ $t('lang.swap485') }}</span>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { poolApi, newsLetterApi, walletApi, cardApi } from '@/api/index';
import Web3 from 'web3';
import pool from '../../api/pool';
export default {
  data() {
    return {
      marketList: [], //市场列表
      miningList: [], //挖矿中列表
      finishList: [], //结束列表
      chooseIndex: 0,
      sheetShow: false,
      sellingPrice: '', //售价
      defray: '', //支付
      popupShow: false,
      imageDomain: undefined,
      domain: undefined,
      buyPoolDetail: undefined,
      poolCoupons: false,
      showWithDraw: false,

      conductPageNum: 1,
      conductPageSize: 10,
      conductTotalPage: 0,
      conductLoading: false,
      conductFinished: false,
      conductList: [], //挖矿中列表

      endPageNum: 1,
      endPageSize: 10,
      endTotalPage: 0,
      endLoading: false,
      endFinished: false,
      endList: [], //结束列表

      payTokenList: [],
      checked: 0, //当前选中了那个优惠券

      vrtObj: undefined,
      usdtObj: undefined,

      team_active: 0, //团队运转数
      team_stop: 0, //团队停止数
      self_active: 0, //个人运转数
      self_stop: 0, //个人停止数
      card_freeze:0, //星球待释放VRT
      rr_freeze:0, //分享待释放VRT
      total_freeze:0, //总待释放VRT
      card_released:0, //星球已释放
      rr_released:0,//分享已释放
      total_released:0, //总释放
      r_reward: 0,
      r_u_reward: 0,
      static_reward: 0,
      community_reward: 0,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.account) {
        this.basicConfig();
      }
    },
    toRecord(){
      this.$router.push({ name: 'minerRecord' });
    },
    buyPool(item) {
      this.buyPoolDetail = item;
      this.sheetShow = true;
    },
    basicConfig() {
      newsLetterApi.basicConfig().then(res => {
        if (res.code == 200) {
          this.domain = res.data.domain;
          this.imageDomain = res.data.image_domain;
          this.getCardList();
          this.getConditionList();
          this.getWalletList();
          this.getCardStatistics();
          this.getConductList();
          this.getEndList();
          this.getCardIncomeStatistics();
          this.getWalletList();
        } else {
          this.$toast(res.msg);
        }
      });
    },
    getCardStatistics() {
      cardApi.cardStatistics(this.account).then(res => {
        this.team_active = res.data.team_active;
        this.team_stop = res.data.team_stop;
        this.self_active = res.data.self_active;
        this.self_stop = res.data.self_stop;
        this.card_freeze = res.data.card_freeze
        this.rr_freeze = res.data.rr_freeze
        this.total_freeze = res.data.total_freeze
        this.card_released = res.data.card_released
        this.rr_released = res.data.rr_released
        this.total_released = res.data.total_released
      });
    },

    getCardIncomeStatistics() {
      cardApi.cardIncomeStatistics(this.account).then(res => {
        this.r_reward = res.data.r_reward;
        this.r_u_reward = res.data.r_u_reward;
        this.static_reward = res.data.static_reward;
        this.community_reward = res.data.community_reward;


      });
    },

    getConditionList() {
      cardApi.cardCondition(this.account).then(res => {
        for (let i in res.data) {
          let a = res.data[i];
          a['checkekId'] = i * 1;
          this.payTokenList.push(a);
        }
      });
    },

    getCardList() {
      cardApi.cardList(this.account).then(res => {
        this.marketList = res.data;
        // this.venusObj = this.cardList[4]
        // this.jupiterObj = this.cardList[3]
        // this.mercuryObj = this.cardList[2]
        // this.marsObj = this.cardList[1]
        // this.saturnObj = this.cardList[0]
      });
    },
    //购买矿机
    payPool() {
      let balance = this.payTokenList[this.checked].token_id == this.vrtObj.token_id ? this.vrtObj.usable : this.usdtObj.usable;
      if (this.needPay > balance) {
        this.$toast(this.$t('lang.swap29'));
        return;
      } else {
        let web3 = new Web3(window.ethereum);
        web3.eth.personal.sign(web3.utils.fromUtf8('Card Buy'), this.account, (err, res) => {
          let data = {
            id: this.buyPoolDetail.id,
            signature: res,
            pay_token_id: this.payTokenList[this.checked].token_id,
          };
          cardApi.cardBuy(this.account, data).then(res => {
            if (res.code == 200) {
              // this.$toast("购买成功");
              this.sheetShow = false;
              this.popupShow = true;
              this.payTokenList = [];
              this.init();
            } else {
              this.$toast(res.msg);
            }
          });
        });
      }
    },

    getPoolList() {
      poolApi.poolList(this.account).then(res => {
        this.marketList = res.data;
      });
    },
    //获取挖矿中列表
    getConductList() {
      let data = {
        page_size: this.conductPageSize,
        page: this.conductPageNum,
        status: 1,
      };
      cardApi.cardHoldList(data, this.account).then(res => {
        if (res.code == 200) {
          this.conductList = this.conductList.concat(res.data.list);
          this.conductTotalPage = Math.ceil(res.data.total_count / this.conductPageSize);
          this.conductPageNum++;
          this.conductLoading = false;
          if (this.conductPageNum > this.conductTotalPage) return (this.conductFinished = true);
        }
      });
    },

    //获取结束列表
    getEndList() {
      let data = {
        page_size: this.endPageSize,
        page: this.endPageNum,
        status: 0,
      };
      cardApi.cardHoldList(data, this.account).then(res => {
        if (res.code == 200) {
          this.endist = this.endList.concat(res.data.list);
          this.endTotalPage = Math.ceil(res.data.total_count / this.endPageSize);
          this.endPageNum++;
          this.endLoading = false;
          if (this.endPageNum > this.endTotalPage) return (this.endFinished = true);
        }
      });
    },

    // 资产列表
    getWalletList() {
      walletApi.getWalletList(this.account).then(res => {
        this.walletList = res.data;
        for (let i of this.walletList) {
          if (i.token_name == 'VRT') {
            this.vrtObj = i;
          }
          if (i.token_name == 'USDT') {
            this.usdtObj = i;
          }
        }
      });
    },
    chooseClick(i) {
      this.chooseIndex = i;
    },
  },
  computed: {
    ...mapState(['account']),
    needPay() {
      if (this.buyPoolDetail) {
        return this.buyPoolDetail.price / this.payTokenList[this.checked].token_price;
      }
      return 0;
    },
  },
  watch: {
    account() {
      this.init();
    },
  },
  components: {},
};
</script>

<style lang="less" scoped>
.home_page {
}
.miner {
  // padding: 0 20px 20px;
  background: url('~@/assets/img/second_bg.png');
  background-size: 100% 100%;
  padding: 20px;
  min-height: 800px;
  .miner_img {
    position: relative;
    .galaxy_img {
      width: 100%;
      height: 120px;
      margin: 10px 0 0;
    }
    .nebula_img {
      position: absolute;
      top: 30%;
      left: 20px;
      width: 130px;
      height: 40px;
    }
  }

  .detail_list {
    background: #232b3e;
    border-radius: 4px;
    padding: 10px 10px;
    margin-top: 20px;
    .detail_top {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .universal {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 48%;

      .universal_img {
        display: block;
        width: 24px;
        height: 24px;
      }
      .digital {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        color: #797979;
        width: 40%;
        // overflow: hidden; //超出的文本隐藏
        // text-overflow: ellipsis; //溢出用省略号显示
        // white-space: nowrap; //溢出不换行
        div {
          display: flex;
          align-items: center;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          white-space: nowrap; //溢出不换行
          .span {
            // width: 100%;
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; //溢出不换行
          }
          .special {
            width: 60%;
            display: block;
            color: #fff;
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; //溢出不换行
          }
        }
      }
    }
    .detail_button {
      display: flex;
      justify-content: space-between;
      .digital {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        color: #797979;
        max-width: 25%;
        .digital-div{
          display: flex;
          align-items: flex-start;
          flex-direction: column;
        }
        // overflow: hidden; //超出的文本隐藏
        // text-overflow: ellipsis; //溢出用省略号显示
        // white-space: nowrap; //溢出不换行
        div {
          display: flex;
          align-items: center;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          white-space: nowrap; //溢出不换行
          margin-top: 4px;
          .span {
            // width: 100%;
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; //溢出不换行
            // letter-spacing: 3px;
          }
          .special {
            max-width: 60%;
            display: block;
            color: #fff;
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; //溢出不换行
            margin-right: 2px;
            // letter-spacing: 3px;
            // margin-top: 4px;
          }
        }
      }
    }
    .ling {
      height: 2px;
      background: #242424;
      margin: 10px 0;
    }
  }
  .choose {
    font-size: 12px;
    color: #797979;
    margin-top: 20px;
    span {
      margin-right: 20px;
      border-bottom: 2px solid #2a89cc00;
      padding-bottom: 4px;
    }
    .checked {
      font-size: 16px;
      color: #fff;
      border-bottom: 2px solid #2a88cc;
      padding-bottom: 4px;
      font-weight: 200;
    }
  }
  .market {
    margin-top: 20px;
    .market_list {
      display: flex;
      align-items: center;
      .market_li {
        width: 46%;
        background: #232b3e;
        border-radius: 4px;
        padding: 10px 16px 10px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .img-div {
          display: flex;
          justify-content: center;
          .li_img {
            align-items: center;
            width: 64px;
            height: 78px;
          }
        }

        .market_title {
          font-size: 14px;
          color: #ffffff;
          margin-top: 10px;
        }
        .capacity {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 10px;
          color: #999;
          white-space: nowrap; //溢出不换行
          margin: 10px 0;
        }
        .confirm {
          height: auto;
          width: 100%;
          color: #ffffff;
          font-size: 14px;
          background: linear-gradient(180deg, #1ad9f3 0%, #2a88cc 100%);
          border-radius: 2px 2px 2px 2px;
          border: 0;
          padding: 6px 0;
        }
      }
    }
  }
  .mining {
    margin-top: 20px;
    .mining_li {
      display: flex;
      align-items: center;
      background: #232b3e;
      border-radius: 4px;
      color: #999;
      padding: 10px 16px 10px;
      margin-bottom: 20px;
      .planets_bg {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90px;
        height: 90px;
        background: url('~@/assets/img/planets_bg.png');
        background-size: 100% 100%;
      }
      .mining_right {
        flex: 1;
        .create-time {
          font-size: 10px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #1ad9f3;
          margin-left: 10px;
        }
      }
      .worth {
        font-size: 12px;
        margin-top: 5px;
        span {
          margin-right: 20px;
        }
      }
      .bottom {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        span {
          font-size: 12px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
        }
        div {
          background: linear-gradient(180deg, #1ad9f3 0%, #2a88cc 100%);
          border-radius: 2px 2px 2px 2px;
          padding: 5px 20px;
        }
      }
      .schedule_title {
        font-size: 14px;
        margin: 6px 0;
      }
      .schedule {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        color: #1ad9f3;
        /deep/ .van-progress {
          width: 82%;
        }
      }
      .li_img {
        width: 44px;
        height: 44px;
        margin-right: 10px;
        margin-top: 10px;
      }
    }
  }
  .content {
    padding: 16px;
    .name {
      font-size: 14px;
      color: #000;
    }
    .field_title {
      display: block;
      font-size: 14px;
      color: #000;
      margin: 20px 0 10px;
    }
    /deep/ .van-radio {
      display: flex;
      justify-content: space-between;
      border: 1px solid #f3f3f3;
      padding: 10px;
      margin-bottom: 10px;
    }
    .van-radio-group {
      display: flex;
      justify-content: space-between;
      .van-radio {
        width: 46%;
      }
      .radio {
        display: flex;
        align-items: center;
      }
      .radio_img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        margin-left: 10px;
      }
    }
    .checkboxs {
      border: 1px solid #ffc93e;
      /deep/ .van-radio__label {
        color: #ffc93e;
      }
    }
    .van-field {
      background: #f3f3f3 !important;
      border-radius: 4px;
      padding: 6px 10px !important;
      .field_button {
        font-size: 12px;
        color: #999;
      }
    }
    .balance {
      font-size: 12px;
      color: #45abe4;
      margin-top: 10px;
    }
    .defray_button {
      width: 100%;
      height: auto;
      border: 0;
      border-radius: 4px;
      background: #ffc93e;
      padding: 10px 0;
      margin-top: 20px;
    }
  }
  .popup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 14px;
    font-weight: bold;
    padding: 10px 20px;
    .popup_img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-bottom: 8px;
    }
  }
  .bare {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    padding: 40px 0;
    .bare_img {
      width: 100px;
      height: 80px;
      margin-bottom: 8px;
    }
  }
  .checkbox {
    display: flex;
    justify-content: space-between;
    color: #999;
    border: 1px solid #f3f3f3;
    padding: 6px 10px;
  }
  .checkboxs {
    // display: flex;
    // justify-content: space-between;
    // color: #ffc840;
    // border: 1px solid #ffc840;
    // padding: 6px 10px;
    margin-bottom: 10px;
  }

  .detailed {
    display: flex;
    justify-content: space-between;
    color: #fff;
    background: #232b3e;
    border-radius: 4px;
    padding: 10px;
    font-size: 14px;
    margin: 20px 0;
    .detailed_li {
      .detailed_title {
        color: #999;
        margin-bottom: 6px;
      }
      max-width: 25%;
      display: flex;
      flex-direction: column;
      span {
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        white-space: nowrap; //溢出不换行
      }
    }
    .detailed_li_two {
      display: flex;
      justify-content: center;
      align-items: center;
      .miner-img {
        width: 30px;
        height: 30px;
      }
    }
  }
}
</style>