<template>
  <div class="home-page">
    <van-swipe class="my-swipe"
               :autoplay="3000"
               indicator-color="white"
               :show-indicators="false">
      <van-swipe-item>
        <div class="swipe-hm">
          <div class="hm-top">
            <div class="top-left">
              <span>VRT</span>
            </div>
            <div class="top-right"
                 v-if="vrtObj">
              <span class="day">{{ $t('lang.swap394') }}</span>
              <span class="number">+{{vrtObj.today_production || 0}}</span>
            </div>
          </div>
          <div class="hm-bottom">
            <div class="bottom-div-left"
                 v-if="vrtObj">
              <span class="day">{{ $t('lang.swap280') }}</span>
              <span class="number">{{vrtObj.usable}}</span>
            </div>
            <div class="bottom-div"
                 v-if="vrtObj">
              <span class="day">{{ $t('lang.swap395') }}</span>
              <span class="number">{{vrtObj.total_production || 0}}</span>
            </div>
            <div class="bottom-div-right"
                 v-if="vrtObj">
              <span class="day">{{ $t('lang.swap396') }}</span>
              <span class="number">{{vrtObj.freeze}}</span>
            </div>
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="swipe-usdt">
          <div class="hm-top">
            <div class="top-left">
              <span>USDT</span>
            </div>
            <div class="top-right"
                 v-if="usdtObj">
              <span class="day">{{ $t('lang.swap394') }}</span>
              <span class="number">+{{usdtObj.today_production || 0}}</span>
            </div>
          </div>
          <div class="hm-bottom">
            <div class="bottom-div-left"
                 v-if="usdtObj">
              <span class="day">{{ $t('lang.swap280') }}</span>
              <span class="number">{{usdtObj.usable}}</span>
            </div>
            <div class="bottom-div"
                 v-if="usdtObj">
              <span class="day">{{ $t('lang.swap395') }}</span>
              <span class="number">{{usdtObj.total_production || 0}}</span>
            </div>
            <div class="bottom-div-right"
                 v-if="usdtObj">
              <span class="day">{{ $t('lang.swap396') }}</span>
              <span class="number">{{usdtObj.freeze}}</span>
            </div>
          </div>
        </div>
      </van-swipe-item>
    </van-swipe>
    <div class="function">
      <div @click="switchRouter('c2c')"
           class="function_li orders">
        <div class="li_top">
          <span style="font-size: 18px; margin-bottom: 6px">C2C</span>
          <span>{{ $t('lang.swap104') }}</span>
        </div>
        <div class="img">
          <img src="@/assets/img/frame_bg.png"
               class="function_img" />
        </div>
      </div>
      <div @click="switchRouter('FlashExchange')"
           class="function_li flash">
        <div class="li_top">
          <span style="font-size: 18px; margin-bottom: 6px">{{ $t('lang.swap35') }}</span>
          <span>{{ $t('lang.swap104') }}</span>
        </div>
        <div class="img">
          <img src="@/assets/img/subtract_bg.png"
               class="function_img" />
        </div>
      </div>
    </div>
    <div class="application">
      <span>{{ $t('lang.swap397') }}</span>
    </div>
    <div class="application-list">
      <div class="application-div"
           v-for="(i,index) of menuList"
           :key="index"
           @click="switchovers(i)">
        <img :src="i.image"
             class="img" />
        <span>{{$t(i.name)}}</span>
      </div>
    </div>
    <div class="banner">
      <van-swipe class="my-swipe"
                 :autoplay="3000"
                 indicator-color="white">
        <van-swipe-item v-for="(item, index) of basicBannerList"
                        :key="index">
          <img :src="item.image"
               @click="switchovers(item)"
               class="banner_img" />
          <!-- <span>{{imageDomain+item.image}}</span> -->
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="application">
      <span>{{ $t('lang.swap215') }}</span>
    </div>
    <div class="quotation-explain">
      <div class="quotation-detail">
        <div class="detail-top">
          <div class="detail-detail">
            <span>{{ $t('lang.swap106') }}</span>
          </div>
          <div class="detail-detail">
            <span>{{ $t('lang.swap211') }}</span>
          </div>
          <div class="detail-detail">
            <span>{{ $t('lang.swap212') }}</span>
          </div>
          <div class="detail-right">
            <span>{{ $t('lang.swap156') }}</span>
          </div>
        </div>
        <div class="detail-currency"
             v-for="(item,index) in walletList"
             :key="index">
          <div class="currency-left">
            <img :src="imageDomain + item.logo"
                 class="currency-logo" />
            <span class="left-currency">{{item.token_name}}</span>
            <!-- <span class="left-usdt">USDT</span> -->
          </div>
          <div class="currency-left">
            <span class="left-currency">{{item.usable}}</span>
            <!-- <span class="left-usdt">USDT</span> -->
          </div>
          <div class="currency-left">
            <span class="left-currency">{{item.freeze}}</span>
            <!-- <span class="left-usdt">USDT</span> -->
          </div>
          <div class="currency-right"
               @click="switchovers({router:'recharge',index:0})">
            <img src="../../assets/img/even_mores.png"
                 class="contact-tel" />
            <!-- <span class="left-currency">HM</span> -->
            <!-- <span class="left-usdt">USDT</span> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { usApi, newsLetterApi, walletApi } from '@/api/index';
import { mapState } from 'vuex';
export default {
  data () {
    return {
      menuList: [
        {
          image: require('@/assets/img/menu/recharge.png'),
          name: 'lang.swap398',
          router: 'recharge',
          index: 0,
        },
        {
          image: require('@/assets/img/menu/withdraw.png'),
          name: 'lang.swap320',
          router: 'recharge',
          index: 1,
        },
        {
          image: require('@/assets/img/menu/transfer.png'),
          name: 'lang.swap175',
          router: 'recharge',
          index: 2,
        },
        {
          image: require('@/assets/img/menu/records.png'),
          name: 'lang.swap399',
          router: 'ordersRecord',
        },
        {
          image: require('@/assets/img/menu/help.png'),
          name: 'lang.swap400',
          router: '',
        },
        {
          image: require('@/assets/img/menu/community.png'),
          name: 'lang.swap100',
          router: 'invite',
        },
        {
          image: require('@/assets/img/gift.png'),
          name: 'lang.swap401',
          router: 'airdrop',
        },
      ],
      domain: '', //图片域名
      basicBannerList: [{ image: require('@/assets/img/turntable.jpg'), router: 'turntable' }],
      imageDomain: '',
      walletList: [], //资产列表
      vrtObj: undefined,
      usdtObj: undefined,
    };
  },
  created () {
    this.init();
  },
  methods: {
    init () {
      if (this.account) {
        this.basicConfig();
      }
    },
    // 资产列表
    getWalletList () {
      walletApi.getWalletList(this.account).then(res => {
        this.walletList = res.data;
        for (let i of this.walletList) {
          if (i.token_name == 'VRT') {
            this.vrtObj = i;
          }
          if (i.token_name == 'USDT') {
            this.usdtObj = i;
          }
        }
      });
      console.log(this.vrtObj, "wwwwwwww");
    },
    //跳转
    switchRouter (route) {
      if (route == '') return this.$toast(this.$t('lang.swap109'));
      this.$router.push({ name: route });
      // this.$Router.pushPage({
      //   name: route
      // })
    },
    basicConfig () {
      newsLetterApi.basicConfig().then(res => {
        if (res.code == 200) {
          this.domain = res.data.domain;
          this.imageDomain = res.data.image_domain;
          this.getWalletList();
          // this.basicBanner();
        } else {
          this.$toast(res.msg);
        }
      });
    },
    switchovers (item) {
      if (item.router == '') return this.$toast(this.$t('lang.swap109'));
      this.$router.push({ name: item.router, query: { index: item.index } });
      // this.$Router.pushPage({
      //   name: route
      // })
    },
  },
  computed: {
    ...mapState(['account']),
  },
  watch: {
    account () {
      this.init();
    },
  },
};
</script>

<style lang="less" scoped>
.home-page {
  background: url('~@/assets/img/second_bg.png');
  background-size: 100% 100%;
  padding: 20px;
  min-height: 800px;
  .my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    // line-height: 150px;
    text-align: center;
    // background-color: #39a9ed;
    .swipe-hm {
      // background: linear-gradient(134deg, #7a3bf2 0%, #aa53e1 100%);
      background: url('~@/assets/img/vrt_banner.png');
      background-size: 100% 100%;
      border-radius: 4px 4px 4px 4px;
      // width: 335px;
      height: 123px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .swipe-usdt {
      background: url('~@/assets/img/usdt_banner.png');
      background-size: 100% 100%;
      border-radius: 4px 4px 4px 4px;
      // width: 335px;
      height: 123px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .hm-top {
      flex: 1;
      display: flex;
      width: 100%;
      align-content: center;
      justify-content: space-between;
      padding: 12px 20px;
      .top-left {
        font-size: 22px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #ffffff;
      }
      .top-right {
        display: flex;
        flex-direction: column;
        .day {
          font-size: 12px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.6);
        }
        .number {
          font-size: 10px;
          font-family: Roboto-Medium, Roboto;
          font-weight: 500;
          color: #ffffff;
          margin-top: 5px;
          text-align: right;
        }
      }
    }
    .hm-bottom {
      flex: 1;
      display: flex;
      width: 100%;
      flex-direction: row;
      .bottom-div {
        flex: 1;
        display: flex;
        flex-direction: column;
        .day {
          font-size: 12px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.6);
        }
        .number {
          font-size: 10px;
          font-family: Roboto-Medium, Roboto;
          font-weight: 500;
          color: #ffffff;
          margin-top: 5px;
        }
      }
      .bottom-div-left {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 20px;
        .day {
          font-size: 12px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.6);
        }
        .number {
          font-size: 10px;
          font-family: Roboto-Medium, Roboto;
          font-weight: 500;
          color: #ffffff;
          margin-top: 5px;
        }
      }

      .bottom-div-right {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-right: 20px;
        .day {
          font-size: 12px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.6);
        }
        .number {
          font-size: 10px;
          font-family: Roboto-Medium, Roboto;
          font-weight: 500;
          color: #ffffff;
          margin-top: 5px;
          text-align: left;
        }
      }
    }
  }

  .function {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0 20px;
    .function_li {
      display: flex;
      // align-items: center;
      justify-content: space-between;
      width: 48%;

      border-radius: 4px;
      padding: 10px;
      .li_top {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        color: #fff;
        span {
          display: block;
        }
      }

      .img {
        display: flex;
        align-items: center;
        height: 50px;
        .function_img {
          display: block;
          width: 73px;
          height: 73px;
        }
      }
    }
    .orders {
      // background: url('~@/assets/img/c2c_bg.png');
      background: #232b3e;
      background-size: 100% 100%;
    }
    .flash {
      background: url('~@/assets/img/flash_bg.png');
      background-size: 100% 100%;
      background: #232b3e;
    }
  }
  .application {
    margin-top: 20px;
    span {
      font-size: 18px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #ffffff;
      margin-top: 20px;
    }
  }

  .application-list {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    .application-div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 0px 0px 0px 0px;
      background: #232b3e;
      padding: 10px;
      text-align: center;
      margin: 5px;
      width: 22%;
      .img {
        width: 21px;
        height: 21px;
      }
      span {
        margin-top: 5px;
        font-size: 12px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffe093;
      }
    }
  }

  .banner {
    // height: 200px;
    // background: #6cf;
    overflow: hidden;
    margin-top: 20px;
    .banner_img {
      display: block;
      width: 100%;
      border-radius: 10px;
      height: 100px;
    }
  }
  .quotation-explain {
    margin-top: 20px;
    padding: 20px;
    background: #232b3e;
    border-radius: 2px 2px 2px 2px;
  }

  .quotation-detail {
    // padding: 20px 20px 0 20px;
    // border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    .detail-top {
      display: flex;
      flex-direction: row;
      // justify-content: center;
      flex-wrap: wrap;
      justify-content: flex-start;
      .detail-detail {
        display: flex;
        // padding: 10px 0px;
        flex: 2;
        align-items: center;
        width: 22%;
        span {
          color: rgba(255, 255, 255, 0.6);
          font-size: 12px;
        }
        // width: 48%;
      }
      .detail-right {
        display: flex;
        // padding: 10px 0px;
        flex: 1;
        justify-content: flex-end;
        // align-items: flex-end;
        // width: 48%;
        span {
          color: rgba(255, 255, 255, 0.6);
          font-size: 12px;
        }
      }
    }
    .detail-currency {
      padding: 20px 0px;
      // border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      display: flex;
      flex-direction: row;
      .currency-left {
        display: flex;
        flex: 2;
        justify-content: flex-start;
        align-items: center;
        .currency-logo {
          margin-right: 5px;
          width: 18px;
          height: 18px;
        }
        .left-currency {
          width: 70px;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          white-space: nowrap; //溢出不换行
        }
        span {
          font-size: 14px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #ffffff;
        }
      }

      .currency-right {
        display: flex;
        // padding: 10px 0px;
        flex: 1;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }
  .contact-tel {
    width: 20px;
    height: 5px;
  }
}
</style>