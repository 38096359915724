import request from '../utils/request';

export default {
    //空头钱包信息
    airDropWallet(address) {
        return request.request({
            url: '/airdrop/wallet/info',
            method: "get",
            headers: {
                'Lang': localStorage.getItem('lang') || '',
                Authorization: address
            },
        })
    },
    //记录列表
    recordList(data, address) {
        return request.request({
            url: '/airdrop/record/list',
            method: "get",
            headers: {
                'Lang': localStorage.getItem('lang') || '',
                Authorization: address
            },
           params: data
        })
    },
    //行为条件
    recordBehaviors(address) {
        return request.request({
            url: '/airdrop/record/behaviors',
            method: "get",
            headers: {
                'Lang': localStorage.getItem('lang') || '',
                Authorization: address
            },
        })
    },
    //领取空头
    getRecord(data, address) {
        return request.request({
            url: '/airdrop/wallet/withdraw',
            method: "post",
            headers: {
                'Lang': localStorage.getItem('lang') || '',
                Authorization: address
            },
           data: data
        })
    },
}