<template>
  <div class="page-content">
    <div class="rule">
      <span>规则</span>
    </div>
    <img class="page_title" src="@/assets/img/turntable/title.png" />
    <van-notice-bar :scrollable="false">
      <van-swipe vertical class="notice-swipe" :autoplay="3000" :show-indicators="false">
        <van-swipe-item v-for="(item,index) of newlist" :key="index">
          <div>恭喜{{item.address}}获得{{item.name}}</div>
        </van-swipe-item>
      </van-swipe>
    </van-notice-bar>
    <div class="body">
      <div class="turntable-body">
        <div
          class="panel"
          :style="{transform: 'rotateZ(' + rotate + 'deg)', transitionDuration: duration + 's'}"
        >
          <div class="item" v-for="(item, index) of awardList" :key="index">{{ item.name }}</div>
        </div>
        <div class="start-btn" @click="startTurnTable">
          <div class="start-text">剩余{{drawTimes}}次</div>
        </div>
      </div>
    </div>
    <div class="btn">
      <div class="recording" @click="recordingClick">抽奖记录</div>
      <div class="purchasing" @click="no">购买抽奖次数</div>
    </div>

    <van-popup v-model="popupShow" position="bottom">
      <div class="popup">
        <div>
          <span class="field_title">抽奖次数购买</span>
          <div class="detriment">
            <div class="venus_logo">
              <img src="@/assets/img/venus_logo.png" class="img_logo" />
              <span>VRT</span>
            </div>
            <div>
              <van-stepper v-model="detrimentValue" input-width="40px" button-size="32px" />
            </div>
          </div>
        </div>
        <div>
          <span class="field_title">支付数量</span>
          <van-field type="number" v-model="needPay" placeholder class="van-field">
            <template #button class="field_button">
              <span>VRT</span>
            </template>
          </van-field>
        </div>
        <div class="balance">
          <span>{{ $t('lang.swap280') }}:00</span>
        </div>
        <div>
          <van-button type="primary" class="defray_button">确定</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { turntableApi, walletApi } from '@/api/index';
import Web3 from 'web3';
export default {
  data() {
    return {
      awardList: [],
      gainIndex: 0, //中奖下标
      rotate: 0,
      duration: 1.5,

      popupShow: false,
      needPay: '',
      detrimentValue: 1,
      payAmount: 0,
      payTokenId: undefined,
      balance: 0,

      drawTimes: 0,
      newlist: [],
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapState(['account']),
  },
  watch: {
    account() {
      this.init();
    },
  },
  methods: {
    init() {
      if (this.account) {
        this.getPaydition();
        this.getLuckList();
        this.getDrawTimes();
        this.getWheelLeast();
      }
    },
    getPaydition() {
      turntableApi.payCondition(this.account).then(res => {
        if (res.code == 200) {
          this.payAmount = res.data.pay_amount;
          this.payTokenId = res.data.token_id;
        }
      });
    },
    getLuckList() {
      turntableApi.luckyList(this.account).then(res => {
        if (res.code == 200) {
          this.awardList = res.data;
        }
      });
    },
    getDrawTimes() {
      turntableApi.wheelDrawTime(this.account).then(res => {
        if (res.code == 200) {
          this.drawTimes = res.data.draw_times;
        }
      });
    },
    //获取最新中奖列表
    getWheelLeast() {
      turntableApi.wheelLatest(this.account).then(res => {
        if (res.code == 200) {
          this.newlist = res.data;
          // this.drawTimes = res.data.draw_times;
        }
      });
    },
    //获取单个币种信息
    getWalletInfo() {
      let data = {
        token_id: this.payTokenId,
      };
      walletApi.getWalletInfo(data, this.account).then(res => {
        if (res.code == 200) {
          this.balance = res.data.usable;
        } else {
          this.$toast(res.msg);
        }
      });
    },

    startTurnTable() {
      if (this.drawTimes == 0) {
        this.$toast('抽奖次数不足');
        return;
      } else {
        let web3 = new Web3(window.ethereum);
        web3.eth.personal.sign(web3.utils.fromUtf8('Lucky Wheel Draw'), this.account, (err, res) => {
          let data = {
            signature: res,
          };
          turntableApi.rightDraw(data, this.account).then(res => {
            if (res.code == 200) {
              for (let i in this.awardList) {
                if (res.data.lw_id == this.awardList[i].id) {
                  this.gainIndex = i;
                }
              }
              this.start();
            }else{
              this.$toast(res.msg);
            }
          });
        });
      }
    },

    start() {
      if (this.rotate != 0) {
        this.duration = 0;
        this.rotate = 0;
        setTimeout(() => {
          this.duration = 1.5;
          this.rotate = 1440 + (this.awardList.length - this.gainIndex - 2) * 45 + 23;
          let str = '';
          for (let i in this.awardList) {
            if (i == this.gainIndex) {
              if (this.awardList[i].type == 0) {
                str = '恭喜你获得' + this.awardList[i].name;
              } else {
                str = this.awardList[i].name;
              }
            }
          }
          setTimeout(() => {
            this.$toast(str);
          }, 2000);

          this.init();
        }, 60);
      } else {
        this.rotate = 1440 + (this.awardList.length - this.gainIndex - 2) * 45 + 23;
        let str = '';
        for (let i in this.awardList) {
          if (i == this.gainIndex) {
            if (this.awardList[i].type == 0) {
              str = '恭喜你获得' + this.awardList[i].name;
            } else {
              str = this.awardList[i].name;
            }
          }
        }
        setTimeout(() => {
          this.$toast(str);
        }, 2000);
        this.init();
      }
    },
    recordingClick() {
      this.$router.push({ name: 'turntableRecord' });
    },
    no() {
      // this.popupShow = true;
      this.$toast(this.$t('lang.swap109'));
    },
  },
};
</script>

<style lang="less" scoped>
.page-content {
  width: 100vw;
  height: 100%;
  background-image: url('~@/assets/img//turntable/bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-bottom: 40px;
}

/deep/ .van-notice-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 80%;
  font-size: 14px;
  background-image: url('~@/assets/img/turntable/exhibit_bg.png');
  background-size: 100% 100%;
  // padding: 8px 10px;
  margin: 0 auto 30px;
  color: #fff;
  background-color:transparent;
  
}


/deep/ .van-notice-bar__wrap{
  display: flex;
  justify-content: center;
  align-items: center;
}

.notice-swipe {
  height: 40px;
  line-height: 40px;
}

.rule {
  margin-top: 10px;
  position: absolute;
  right: 0px;
  background: #3d3d3b;
  border-radius: 15px 0px 0px 15px;
  padding: 2px 10px;
  width: 60px;
  text-align: center;
  span {
    color: #ffffff;
  }
}

.page_title {
  width: 100%;
  display: block;
  // height: 160px;
  padding: 20px 40px;
}
.exhibit {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  font-size: 14px;
  background-image: url('~@/assets/img/turntable/exhibit_bg.png');
  background-size: 100% 100%;
  padding: 8px 0;
  margin: 0 auto 30px;
}
.body {
  display: flex;
  justify-content: center;
}
.turntable-body {
  position: relative;
  // margin: 200px auto;
  width: 80%;
  height: 80vw;
  color: #ffffff;
  // transform: translateX(50%) translateY(0);
  // background-image: url('/assets/img/turntable//circle.png');
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  &::before {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    // padding: 40px;
    width: 100%;
    height: 100%;
    background-image: url('~@/assets/img/turntable/circle_border.png');
    // background: rgba(0, 0, 0, 0.144);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    transform: scale(1.2, 1.2);
  }
}

.panel {
  position: relative;
  // margin-left: 16px;
  width: 100%;
  height: 100%;
  background-image: url('~@/assets/img/turntable/circle.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transition: all ease-in-out;
  // transition-duration: 1.5s;
}

.item {
  position: absolute;
  // top: 42vw;
  // right: 37vw;
  top: 50%;
  right: calc(50% - 0.5em);
  padding-top: 2em;
  width: 1em;
  height: 50%;
  // height: 38vw;
  box-sizing: border-box;
  font-size: 14px;
  word-break: break-all;
  transform-origin: 50% 100%;
  &:nth-child(1) {
    transform: translateY(-100%) rotateZ(24deg);
    // color: red;
    color: #FFD248;
  }
  &:nth-child(2) {
    transform: translateY(-100%) rotateZ(65deg);
  }
  &:nth-child(3) {
    transform: translateY(-100%) rotateZ(110deg);
    color: #12A9FF;
  }
  &:nth-child(4) {
    transform: translateY(-100%) rotateZ(155deg);
  }
  &:nth-child(5) {
    transform: translateY(-100%) rotateZ(202deg);
    color: #08FF93;
  }
  &:nth-child(6) {
    transform: translateY(-100%) rotateZ(247deg);
  }
  &:nth-child(7) {
    transform: translateY(-100%) rotateZ(292deg);
    color: #FF627B;
  }
  &:nth-child(8) {
    transform: translate(0, -100%) rotateZ(338deg);
  }
}

.start-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  width: 120px;
  height: 120px;
  transform: translate(-50%, -50%);
  background-image: url('~@/assets/img//turntable/btn.png');
  background-size: 100% 100%;
  .start-text {
    position: absolute;
    top: 60%;
    left: 50%;
    z-index: 4;
    padding-top: 22px;
    width: 90rpx;
    height: 90rpx;
    box-sizing: border-box;
    white-space: nowrap;
    text-align: center;
    font-size: 12px;
    transform: translate(-50%, -50%);
    background-image: url('~@/assets/img/turntable/start.png');
    background-size: 100% 100%;
    padding-bottom: 2px;
    border-bottom: 1px solid #ddd;
  }
}
.btn {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin-top: 20px;
  .recording {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44%;
    font-size: 14px;
    background-image: url('~@/assets/img/turntable/recording_bg.png');
    background-size: 100% 100%;
    padding: 8px 0;
  }
  .purchasing {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44%;
    font-size: 14px;
    background-image: url('~@/assets/img/turntable/purchasing_bg.png');
    background-size: 100% 100%;
    padding: 8px 0;
  }
}
.popup {
  // height: 400px;
  width: 100%;
  background: #fff;
  padding: 20px;

  .field_title {
    display: block;
    font-size: 14px;
    font-weight: bold;
    color: #000;
    margin: 20px 0 10px;
  }

  .detriment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .venus_logo {
      display: flex;
      align-items: center;
      color: #000;
      font-weight: bold;
      .img_logo {
        display: block;
        width: 30px;
        height: 30px;
        margin-right: 6px;
      }
    }
  }
  .van-field {
    background: #ffffff !important;
    border-radius: 4px;
    border: 1px solid #ececec;
    padding: 6px 10px !important;
    .field_button {
      font-size: 12px;
      color: #999;
    }
  }
  .balance {
    font-size: 12px;
    color: #919191;
    margin-top: 10px;
  }
  .defray_button {
    width: 100%;
    height: auto;
    border: 0;
    border-radius: 4px;
    background: linear-gradient(0deg, #2a8cce 0%, #19d7f2 100%);

    padding: 10px 0;
    margin-top: 20px;
  }
}
</style>
