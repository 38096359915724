<template>
  <div class="blind">
    <img src="@/assets/img/coming.png" class="nothing_img" />
    <span class="dec">Coming Soon</span>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
   
};
</script>

<style lang="less" scoped>
.blind{
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.dec{
    margin-top: 10px;
    color: #ffffff;
    font-size: 24px;
}
.nothing_img{
    width: 200px;
    height: 200px;
}
</style>