import { render, staticRenderFns } from "./release.vue?vue&type=template&id=041fe90b&scoped=true"
import script from "./release.vue?vue&type=script&lang=js"
export * from "./release.vue?vue&type=script&lang=js"
import style0 from "./release.vue?vue&type=style&index=0&id=041fe90b&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "041fe90b",
  null
  
)

export default component.exports