import request from '../utils/request';
export default {
    //今日收益
    cardTodayIncome(address) {
        return request.request({
            url: '/card/today-income',
            method: "get",
            headers: {
                'Lang': localStorage.getItem('lang'),
                Authorization: address
            },
        })
    },

    //卡牌列表
    cardList(address) {
        return request.request({
            url: '/card/list',
            method: "get",
            headers: {
                'Lang': localStorage.getItem('lang'),
                Authorization: address
            },
        })
    },

    //是否可领取静态收益
    cardReleased(address) {
        return request.request({
            url: '/card/released',
            method: "get",
            headers: {
                'Lang': localStorage.getItem('lang'),
                Authorization: address
            },
        })
    },

    //领取静态收益
    cardObtain(address,data) {
        return request.request({
            url: '/card/obtain',
            method: "post",
            headers: {
                'Lang': localStorage.getItem('lang'),
                Authorization: address
            },
            data:data
        })
    },

    //是否可领取推荐奖励
    cardRRReleased(address) {
        return request.request({
            url: '/card/rr-released',
            method: "get",
            headers: {
                'Lang': localStorage.getItem('lang'),
                Authorization: address
            },
        })
    },

    //领取推荐奖励
    cardRRobtain(address,data) {
        return request.request({
            url: '/card/rr-obtain',
            method: "post",
            headers: {
                'Lang': localStorage.getItem('lang'),
                Authorization: address
            },
            data:data
        })
    },

    //静态收益数量
    cardSrAmount(address) {
        return request.request({
            url: '/card/sr-amount',
            method: "get",
            headers: {
                'Lang': localStorage.getItem('lang'),
                Authorization: address
            },
        })
    },

    //推荐收益数量
    cardRrAmount(address) {
        return request.request({
            url: '/card/rr-amount',
            method: "get",
            headers: {
                'Lang': localStorage.getItem('lang'),
                Authorization: address
            },
        })
    },

    //支付条件
    cardCondition(address) {
        return request.request({
            url: '/card/pay-condition',
            method: "get",
            headers: {
                'Lang': localStorage.getItem('lang'),
                Authorization: address
            },
        })
    },

    //卡牌详情
    cardDetail(address) {
        return request.request({
            url: '/card/detail',
            method: "get",
            headers: {
                'Lang': localStorage.getItem('lang'),
                Authorization: address
            },
        })
    },

    //持有列表
    cardHoldList(data, address) {
        return request.request({
            url: '/card/hold-list',
            method: "get",
            headers: {
                'Lang': localStorage.getItem('lang'),
                Authorization: address
            },
            params: data
        })
    },

    //购买卡牌
    cardBuy(address, data) {
        return request.request({
            url: '/card/buy',
            method: "post",
            headers: {
                'Lang': localStorage.getItem('lang'),
                Authorization: address
            },
            data: data
        })
    },

    //卡牌数量统计
    cardStatistics(address) {
        return request.request({
            url: '/card/statistics',
            method: "get",
            headers: {
                'Lang': localStorage.getItem('lang'),
                Authorization: address
            },
        })
    },

    //卡牌收益统计
    cardIncomeStatistics(address) {
        return request.request({
            url: '/card/income/statistics',
            method: "get",
            headers: {
                'Lang': localStorage.getItem('lang'),
                Authorization: address
            },
        })
    },

    //个人社区信息
    cardCommunityInfo(address) {
        return request.request({
            url: '/card/community/info',
            method: "get",
            headers: {
                'Lang': localStorage.getItem('lang'),
                Authorization: address
            },
        })
    },

    //个人团队信息
    cardTeamInfo(address) {
        return request.request({
            url: '/card/team/info',
            method: "get",
            headers: {
                'Lang': localStorage.getItem('lang'),
                Authorization: address
            },
        })
    },

    //释放账单
    cardReleaseList(address,data){
        return request.request({
            url: '/card/release-list',
            method: "get",
            headers: {
                'Lang': localStorage.getItem('lang'),
                Authorization: address
            },
            params:data
        })
    },

    cardReleastTypes(address){
        return request.request({
            url: '/card/release-types',
            method: "get",
            headers: {
                'Lang': localStorage.getItem('lang'),
                Authorization: address
            },
        })
    }
}