<template>
  <div class="release">
    <div class="headWrap">
      <div>
        <span @click="checkedClick(0)"
              :class="checkedIndex == 0 ? 'jump' : 'headWrap_checked'">{{$t('lang.swap286')}}</span>
        <span @click="checkedClick(1)"
              :class="checkedIndex == 1 ? 'jump' : 'headWrap_checked'">{{$t('lang.swap285')}}</span>
      </div>
      <div class="headWrap_img"></div>
    </div>
    <div v-show="checkedIndex == 0"
         class="my">
      <div class="universal">
        <div class="universal_left">
          <span>{{$t('lang.swap287')}}</span>
        </div>
        <div class="universal_right genre">
          <span @click="merchandiseClick(true)"
                :class="merchandiseShow ? 'checked' : ''"
                class>{{$t('lang.swap288')}}</span>
          <span @click="merchandiseClick(false)"
                :class="merchandiseShow ? 'right_right' : 'checked right_right'">{{$t('lang.swap289')}}</span>
        </div>
      </div>
      <!-- 购买 -->
      <div>
        <div class="universal">
          <div class="universal_left">
            <span>{{$t('lang.swap293')}}</span>
          </div>
          <div class="universal_right">
            <van-field v-model="fromQuantity"
                       :placeholder="$t('lang.swap294')"
                       class="universal_field"
                       type="number" />
            <div @click="actionFromClick(1)"
                 class="currency">
              <span v-if="fromCurrency.name">{{ fromCurrency.name }}</span>
              <span v-else>{{$t('lang.swap295')}}</span>
              <van-icon name="arrow-down" />
            </div>
          </div>
        </div>
        <div v-show="!merchandiseShow && saleBalance !== undefined"
             class="balance">
          <span>{{$t('lang.swap22')}}: {{ saleBalance }}</span>
        </div>
        <div class="universal">
          <div class="universal_left">
            <span>{{$t('lang.swap90')}}</span>
          </div>
          <div class="universal_right">
            <van-field v-model="fromUnivalent"
                       :placeholder="$t('lang.swap292')"
                       class="universal_field"
                       type="number" />
            <span class="currency">{{ fromPaymentMethod }}</span>
          </div>
        </div>

        <div class="universal">
          <div class="universal_left">
            <span>{{$t('lang.swap290')}}</span>
          </div>
          <div class="universal_right">
            <van-field v-model="fromPaymentMethod"
                       :placeholder="$t('lang.swap291')"
                       class="universal_field"
                       readonly />
          </div>
        </div>
        <div v-show="merchandiseShow && purchaseBalance !== undefined"
             class="balance">
          <span>{{$t('lang.swap22')}}: {{ purchaseBalance }}</span>
        </div>
        <!-- <div class="universal">
          <div class="universal_left">
            <span>{{$t('lang.swap296')}}</span>
          </div>
          <div class="universal_right">
            <van-field v-model="fromMaximumLimit"
                       :placeholder="$t('lang.swap297')"
                       class="universal_field"
                       type="number" />
          </div>
        </div>
        <div class="universal">
          <div class="universal_left">
            <span>{{$t('lang.swap298')}}</span>
          </div>
          <div class="universal_right">
            <van-field v-model="fromMinimumLimit"
                       :placeholder="$t('lang.swap299')"
                       class="universal_field"
                       type="number" />
          </div>
        </div>-->

        <!-- <div class="universal">
          <div class="universal_left">
            <span>交易密码</span>
          </div>
          <div class="universal_right">
            <van-field v-model="password"
                       placeholder="请输入交易密码"
                       class="universal_field"
                       type="number" />
          </div>
        </div>-->
        <div>
          <van-button type="primary"
                      class="publish"
                      @click="publish">{{$t('lang.swap300')}}</van-button>
        </div>
        <div class="universal"
             v-if="JSON.stringify(fromCurrency) != '{}'">
          <div class="universal_left">
            <span>{{$t('lang.swap236')}}</span>
          </div>
          <div class="universal_right"></div>
        </div>
        <div class="detail"
             v-if="JSON.stringify(fromCurrency) != '{}'">
          <span>1.{{$t('lang.swap301')}}{{ fromCurrency.max_price }},{{$t('lang.swap302')}}{{ fromCurrency.min_price }},</span>
          <span>2.{{$t('lang.swap303')}}{{ fromCurrency.max_amount }},{{$t('lang.swap304')}}{{ fromCurrency.min_amount }}</span>
          <span>3.{{$t('lang.swap374')}}</span>
        </div>
      </div>
      <!-- 出售 -->
      <!-- <div v-show="!merchandiseShow">
        <div class="universal">
          <div class="universal_left">
            <span>支付方式</span>
          </div>
          <div class="universal_right">
            <van-field @click="actionClick(0)" v-model="toPaymentMethod" placeholder="请选择支付方式" class="universal_field" right-icon="arrow-down" />
          </div>
        </div>
        <div class="universal">
          <div class="universal_left">
            <span>单价</span>
          </div>
          <div class="universal_right">
            <van-field v-model="toUnivalent" placeholder="请输入单价" class="universal_field" />
            <span class="currency">{{ toPaymentMethod }}</span>
          </div>
        </div>
        <div class="universal">
          <div class="universal_left">
            <span>发布数量</span>
          </div>
          <div class="universal_right">
            <van-field v-model="toQuantity" placeholder="请输入发布数量" class="universal_field" />
            <div @click="actionClick(1)" class="currency">
              <span>{{ toCurrencyQuantity }}</span>
              <van-icon name="arrow-down" />
            </div>
          </div>
        </div>
        <div class="universal">
          <div class="universal_left">
            <span>最大限额</span>
          </div>
          <div class="universal_right">
            <van-field v-model="toMaximumLimit" placeholder="请输入最大限额" class="universal_field" />
          </div>
        </div>
        <div class="universal">
          <div class="universal_left">
            <span>最小限额</span>
          </div>
          <div class="universal_right">
            <van-field v-model="toMinimumLimit" placeholder="请输入最小限额" class="universal_field" />
          </div>
        </div>
        <div>
          <van-button type="primary" class="publish">发布</van-button>
        </div>
      </div>-->
    </div>
    <div v-show="checkedIndex == 1">
      <!-- <div class="time">
        <span>2021-09-08</span>
      </div>-->
      <div v-if="orderRecord.length == 0"
           class="nothing">
        <img src="@/assets/img/nothingBlank.png"
             class="nothing_img" />
        <span>{{$t('lang.swap118')}}</span>
      </div>
      <van-list v-else
                v-model="loading"
                :finished="finished"
                finished-text=" "
                @load="userTrades">
        <div v-for="(item, index) of orderRecord"
             :key="index"
             class="orders_li">
          <div class="li_left">
            <span>{{ item.trade_type == 0 ? $t('lang.swap305') : $t('lang.swap306') }}</span>
          </div>
          <div class="li_right">
            <div class="li_universal">
              <span class="li_name">{{$t('lang.swap307')}}：{{ item.order_no }}</span>
              <span class="small">{{$t('lang.swap90')}}</span>
            </div>
            <div class="li_universal">
              <span></span>
              <div class="univalent">
                <span>{{ item.price }}</span>
                <span style="font-size: 12px">{{ item.t2_name }}/{{ item.t1_name }}</span>
              </div>
            </div>
            <div class="li_universal">
              <span>{{$t('lang.swap269')}}：{{ item.amount - item.remain_amount }} {{$t('lang.swap270')}}：{{ (1 - item.remain_amount / item.amount) * 100 }}%</span>
              <span></span>
            </div>
            <div class="li_universal"
                 style="margin-top: 10px">
              <div class="amount">
                <span>{{$t('lang.swap181')}}：{{ item.amount }} {{ item.t1_name }}</span>
                <!-- <span>{{$t('lang.swap271')}}：{{ item.min_trade_amount }}-{{ item.max_trade_amount }} {{ item.t1_name }}</span> -->
              </div>
              <div class="button"
                   v-if="item.status == 0">
                <van-button class="cancel"
                            type="primary"
                            @click="revoke(item.id)">{{$t('lang.swap308')}}</van-button>
                <!-- <span>交易失败</span> -->
                <!-- <span>订单已取消</span> -->
                <!-- <span class="success">交易成功</span> -->
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </div>

    <van-action-sheet v-model="actionsShow"
                      :actions="actions"
                      @select="onSelect" />
    <van-action-sheet v-model="actionsFromShow"
                      :actions="fromCurrencyList"
                      @select="onFromSelect" />
  </div>
</template>

<script>
import { c2cApi, walletApi } from '../../api/index';
import { mapState } from 'vuex';
export default {
  data () {
    return {
      checkedIndex: 0,
      merchandiseShow: true, //true:购买 false:出售

      fromPaymentMethod: 'USDT', //支付方式
      fromUnivalent: 0, //单价
      fromQuantity: 0, //发布数量
      fromCurrency: {},

      fromCurrencyQuantity: '',
      fromMaximumLimit: 0, //最大限额
      fromMinimumLimit: 0, //最小限额
      password: '', //交易密码
      //可选币种
      actionsShow: false,
      actions: [],
      actionsIndex: 0,

      //交易币种
      actionsFromShow: false,
      fromCurrencyList: [],
      actionsFromIndex: 0,

      toPaymentMethod: '', //支付方式
      toUnivalent: '', //单价
      toQuantity: '', //发布数量
      toCurrencyQuantity: 'NANS',
      toMaximumLimit: '', //最大限额
      toMinimumLimit: '', //最小限额
      toPassword: '', //交易密码

      list: [],
      c2cMap: [],

      orderRecord: [], //订单记录
      page: 1, //页码
      pageSize: 10, //页数
      totalPage: 0, //总页数
      loading: false,
      finished: false,

      purchaseBalance: undefined, //购买余额
      saleBalance: undefined, //出售余额
    };
  },

  methods: {
    init () {
      if (this.account) {
        this.orderRecord = [];
        this.fromCurrencyList = [];
        this.actions = [];
        this.getCondition();
        // this.userTrades();
      }
    },
    getCondition () {
      c2cApi.condition(this.account).then(res => {
        this.list = res.data.token_list;
        for (let i of this.list) {
          let obj = {};
          obj['name'] = i.token_name;
          obj['id'] = i.token_id;
          this.actions.push(obj);
        }
        this.c2cMap = res.data.trade_map;
        if (this.c2cMap && this.fromPaymentMethod !== '') {
          if (this.fromCurrencyList.length == 0) {
            for (let i of this.c2cMap[this.fromPaymentMethod]) {
              let obj = {};
              obj['name'] = i.t1_name;
              obj['id'] = i.t1_id;
              obj['conf_id'] = i.id;
              obj['max_amount'] = i.max_amount;
              obj['min_amount'] = i.min_amount;
              obj['max_price'] = i.max_price;
              obj['min_price'] = i.min_price;
              this.fromCurrencyList.push(obj);
            }
          }
        }
      });
    },
    revoke (id) {
      const domain = {
        name: 'SER',
        version: 'v1.0',
        chainId: '56',
        verifyingContract: '',
      };

      const EIP712Domain = [
        { name: 'name', type: 'string' },
        { name: 'version', type: 'string' },
        { name: 'chainId', type: 'uint256' },
      ];

      const Primary = [
        { name: 'from', type: 'address' },
        { name: 'content', type: 'string' },
      ];

      const message = {
        from: this.account,
        content: 'C2C Cancel',
      };

      const data = JSON.stringify({
        types: {
          EIP712Domain,
          Primary,
        },
        domain,
        primaryType: 'Primary',
        message,
      });
      try {
        const from = this.account;
        ethereum
          .request({
            method: 'eth_signTypedData_v4',
            params: [from, data],
          })
          .then(splitSignature => {
            let data = {
              signature: splitSignature,
              id: id,
            };
            c2cApi.cancel(data, this.account).then(res => {
              if (res.code == 200) {
                this.$toast(this.$t('lang.swap309'));
                this.init();
                // this.$router.go(-2);
              } else {
                this.$toast(res.msg);
              }
            });
          });
      } catch (err) {
        console.error(err);
      }
    },
    checkedClick (i) {
      this.checkedIndex = i;
      this.orderRecord = [];
      this.page = 1;
      this.loading = false;
      this.finished = false;
      this.userTrades();
    },
    // 购买出售
    merchandiseClick (show) {
      this.merchandiseShow = show;
      this.fromCurrency = {};
      // this.fromPaymentMethod = '';
      this.purchaseBalance = undefined;
      this.saleBalance = undefined;
    },
    actionClick (i) {
      this.actionsIndex = i;
      this.actionsShow = true;
    },

    actionFromClick (i) {
      this.actionsFromIndex = i;
      this.actionsFromShow = true;
    },

    publish () {
      if (this.fromPaymentMethod == '') {
        this.$toast(this.$t('lang.swap291'));
        return;
      } else if (this.fromUnivalent == 0) {
        this.$toast(this.$t('lang.swap292'));
        return;
      } else if (this.fromUnivalent * 1 > this.fromCurrency.max_price || this.fromUnivalent * 1 < this.fromCurrency.min_price) {
        this.$toast(this.$t('lang.swap310'));
        return;
      } else if (this.fromQuantity == 0) {
        this.$toast(this.$t('lang.swap294'));
        return;
      } else if (this.fromQuantity * 1 > this.fromCurrency.max_amount || this.fromQuantity * 1 < this.fromCurrency.min_amount) {
        this.$toast(this.$t('lang.swap311'));
        return;
      } else if (this.fromCurrency.name == undefined) {
        this.$toast(this.$t('lang.swap312'));
        return;
      } else if (this.merchandiseShow && this.fromQuantity * this.fromUnivalent > this.purchaseBalance) {
        this.$toast(this.$t('lang.swap317'));
        return;
      } else if (!this.merchandiseShow && this.fromQuantity > this.saleBalance) {
        this.$toast(this.$t('lang.swap318'));
        return;
      } else if (this.fromQuantity * this.fromUnivalent < 30) {
        this.$toast(this.$t('lang.swap374'));
        return;
      } else {
        const domain = {
          name: 'SER',
          version: 'v1.0',
          chainId: '56',
          verifyingContract: '',
        };

        const EIP712Domain = [
          { name: 'name', type: 'string' },
          { name: 'version', type: 'string' },
          { name: 'chainId', type: 'uint256' },
        ];

        const Primary = [
          { name: 'from', type: 'address' },
          { name: 'content', type: 'string' },
        ];

        const message = {
          from: this.account,
          content: 'C2C Publish',
        };

        const data = JSON.stringify({
          types: {
            EIP712Domain,
            Primary,
          },
          domain,
          primaryType: 'Primary',
          message,
        });
        try {
          const from = this.account;
          ethereum
            .request({
              method: 'eth_signTypedData_v4',
              params: [from, data],
            })
            .then(splitSignature => {
              let data = {
                signature: splitSignature,
                conf_id: this.fromCurrency.conf_id * 1,
                trade_type: this.merchandiseShow ? 0 : 1,
                amount: this.fromQuantity * 1,
                price: this.fromUnivalent * 1,
              };
              c2cApi.publish(data, this.account).then(res => {
                if (res.code == 200) {
                  this.$toast(this.$t('lang.swap319'));
                  this.init();
                  // this.$router.go(-2);
                } else {
                  this.$toast(res.msg);
                }
              });
            });
        } catch (err) {
          console.error(err);
        }
      }
    },

    //发布订单记录
    userTrades () {
      if (!this.account) return;
      let data = {
        page: this.page,
        page_size: this.pageSize,
      };
      c2cApi.userTrades(data, this.account).then(res => {
        if (res.code == 200) {
          console.log('4');
          this.orderRecord = this.orderRecord.concat(res.data.list);
          this.totalPage = Math.ceil(res.data.total_count / this.pageSize);
          this.page++;
          this.loading = false;
          if (this.page > this.totalPage) return (this.finished = true);
        } else {
          this.$toast(res.msg);
        }
      });
    },

    onSelect (item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      if (this.actionsIndex == 0) {
        this.fromPaymentMethod = item.name;
      } else if (this.actionsIndex == 1) {
        this.fromCurrencyQuantity = item.name;
      }
      this.actionsShow = false;
      if (!this.merchandiseShow) return;
      this.getWalletInfo(item.id);
    },

    //获取单个币种信息
    getWalletInfo (id) {
      let data = {
        token_id: id,
      };
      walletApi.getWalletInfo(data, this.account).then(res => {
        if (res.code == 200) {
          if (this.merchandiseShow) {
            this.purchaseBalance = res.data.usable;
          } else {
            this.saleBalance = res.data.usable;
          }
        } else {
          this.$toast(res.msg);
        }
      });
    },

    onFromSelect (item) {
      this.fromCurrency = item;
      this.actionsFromShow = false;
      if (this.merchandiseShow) return;
      this.getWalletInfo(item.id);
    },
  },
  watch: {
    account () {
      this.orderRecord = [];
      this.init();
    },
    // fromPaymentMethod() {

    // },
  },
  created () {
    this.init();
  },
  computed: {
    ...mapState(['account', 'net']),
  },
};
</script>

<style lang="less" scoped>
.release {
  background: #ffffff;
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.11);
  border-radius: 20px;
  padding: 0 10px 20px;
  margin-top: 20px;
  .headWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0 10px;
    .jump {
      // width: 70px;
      display: inline-block;
      color: #333333;
      font-weight: bold;
      background: #ffdb1f;
      border-radius: 0 0 10px 10px;
      text-align: center;
      padding: 10px;
    }
    .headWrap_checked {
      // width: 70px;
      display: inline-block;
      font-weight: bold;

      color: #333;
      // background: #fff;
      border-radius: 0 0 10px 10px;
      text-align: center;
      padding: 10px;
    }
  }
  .my {
    margin-top: 30px;
    .balance {
      display: flex;
      justify-content: flex-end;
      font-size: 12px;
    }
    .universal {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      margin-top: 10px;
      .universal_left {
        width: 30%;
        font-weight: bold;
      }
      .universal_right {
        display: flex;
        align-items: center;
        width: 70%;
      }
      .genre {
        display: flex;
        align-items: center;
        flex-direction: row;
        // justify-content: space-between;
        .right_right {
          margin-left: 10px;
        }
        span {
          display: block;
          color: #999999;
          border: 1px solid #999999;
          border-radius: 4px;
          padding: 4px 14px;
        }
        .checked {
          color: #1263f1;
          border: 1px solid #1263f1;
        }
      }
      .universal_field {
        border: 1px solid #999999;
        border-radius: 4px;
        padding: 0 6px !important;
      }
      .currency {
        display: block;
        width: 70%;
        text-align: center;
      }
    }
    .publish {
      width: 100%;
      height: auto;
      border-radius: 30px;
      padding: 10px 0;
      margin-top: 30px;
    }
  }
  .time {
    font-size: 12px;
    color: #666666;
    margin-top: 20px;
  }
  .orders_li {
    display: flex;
    background: #ffffff;
    border-radius: 20px;
    padding: 14px 0;
    // margin-top: 20px;

    .li_left {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      font-weight: bold;
      border: 1px solid #1263f1;
      border-radius: 50%;
      margin-right: 6px;
    }
    .li_right {
      flex: 1;
      .li_name {
        font-size: 14px;
        font-weight: bold;
      }
      .li_universal {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;

        .amount {
          display: flex;
          flex-direction: column;
        }
      }
      .univalent {
        font-size: 18px;
        font-weight: bold;
        color: #1263f1;
      }
      .small {
        font-size: 12px;
        color: #666666;
      }
      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #999999;
        .cancel {
          height: auto;
          color: #1263f1;
          background: #f3961400;
          border: 1px solid #1263f1;
          border-radius: 6px;
          padding: 6px 10px;
        }
        .success {
          color: #2fa80a;
        }
      }
    }
  }
  .detail {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    span {
      margin-top: 2px;
      font-size: 12px;
      color: #999;
    }
  }
  .nothing {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    // height: 60px;
    .nothing_img {
      width: 200px;
    }
  }
}
</style>