export const lang = {
  swap1: 'The output is a valuation, you will get at least {inputOutMin} {symbol}, or the transaction will be withdrawn',
  swap2: 'The input is a valuation, you will sell up to {inputInMax} {symbol}, or the trade will be withdrawn',
  swap3: 'The output is an estimate. Your trade will be withdrawn if the price changes by more than {slippage}%',
  swap4: 'The output is an estimate. Your trade will be withdrawn if the price changes by more than {slippage}%',
  swap5: 'Confirm swap',
  swap6: 'Waiting for confirmation',
  swap7: 'Swapping',
  swap8: 'Swap to',
  swap9: 'Confirm this transaction in your wallet',
  swap10: 'Submitted transaction',
  swap11: 'View on BscScan',
  swap12: 'Add Swan-LP to Metamsak',
  swap13: 'Transaction rejected',
  swap14: 'close',
  swap15: 'Removing',
  swap16: 'and',
  swap17: 'Supplying',
  swap18: 'Exchange',
  swap19: 'liquidity',
  swap20: 'from',
  swap21: 'estimate',
  swap22: 'balance',
  swap23: 'max',
  swap24: 'Select token',
  swap25: 'to',
  swap26: 'Connect wallet',
  swap27: 'Insufficient liquidity for this transaction',
  swap28: 'input quantity',
  swap29: 'Insufficient balance',
  swap30: 'Select token',
  swap31: 'authorization',
  swap32: 'wrapper',
  swap33: 'unpack',
  swap34: 'Price influence is too high',
  swap35: 'Swap',
  swap36: 'price',
  swap37: 'Slippage tolerance',
  swap38: 'Price Impact',
  swap39: 'routing',
  swap40: 'Minimum gain',
  swap41: 'liquidity provider fee',
  swap42: 'Transaction receipt',
  swap43: 'flash pair',
  swap44: 'you will get',
  swap45: 'Maximum amount to sell',
  swap46: 'The price impact of this swap is at least 10%, please confirm this swap',
  swap47: 'Confirm',
  swap48: 'Cancel',
  swap49: 'Recent transaction',
  swap50: 'Clear all',
  swap51: 'No transaction recently',
  swap52: 'your liquidity',
  swap53: 'Pooled',
  swap54: 'Add',
  swap55: 'remove',
  swap56: 'Increase liquidity',
  swap57: 'Don t see the pool you joined? ',
  swap58: 'import it',
  swap59: 'If you stake XXX tokens on the farm, unstake them here to see them',
  swap60: 'You are the first liquidity provider. ',
  swap61: 'The ratio of tokens you add will determine the initial price of the liquidity pool. ',
  swap62: 'If you are satisfied with the exchange rate, click Supply to check it. ',
  swap63: 'input',
  swap64: 'Exchange rate and liquidity pool shares',
  swap65: 'Shares in the liquidity pool',
  swap66: 'Supply',
  swap67: 'Receive',
  swap68: 'amount',
  swap69: 'you will get',
  swap70: 'LP tokens in your wallet',
  swap71: 'Remove liquidity to withdraw tokens',
  swap72: 'Import pool',
  swap73: 'Choose a token to find your liquidity. ',
  swap74: 'You have not provided liquidity in this liquidity pool. ',
  swap75: 'Deposited',
  swap76: 'Exchange Rate',
  swap77: 'Confirm supply',
  swap78: 'Incinerated',
  swap79: 'Asset change',
  swap80: 'Withdrawal bill',
  swap81: 'withdrawal amount',
  swap82: 'handling fee',
  swap83: 'Actual arrival',
  swap84: 'passed',
  swap85: 'rejected',
  swap86: 'buy',
  swap87: 'sell',
  swap88: 'Order',
  swap89: 'Required release',
  swap90: 'unit price',
  swap91: 'Manage tokens',
  swap92: 'Import tokens',
  swap93: 'Search name or paste address',
  swap94: 'import',
  swap95: 'Manage Tokens',
  swap96: 'custom token',
  swap97: 'Home',
  swap98: 'transaction',
  swap99: 'info',
  swap100: 'Community',
  swap101: 'mine',
  swap102: 'Not connected',
  swap103: 'Applying',
  swap104: 'Enter',
  swap105: 'Quote',
  swap106: 'currency',
  swap107: 'Rise and fall',
  swap108: 'The Butterfly Tribe is a group of communities with common interests, cognition, and values ​​that unite to interact, communicate, collaborate, and infect a new platform of trust and value linking. The emotional connection and trust between them generate new tribal communities. The advent of the blockchain era has enabled the community to exert unimaginable power. Through more efficient connection and value exchange between communities and community organizations, a new efficient and efficient operating economy is formed. ',
  swap109: 'Not yet open',
  swap110: 'Butterfly Community',
  swap111: 'SER & SES dual currency linkage · Reincarnation is about to open equity subscription',
  swap112: 'Community Announcement',
  swap113: 'Contact',
  swap114: 'message',
  swap115: 'circle',
  swap116: 'Search contacts',
  swap117: 'No contact yet',
  swap118: 'No data',
  swap119: 'Block this user',
  swap120: 'Report this content',
  swap121: 'Fast forward',
  swap122: 'reference',
  swap123: 'Share',
  swap124: 'new friend',
  swap125: 'group',
  swap126: 'channel',
  swap127: 'Nearby people and groups',
  swap128: 'published',
  swap129: 'Please enter the post',
  swap130: 'Only JPG, BMP, PNG, GIF are accepted',
  swap131: 'Uploading...',
  swap132: 'Upload successful',
  swap133: 'Upload failed',
  swap134: 'Please enter the content',
  swap135: 'Please add a picture',
  swap136: 'Table cloth successfully',
  swap137: 'depth',
  swap138: 'Newsletter',
  swap139: 'Swap bill',
  swap140: 'Insufficient current balance',
  swap141: 'Please select a currency',
  swap142: 'Swap successful',
  swap143: 'earned',
  swap144: 'Annualized interest rate',
  swap145: 'reap',
  swap146: 'Pledge',
  swap147: 'in progress',
  swap148: 'Ended',
  swap149: 'I participated',
  swap150: 'Pledge lp token',
  swap151: 'Redeem lp tokens',
  swap152: 'Redeem',
  swap153: 'really',
  swap154: 'Share link',
  swap155: 'Newbie profile',
  swap156: 'more',
  swap157: 'Post image material',
  swap158: 'Common tools',
  swap159: 'FAQ',
  swap160: 'Market Mechanism',
  swap161: 'Development plan',
  swap162: 'Previous',
  swap163: 'Next',
  swap164: 'Hairband material',
  swap165: 'Hairband poster',
  swap166: 'Hairband video',
  swap167: 'Hair circle copy',
  swap168: 'Market activity',
  swap169: 'Holiday wishes',
  swap170: 'Friend Invite',
  swap171: 'Project introduction',
  swap172: 'all',
  swap173: 'transfer into',
  swap174: 'swap out',
  swap175: 'Transfer',
  swap176: 'Receipt',
  swap177: 'Please enter the transfer amount',
  swap178: 'Please enter the recipient s address',
  swap179: 'Transfer successful',
  swap180: 'Receiver',
  swap181: 'quantity',
  swap182: 'Swapable',
  swap183: 'Please choose',
  swap184: 'Please enter the quantity',
  swap185: 'Maximum transfer',
  swap186: 'Minimum transfer',
  swap187: 'Transaction value deduction',
  swap188: 'Fees deduction',
  swap189: 'Please enter the nickname to be modified',
  swap190: 'Save',
  swap191: 'Please upload a picture first',
  swap192: 'Modified successfully',
  swap193: 'I want feedback',
  swap194: 'Reply',
  swap195: 'Feedback title',
  swap196: 'Title',
  swap197: 'Please fill in the title',
  swap198: 'feedback content',
  swap199: 'Please fill in the content',
  swap200: 'attachment',
  swap201: 'commit',
  swap202: 'No reply yet',
  swap203: 'Submitted successfully',
  swap204: 'Your question',
  swap205: 'What problems do you encounter in the process of using the app, or any suggestions for the platform can be written here',
  swap206: 'Please fill in the reply content',
  swap207: 'Butterfly Academy',
  swap208: 'Invite friends',
  swap209: 'Star Center',
  swap210: 'Asset',
  swap211: 'available',
  swap212: 'frozen',
  swap213: 'Contribution value',
  swap214: 'View mining pool',
  swap215: 'Asset details',
  swap216: 'Please link the account first',
  swap217: 'Go to invite',
  swap218: 'My Referrer',
  swap219: 'No superior',
  swap220: 'My recommendation',
  swap221: 'No recommendation yet',
  swap222: 'Copy succeeded',
  swap223: 'Copy is not supported, this browser does not support automatic copy',
  swap224: 'Swap',
  swap225: 'holding pool',
  swap226: 'Receive',
  swap227: 'reading',
  swap228: 'Growth Zone',
  swap229: 'Gift area',
  swap230: 'Starting center',
  swap231: 'The Butterfly Tribe is a group of communities with common interests, cognition, and values ​​that unite to interact, communicate, collaborate, and infect a new platform of trust and value links. The emotional connection and trust between them generate new tribal communities. The advent of the blockchain era has enabled the community to exert unimaginable power. Through more efficient connection and value exchange between communities and community organizations, a new efficient and efficient operating economy is formed. ',
  swap232: 'Currently completed progress percentage',
  swap233: 'will exceed 100%',
  swap234: 'Always need to donate',
  swap235: 'You have donated',
  swap236: 'Description',
  swap237: 'The recruitment information for the leaders of xxxx is provided and responsible by xxxx',
  swap238: 'This recruitment can be carried out in excess, and it will be assessed by the xxxx community. The leader seat that fails to pass will be automatically returned to the donated funds,',
  swap239: 'member',
  swap240: 'joint partner',
  swap241: 'Shareholder partner',
  swap242: 'Congratulations, you got it',
  swap243: 'list',
  swap244: 'in progress',
  swap245: 'Completed',
  swap246: 'Number of times',
  swap247: 'Total capacity',
  swap248: 'Number pool',
  swap249: 'Number gift',
  swap250: 'Total reward',
  swap251: 'consumption',
  swap252: 'Completion times',
  swap253: 'rewarded',
  swap254: 'Remaining times',
  swap255: 'Deposit',
  swap256: 'Withdrawal',
  swap257: 'Copy',
  swap258: 'Please enter the recharge amount',
  swap259: 'Please enter the withdrawal amount',
  swap260: 'Platform balance',
  swap261: 'Deposit successful',
  swap262: 'Transfer failed',
  swap263: 'Withdrawal amount unavailable balance',
  swap264: 'Currently unavailable for withdrawal',
  swap265: 'Exceeds the current maximum amount of coins that can be withdrawn',
  swap266: 'Withdrawal succeeded',
  swap267: 'Total Assets',
  swap268: 'token',
  swap269: 'Deal',
  swap270: 'Transaction rate',
  swap271: 'limit',
  swap272: 'go to buy',
  swap273: 'go to sell',
  swap274: 'Please enter the purchase quantity',
  swap275: 'The purchase quantity must be within the limit',
  swap276: 'Exceeded balance',
  swap277: 'Purchase successful',
  swap278: 'Successful sale',
  swap279: 'My order',
  swap280: 'Available balance',
  swap281: 'Please enter the sale quantity',
  swap282: 'Remaining tradable',
  swap283: 'Buy now',
  swap284: 'Sell now',
  swap285: 'My Post',
  swap286: 'Post order',
  swap287: 'release type',
  swap288: 'buy',
  swap289: 'sale',
  swap290: 'Payment method',
  swap291: 'Please select a payment method',
  swap292: 'Please enter the unit price',
  swap293: 'Number of releases',
  swap294: 'Please enter the number of releases',
  swap295: 'Select currency',
  swap296: 'Maximum limit',
  swap297: 'Please enter the maximum limit',
  swap298: 'Minimum limit',
  swap299: 'Please enter a minimum amount',
  swap300: 'release',
  swap301: 'Enter the maximum price should be',
  swap302: 'Minimum price should be',
  swap303: 'The maximum number of inputs should be',
  swap304: 'Minimum quantity should be',
  swap305: 'buy',
  swap306: 'sell',
  swap307: 'Order number',
  swap308: 'Cancel order',
  swap309: 'Undo succeeded',
  swap310: 'Unit price is out of range',
  swap311: 'Number not in range',
  swap312: 'Please select a currency to publish',
  swap313: 'Please select the minimum amount',
  swap314: 'Please select the maximum limit',
  swap315: 'The minimum limit cannot be greater than the maximum limit',
  swap316: 'The maximum limit cannot exceed the number of releases',
  swap317: 'The purchase price cannot exceed the balance',
  swap318: 'The release quantity cannot exceed the balance',
  swap319: 'Published successfully',
  swap320: 'Withdrawal',
  swap321: 'You have joined',
  swap322: 'join',
  swap323: 'Please login first',
  swap324: 'Authorization failed',
  swap325: 'Join successfully',
  swap326: 'Actual exchange amount',
  swap327: 'Number received',
  swap328: 'Please enter the exchange amount',
  swap329: 'Please add an inviter first',
  swap330: 'Upgrade',
  swap331: 'SER is an ecological token based on DAO+ iGaming 3.0 in the DEFI field, the sub-currency SES. Through the new dual-currency linkage market promotion plan, it provides users with an entertaining experience while ensuring the continuous and stable growth of value. Nirvana from ashes, reincarnation, endless life, value immortal, SER rights recognition is starting! ',
  swap332: 'My reward',
  swap333: 'Recommended list',
  swap334: 'Address',
  swap335: 'OK will',
  swap336: 'Transfer to asset',
  swap337: 'Mining Pool Account',
  swap338: 'Wallet account',
  swap339: 'Contribution value balance',
  swap340: 'Consumption Contribution Value',
  swap341: 'Confirm swap',
  swap342: 'Increase contribution value',
  swap343: 'Transfer successfully',
  swap344: 'Insufficient contribution value',
  swap345: 'Transfer successfully',
  swap346: 'There is currently no available balance',
  swap347: 'Exceeded maximum holding',
  swap348: 'Coming soon',
  swap349: 'Payable',
  swap350: 'will get',
  swap351: 'Invitation bonus program',
  swap352: 'Invite users to help the construction of Nebula infrastructure, and become the co constructors of Nebula, you can obtain multiple benefits.',
  swap353: 'My invite link',
  swap354: 'Miner list',
  swap355: 'hold list',
  swap356: 'holding amount',
  swap357: 'Total output',
  swap358: 'Output today',
  swap359: 'Output progress',
  swap360: 'Miner',
  swap361: 'Estimated output',
  swap362: 'Deposit amount',
  swap363: 'The counterparty s account',
  swap364: 'Please enter the other party s account',
  swap365: 'Transfer amount',
  swap366: 'Transfer instructions',
  swap367: 'Internal transfer does not take blocks, no handling fee is deducted, and the account is instantly credited. If it is irreversible, please check the account of the other party carefully. ',
  swap368: 'If you need to transfer to another platform, please use the withdrawal method',
  swap369: 'Exceeding the tradable amount',
  swap370: 'Blind box',
  swap371: 'My assets',
  swap372: 'Bill list',
  swap373: 'Please bind the inviter',
  swap374: 'The total issuing price must be greater than 30USDT',
  swap375: 'The wallet balance is exceeded',
  swap376: 'Posting, please check later',
  swap377: 'New user discount',
  swap378: '----10 USDT HM for first registration',
  swap379: 'Pending order',
  swap380: 'Wallet address',
  swap381: 'Mining',
  swap382: 'Buy order',
  swap383: 'Sell order',
  swap384: 'Purchase Price',
  swap385: 'Purchase time',
  swap386: 'User',
  swap387: 'release time',
  swap388: 'sell',
  swap389: 'Buy',
  swap390: 'Latest transaction price',
  swap391: 'Post buy order',
  swap392: 'Post a sell order',
  swap393: 'stay tuned',
  swap394: 'Today s income',
  swap395: 'cumulative income',
  swap396: 'Frozen balance',
  swap397: 'Apply',
  swap398: 'Deposit currency',
  swap399: 'Fund records',
  swap400: 'Help Center',
  swap401: 'Airdrop',
  swap402: 'Pay',
  swap403: 'type',
  swap404: 'Announcement',
  swap405: 'No announcement',
  swap406: 'Today s price',
  swap407: 'Today s income',
  swap408: 'Receive planetary income',
  swap409: 'Receive shared income',
  swap410: 'Venus',
  swap411: 'Saturn',
  swap412: 'Mercury',
  swap413: 'Jupiter',
  swap414: 'Mars',
  swap415: 'Currently unable to receive static income',
  swap416: 'No income to receive',
  swap417: 'Received successfully',
  swap418: 'Currently unable to receive referral rewards',
  swap419: 'Order records',
  swap420: 'Published',
  swap421: 'Dealed',
  swap422: 'Canceled',
  swap423: ',No orders',
  swap424: 'order',
  swap425: 'Total',
  swap426: 'Price',
  swap427: 'Completed quantity',
  swap428: 'Revocable quantity',
  swap429: 'confirm',
  swap430: 'Note: There is a tax fee for trading VRT, {buyFee}% tax for buying VRT, and {sellFee}% tax for selling VRT',
  swap431: 'The current remaining tradable quantity',
  swap432: 'The balance of the number of personal accounts',
  swap433: 'Available',
  swap434: 'User feedback',
  swap435: 'Add images (up to three)',
  swap436: 'feedback',
  swap437: 'Please enter a message',
  swap438: 'Please upload an image',
  swap439: 'Please enter your feedback',
  swap440: 'Online customer service',
  swap441: 'No reply',
  swap442: 'Feedback list',
  swap443: 'No feedback at this time',
  swap444: 'Current level',
  swap445: 'Planet users',
  swap446: 'Next level',
  swap447: 'Friend referral program',
  swap448: 'Binding relationship',
  swap449: 'Invite friends to join Venus, claim great rewards together, invite friends to participate in Planet Consensus, and you will earn more',
  swap450: 'Referral link',
  swap451: 'Copy the link',
  swap452: 'Number of people to share',
  swap453: 'My team',
  swap454: 'My performance',
  swap455: 'grade',
  swap456: 'Total performance',
  swap457: 'outstanding achievement',
  swap458: 'Direct push',
  swap459: 'My team',
  swap460: 'Enter the address',
  swap461: 'There are no invitees',
  swap462: 'team',
  swap463: 'Enter the address binding',
  swap464: 'Please enter the inviter address',
  swap465: 'attribute',
  swap466: 'Lucky',
  swap467: 'effort',
  swap468: 'extraordinary',
  swap469: 'Achievement',
  swap470: 'hegemony',
  swap471: 'value',
  swap472: 'Capacity',
  swap473: 'Click Buy',
  swap474: 'Import an existing liquidity pool',
  swap475: 'In operation',
  swap476: 'Exhausted',
  swap477: 'Planet output',
  swap478: 'Referral rewards',
  swap479: 'Community gains',
  swap480: 'Planet site',
  swap481: 'Subscription time',
  swap482: 'Total value',
  swap483: 'Produced value',
  swap484: 'Mining progress',
  swap485: 'Payment successful',
  swap486: 'Free Airdrop',
  swap487: 'Shared value',
  swap488: 'Contribution airdrop',
  swap489: 'airdropped',
  swap490: 'receivable',
  swap491: 'Receive',
  swap492: 'receive record',
  swap493: 'time',
  swap494: 'personal',
  swap495: 'Get Progress',
  swap496: 'Invitation',
  swap497: 'planet',
  swap498: 'Lucky Wheel',
  swap499: 'Market Announcement',
  swap500: 'Problem feedback',
  swap501: 'Understand',
  swap502: 'Community governance',
  swap503: 'Copy',
  swap504: 'Planet to be released',
  swap505: 'Share to be released',
  swap506: 'to be released',
  swap507: 'The planet has been released',
  swap508: 'Share has been released',
  swap509: 'Total release',
  swap510: 'Revenue details',
  swap511: 'Source type',
  swap512: 'Amount of reward',
  swap513: 'Recommended bill',
  swap514:'Completion time',
  swap515:'pay'
};

