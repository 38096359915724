<template>
  <div class="list">
    <!-- <span v-for="(item,) of 31"
          :key="item">{{item}}</span> -->
    <img v-for="(item) of 31"
         :key="'venus'+item"
         :src="require(`@/assets/img/venusWhitePaper/venus${item}.png`)"
         class="li_img">
    <img v-for="(item) of 23"
         :key="'white_paper'+item"
         :src="require(`@/assets/img/whitePaper/white_paper${item}.png`)"
         class="li_img">
    <!-- <span v-for="(item) of 44"
          :key="item">{{item-1}}</span> -->
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  }
}
</script>

<style lang="less" scoped>
.list {
  padding: 20px;
}
.li_img {
  width: 100%;
}
</style>