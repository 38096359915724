<template>
  <div class="record">
    <div class="title">
      <span>{{ $t('lang.swap419') }}</span>
    </div>
    <div class="headWrap">
      <span @click="checkedClick(0)" :class="checkedIndex == 0 ? 'checked' : 'jump'">{{ $t('lang.swap420') }}</span>
      <span @click="checkedClick(2)" :class="checkedIndex == 2 ? 'checked' : 'jump'">{{ $t('lang.swap421') }}</span>
      <span @click="checkedClick(1)" :class="checkedIndex == 1 ? 'checked' : 'jump'">{{ $t('lang.swap422') }}</span>
    </div>
    <div v-show="checkedIndex == 0" class="list">
      <div v-if="recordList.length == 0" class="nothing">
        <img src="@/assets/img/nothingBlank.png" class="nothing_img" />
        <span>{{ $t('lang.swap423') }}</span>
      </div>
      <van-list v-else v-model="loading" :finished="finished" :immediate-check="false" finished-text=" " @load="userTrades">
        <div v-for="(item, index) of recordList" :key="index" class="li">
          <div class="universal">
            <span class="universal_left">{{ item.trade_type == 0 ? $t('lang.swap305') : $t('lang.swap306') }}{{ $t('lang.swap424') }}</span>
            <span class="universal_right" @click="cancelOrder(item.id)">{{ $t('lang.swap308') }}</span>
          </div>
          <div class="universal">
            <span>VRT{{ $t('lang.swap90') }}</span>
            <span>{{ item.price }}</span>
          </div>
          <div class="universal">
            <span>VRT{{ $t('lang.swap425') }}：</span>
            <span>{{ (item.amount * 1).toFixed(4) }}</span>
          </div>
          <div class="universal">
            <span>VRT{{ $t('lang.swap426') }}：</span>
            <span>{{ (item.price * item.amount * 1).toFixed(4) }}</span>
          </div>
          <div class="universal">
            <span>{{ $t('lang.swap427') }}：</span>
            <span>{{ (item.amount - item.remain_amount * 1).toFixed(4) }}</span>
          </div>
          <div class="universal">
            <span>{{ $t('lang.swap428') }}：</span>
            <span>{{ (item.remain_amount * 1).toFixed(4) }}</span>
          </div>
          <div class="universal">
            <span>{{ $t('lang.swap387') }}：</span>
            <span>{{ item.created_time }}</span>
          </div>
        </div>
      </van-list>
    </div>
    <div v-show="checkedIndex == 2" class="list">
      <div v-if="recordList.length == 0" class="nothing">
        <img src="@/assets/img/nothingBlank.png" class="nothing_img" />
        <span>{{ $t('lang.swap423') }}</span>
      </div>
      <van-list v-else v-model="loading" :finished="finished" :immediate-check="false" finished-text=" " @load="userTrades">
        <div v-for="(item, index) of recordList" :key="index" class="li">
          <div class="universal">
            <span class="universal_left">{{ item.trade_type == 0 ? $t('lang.swap305') : $t('lang.swap306') }}{{ $t('lang.swap424') }}</span>
            <span class="complete">{{ $t('lang.swap245') }}</span>
          </div>
          <div class="universal">
            <span>VRT{{ $t('lang.swap90') }}</span>
            <span>{{ item.price }}</span>
          </div>
          <div class="universal">
            <span>VRT{{ $t('lang.swap425') }}：</span>
            <span>{{ item.out_amount }}</span>
          </div>
          <div class="universal">
            <span>VRT{{ $t('lang.swap426') }}：</span>
            <span>{{ (item.price * item.out_amount).toFixed(4) }}</span>
          </div>
          <!-- <div class="universal">
            <span>{{ $t('lang.swap427') }}：</span>
            <span>{{ item.amount - item.remain_amount }}</span>
          </div>
          <div class="universal">
            <span>{{ $t('lang.swap428') }}：</span>
            <span>{{ item.remain_amount }}</span>
          </div> -->
          <div class="universal">
            <span>{{ $t('lang.swap514') }}:</span>
            <span>{{ item.created_time }}</span>
          </div>
        </div>
      </van-list>
    </div>
    <div v-show="checkedIndex == 1" class="list">
      <div v-if="recordList.length == 0" class="nothing">
        <img src="@/assets/img/nothingBlank.png" class="nothing_img" />
        <span>{{ $t('lang.swap423') }}</span>
      </div>
      <van-list v-else v-model="loading" :finished="finished" :immediate-check="false" finished-text=" " @load="userTrades">
        <div v-for="(item, index) of recordList" :key="index" class="li">
          <div class="universal">
            <span class="universal_left">{{ item.trade_type == 0 ? '买' : '卖' }}单</span>
            <span class="complete">{{ $t('lang.swap422') }}</span>
          </div>
          <div class="universal">
            <span>VRT{{ $t('lang.swap90') }}</span>
            <span>{{ item.price }}</span>
          </div>
          <div class="universal">
            <span>VRT{{ $t('lang.swap425') }}：</span>
            <span>{{ item.amount }}</span>
          </div>
          <div class="universal">
            <span>VRT{{ $t('lang.swap426') }}：</span>
            <span>{{ item.price * item.amount }}</span>
          </div>
          <div class="universal">
            <span>{{ $t('lang.swap427') }}：</span>
            <span>{{ item.amount - item.remain_amount }}</span>
          </div>
          <div class="universal">
            <span>{{ $t('lang.swap428') }}：</span>
            <span>{{ item.remain_amount }}</span>
          </div>
          <div class="universal">
            <span>{{ $t('lang.swap387') }}：</span>
            <span>{{ item.created_time }}</span>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { c2cApi } from '../../api/index';
import { mapState } from 'vuex';
import Web3 from 'web3';
export default {
  data() {
    return {
      checkedIndex: 0,
      recordList: [], //记录列表
      page: 1, //页码
      pageSize: 10, //页数
      totalPage: 0, //总页数
      loading: false,
      finished: false,
      backtrackShow: false, //返回状态
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.account) {
        this.userTrades();
      }
    },
    cancelOrder(id) {
      let web3 = new Web3(window.ethereum);
      web3.eth.personal.sign(web3.utils.fromUtf8('C2C Cancel'), this.account, (err, res) => {
        let data = {
          signature: res,
          id: id,
        };
        c2cApi.cancel(data, this.account).then(res => {
          if (res.code == 200) {
            this.$toast(this.$t('lang.swap422'));
            this.page = 1;
            this.loading = false;
            this.finished = false;
            this.recordList = [];
            this.init();
          } else {
            this.$toast(res.msg);
          }
        });
      });
    },
    checkedClick(i) {
      if (this.backtrackShow) return;
      this.backtrackShow = true;
      this.checkedIndex = i;
      this.page = 1;
      this.totalPage = 0;
      this.recordList = [];
      this.loading = false;
      this.finished = false;
      if(i == 2){
        this.userTradesDeal()
      }else{
        this.userTrades();
      }
      
    },
    userTrades() {
      if (!this.account) return;
      let data = {
        page: this.page,
        page_size: this.pageSize,
        status: this.checkedIndex,
        // trade_type: this.checkedIndex,
      };
      c2cApi.userTrades(data, this.account).then(res => {
        this.backtrackShow = false;

        if (res.code == 200) {
          this.recordList = this.recordList.concat(res.data.list);
          this.totalPage = Math.ceil(res.data.total_count / this.pageSize);
          this.page++;
          this.loading = false;
          if (this.page > this.totalPage) return (this.finished = true);
        } else {
          this.$toast(res.msg);
        }
      });
    },

    userTradesDeal() {
      let data = {
        page: this.page,
        page_size: this.pageSize,
        status: -1,
        // trade_type: this.checkedIndex,
      };
      c2cApi.tradeRecords(data, this.account).then(res => {
        this.backtrackShow = false;

        if (res.code == 200) {
          this.recordList = this.recordList.concat(res.data.list);
          this.totalPage = Math.ceil(res.data.total_count / this.pageSize);
          this.page++;
          this.loading = false;
          if (this.page > this.totalPage) return (this.finished = true);
        } else {
          this.$toast(res.msg);
        }
      });
    },
  },
  watch: {
    account() {
      this.init();
    },
  },
  computed: {
    ...mapState(['account']),
  },
};
</script>

<style lang="less" scoped>
.record {
  background: url('~@/assets/img/second_bg.png');
  background-size: 100% 100%;
  padding: 20px;
  min-height: 800px;
  .title {
    color: #fff;
    margin: 20px 0;
  }
  .headWrap {
    display: flex;
    border-radius: 4px 4px 0 0;
    overflow: hidden;
    // width: 100%;

    // position: absolute;
    // right: 0;
    // display: flex;
    // align-items: center;
    // justify-content: flex-end;
    // padding: 0 16px 0 30px;
    margin-top: 20px;
    .jump {
      display: block;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
      // background: #000;
      border: 2px solid #232b3e;
      padding: 10px 20px;
    }
    .checked {
      // width: 70px;
      display: inline-block;
      color: #fff;
      font-size: 14px;

      border: 2px solid #232b3e;
      background: #232b3e;

      text-align: center;
      padding: 10px 20px;
    }
  }
  .list {
    background: #232b3e;
    .li {
      border-bottom: 1px solid #333;
      padding: 10px;
      .universal {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        color: #999;
        margin-bottom: 16px;
      }
      .universal_left {
        background: #363636;
        border-radius: 2px 2px 2px 2px;
        border: 1px solid #363636;
        padding: 8px 20px;
        color: #fff;
      }
      .universal_right {
        color: #18d7fc;
        border: 1px solid #18d7fc;
        padding: 8px 20px;
      }
      .complete {
        border: 1px solid #545253;
        padding: 8px 20px;
      }
    }
  }
  .nothing {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    // height: 60px;
    padding-bottom: 30px;
    .nothing_img {
      width: 200px;
    }
  }
}
</style>