<template>
  <div class="home_page">
    <div class="headWrap">
      <span @click="switchovers('FlashExchange')"
            class="checked">{{$t('lang.swap35')}}</span>
      <span class="jump">{{$t('lang.swap139')}}</span>
    </div>
    <div class="bill">
      <div class="bill_list">
        <div v-if="billList.length == 0"
             class="nothing">
          <img src="@/assets/img/nothingBlank.png"
               class="nothing_img">
          <span>{{$t('lang.swap118')}}</span>
        </div>
        <van-list v-else
                  v-model="loading"
                  :finished="finished"
                  finished-text=""
                  @load="exchangeList">
          <div v-for="(item,index) of billList"
               :key="index"
               class="bill_li"
               :class="index == billList.length -1?'':'ling'">
            <div>
              <span>{{item.t1_name}}{{$t('lang.swap18')}}{{item.t2_name}}</span>
              <!-- <span class="dust">-{{item.amount}}{{item.t1_name}}</span> -->
              <span></span>
            </div>
            <div>
              <span class="dust">{{item.created_time}}</span>
              <span class="quantity">＋{{item.real_exchange_amount}}{{item.t2_name}}</span>
            </div>
          </div>
        </van-list>
      </div>
    </div>
  </div>

</template>

<script>
import { exchangeApi } from '@/api/index';
import { mapState } from 'vuex';
export default {
  data () {
    return {
      billList: [],
      page: 1,
      pageSize: 10, //页数
      totalPage: 0, //总页数
      loading: false,
      finished: false,
    }
  },
  created () {
    this.init();
  },
  methods: {
    init () {
      if (this.account) {
        this.exchangeList()
      }
    },
    exchangeList () {
      if (!this.account) return
      let data = {
        page: this.page,
        page_size: this.pageSize
      }
      exchangeApi.exchangeList(data, this.account).then((res) => {
        if (res.code == 200) {
          this.billList = this.billList.concat(res.data.list)
          this.totalPage = Math.ceil(res.data.total_count / this.pageSize);
          this.page++;
          this.loading = false;
          if (this.page > this.totalPage) return (this.finished = true);
        } else {
          this.$toast(res.msg)
        }
      })
    },
    //跳转
    switchovers (route) {
      this.$router.push({ name: route });
    },
  },
  watch: {
    account () {
      this.billList = []
      this.init();
    }
  },
  computed: {
    ...mapState(['account', 'net']),
  },
}
</script>

<style lang="less" scoped>
.home_page {
  background: url('~@/assets/img/second_bg.png');
  background-size: 100% 100%;
  padding: 20px;
  min-height: 800px;
}
.headWrap {
  display: flex;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  // width: 100%;

  // position: absolute;
  // right: 0;
  // display: flex;
  // align-items: center;
  // justify-content: flex-end;
  // padding: 0 16px 0 30px;
  margin-top: 20px;
  .jump {
    display: block;
    font-size: 14px;
    color: #ffffff;
    background: #232B3E;
    border: 2px solid #232B3E;
    padding: 10px 20px;
    border: 2px solid #232B3E;
  }
  .checked {
    // width: 70px;
    display: inline-block;
    color: rgba(255,255,255,0.6);
    font-size: 14px;
    border: 2px solid #232b3e;
    // background: #000;
    text-align: center;
    padding: 10px;
  }
}
.bill {
  background: #232B3E;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1607843137254902);
  padding: 0 10px ;
  // margin-top: 20px;
  .bill_list {
    // padding: 10px;
    .bill_li {
      // margin: 0 0 10px;
      padding: 10px;
      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #fff;
      }
      .dust {
        font-size: 12px;
        color: #999;
      }
      .quantity {
        font-size: 14px;
        color: #45abe4;
      }
    }
    .ling {
      border-bottom: 1px solid #2b2b2b;
    }
    .nothing {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      color: #fff;

      margin: 60 0 50px;
      .nothing_img {
        width: 200px;
      }
    }
  }
}
</style>