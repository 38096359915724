<template>
  <div class="settingsModal">
    <div class="van-dialog__header">
      <div class="text">设置</div>
      <div class="cancel"
           @click="handleClose"></div>
    </div>
    <div class="van-hairline--top van-dialog__footer"></div>
    <div class="van-content">
      <div class="van-content-top">
        <div class="global">全局</div>
        <div class="van-content-title">默认交易速度(GWEI)</div>
        <div class="van-content-top-tab">
          <van-button :type="gasPrice == 5000000000 ? 'primary' : 'info'"
                      class="gasPrice"
                      :style="{color: gasPrice == 5000000000  ?'#fff':''}"
                      @click="handlePrice(5000000000)">标准(5)</van-button>
          <van-button :type="gasPrice == 6000000000 ? 'primary' : 'info'"
                      class="gasPrice"
                      :style="{color:gasPrice == 6000000000  ?'#fff':''}"
                      @click="handlePrice(6000000000)">快速(6)</van-button>
          <van-button :type="gasPrice == 7000000000 ? 'primary' : 'info'"
                      class="gasPrice"
                      :style="{color: gasPrice == 7000000000  ?'#fff':''}"
                      @click="handlePrice(7000000000)">即时(7)</van-button>
        </div>
      </div>

      <div class="van-content-bottom">
        <div class="global">全局</div>
        <div class="van-content-title">滑点容差</div>
        <div class="van-content-bottom-tab">
          <van-button :type="slippage == 0.1 ? 'primary' : 'info'"
                      :style="{color: slippage == 0.1 ?'#fff':''}"
                      class="cur"
                      @click="handleSlippage(0.1)">0.1%</van-button>
          <van-button :type="slippage == 0.5 ? 'primary' : 'info'"
                      class="cur"
                      :style="{color: slippage == 0.5 ?'#fff':''}"
                      @click="handleSlippage(0.5)">0.5%</van-button>
          <van-button :type="slippage == 1 ? 'primary' : 'info'"
                      class="cur"
                      :style="{color: slippage == 1 ?'#fff':''}"
                      @click="handleSlippage(1)">1.0%</van-button>
          <div class="inputDiv1">
            <van-field type="number"
                       class="slipInput"
                       input-align="center"
                       @input="onInputDeadlineSlippage"
                       v-model="slippage" />
            <div class="uint">%</div>
          </div>
        </div>
        <div class="tip1"
             v-if="slippage >= 50">输入有效的滑点百分比</div>
        <div class="tip1"
             v-else-if="slippage == 0">你的交易可能会失败</div>
        <div class="tip1"
             v-else-if="slippage > 5">你的交易可能会被超前</div>
        <div class="van-content-bottom-select">
          <div class="select-list">
            <div class="label">交易截止时间(分钟)</div>
            <van-field type="number"
                       class="deadInput"
                       input-align="center"
                       @input="onInputDeadline"
                       v-model="deadline" />
          </div>
          <!-- <div class="select-list">
            <div class="label">专家模式</div>
            <div class="select"><van-switch v-model="checked" size="25px" /></div>
          </div>-->
          <div class="select-list">
            <div class="label">禁用多跳</div>
            <div class="select">
              <van-switch v-model="multipath"
                          size="25px"
                          @change="handleMultipath" />
            </div>
          </div>
          <!-- <div class="select-list">
            <div class="label">专家模式</div>
            <div class="select">
              <van-switch v-model="expert"
                          size="25px"
                          @change="handleExpert" />
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  data() {
    return {
      gasPrice: '',
      slippage: '',
      deadline: 20,
      multipath: false,
      expert: false,
    };
  },
  computed: {
    ...mapState('ModalStore', {
      settingsModal: state => state.settingsModal,
    }),
  },
  created() {
    this.gasPrice = this.$store.state.gasPrice;
    this.slippage = this.$store.state.slippage;
    this.deadline = this.$store.state.deadline;
    this.multipath = this.$store.state.multipath;
    this.expert = this.$store.state.expert;
  },
  methods: {
    handleClose() {
      this.$emit('listenClose');
    },
    handlePrice(gasPrice) {
      this.gasPrice = gasPrice;
      localStorage.setItem('gasPrice', gasPrice);
      this.$store.commit('SET_GASPRICE', gasPrice);
    },
    handleSlippage(slippage) {
      this.slippage = slippage;
      localStorage.setItem('slippage', slippage);
      this.$store.commit('SET_SLIPPAGE', slippage);
    },
    handleMultipath(multipath) {
      this.multipath = multipath;
      localStorage.setItem('multipath', multipath);
      this.$store.commit('SET_MULTIPATH', multipath);
    },
    onInputDeadline(deadLine) {
      this.deadLine = deadLine;
      localStorage.setItem('deadLine', deadLine);
      this.$store.commit('SET_DEADLINE', deadLine);
    },
    //专家模式
    handleExpert(ex) {
      this.expert = ex;
      localStorage.setItem('expert', this.expert);
      this.$store.commit('SET_EXPORT', this.expert);
    },
    onInputDeadlineSlippage(slippage) {
      if (this.slippage >= 50) {
        this.slippage = slippage;
        return;
      }
      this.slippage = slippage;
      localStorage.setItem('slippage', slippage);
      this.$store.commit('SET_SLIPPAGE', slippage);
    },

  },
};
</script>

<style lang="less" scoped>
.settingsModal {
  width: 350px;
  .van-dialog {
    height: 500px;
    overflow: auto;
  }
  .van-dialog__header {
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #e9e9e9;
    font-size: 20px;

    .cancel {
      width: 20px;
      height: 20px;
      background-image: url('~@/assets/img/cancel.png');
      background-size: contain;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }
  .van-content {
    padding: 20px;
  }
  .van-content-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #0c0047;
  }
  .global {
    font-size: 16px;
    color: #f39714;
    margin-bottom: 10px;
  }
  .van-content-top-tab {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    .gasPrice {
      margin-right: 15px;
      width: 94px;
      font-size: 16px;
      text-align: center;
      height: 32px;
      line-height: 32px;
      color: #333333;
      border-radius: 14px;
      cursor: pointer;
    }
  }
  .active {
    background-color: #12cdb7;
    color: #ffffff;
  }
  //bottom
  .van-content-bottom {
    margin-top: 30px;

    .van-content-bottom-title {
      font-weight: bold;
      font-size: 14px;
    }
  }
  .van-content-bottom-tab {
    display: flex;
    align-items: center;
    margin-top: 20px;
    .cur {
      margin-right: 15px;
      width: 64px;
      font-size: 12px;
      text-align: center;
      height: 32px;
      line-height: 32px;
      border-radius: 14px;
      color: #333333;
      cursor: pointer;
    }
    .uint {
      color: #12cdb7;
    }
    .inputDiv1 {
      display: flex;
      color: #12cdb7;
      width: 84px;
      align-items: center;
      .slipInput {
        font-size: 12px;
        border-radius: 4px;
        height: 32px;
        line-height: 32px;
        background-color: #e9e9e9;
      }

      .uint {
        color: #f39714;
        margin-left: 4px;
      }
    }
  }
  //select
  .van-content-bottom-select {
    margin-top: 30px;
    .select-list {
      display: flex;
      font-size: 12px;
      margin-top: 10px;
      align-items: center;
      justify-content: space-between;
      .label {
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        color: #0c0047;
      }
      .deadInput {
        width: 64px;
        height: 25px;
        border-radius: 4px;
        background-color: #e9e9e9;
        border: 0px;
        padding-left: 10px;
        color: #12cdb7;
      }
      .van-cell {
        padding: 0;
      }
      .van-cell::after {
        content: none;
      }
    }
  }
  .tip1 {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
    color: #cc9300;
  }
}
</style>
