import request from '../utils/request';

export default {
  //行情
  tokenQuote(address) {
    return request.request({
      url: '/token/quote',
      method: "get",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
        'Authorization': address
      },
    })
  },

  //提币配置
  tokenWithdrawConfigs(address) {
    return request.request({
      url: '/token/withdraw-configs',
      method: "get",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
        'Authorization': address
      },
    })
  },

  //充币配置
  tokenRechargeConfigs(address) {
    return request.request({
      url: '/token/recharge-configs',
      method: "get",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
        'Authorization': address
      },
    })
  },

  //获取vrt今日价格
  tokenVrtPrice(address){
    return request.request({
      url: '/token/vrt-price',
      method: "get",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
        'Authorization': address
      },
    })
  }
}