import Vue from 'vue';
import VueRouter from 'vue-router';


import Home from '../views/Home.vue';

import Index from '../views/index/index.vue'; //首页
import invite from '../views/index/invite.vue'; //邀请
import whitePaper from '../views/index/whitePaper.vue'; //白皮书
import turntable from '../views/index/turntable.vue'; //转盘
import turntableRecord from '../views/index/turntableRecord.vue'; //转盘记录
import notice from '../views/notice/index.vue'; //公告
import noticeDetail from '../views/notice/detail.vue'; //公告详情
import miner from '../views/miner/index.vue'; //矿机
import minerRecord from '../views/miner/minerRecord.vue'; //账单
import FlashExchange from '../views/flashExchange/index.vue';  //闪兑
import FlashExchangeBill from '../views/flashExchange/bill.vue';  //闪兑账单


import orders from '../views/orders/index.vue';  //订单买卖
import ordersRecord from '../views/orders/record.vue';  //订单买卖记录
import ordersMerchandise from '../views/orders/merchandise.vue'; //订单购买出售
import ordersRelease from '../views/orders/release.vue';  //订单需求发布
import recharge from '../views/orders/recharge.vue';  //充值提现
import ordersBill from '../views/orders/bill.vue';  //账单列表
import ordersMeans from '../views/orders/means.vue';  //资产列表
import airdrop from '../views/orders/airdrop.vue'; //空投


import blindBox from '../views/blind/index.vue'

import SwapIndex from '../views/swap/Index.vue';

import Swap from '../views/swap/Swap.vue';
import LiquidityIndex from '../views/liquidity/Index.vue';
import Liquidity from '../views/liquidity/Liquidity.vue';
import importMining from '../views/liquidity/importMining.vue';
// import Remove from '../views/liquidity/remove.vue';
import pendingOrder from '../views/pendingOrder/index.vue';
import feedBack from '../views/feedback/index';
import feedBackDetail from '../views/feedback/feedBackDetail.vue'
import editFeedback from '../views/feedback/editFeedback'
// import boardOfDirectors from '../views/boardOfDirectors/Index.vue'

import c2c from '../views/c2c/index'
import c2cRecord from '../views/c2c/record'
import quotation from '../views/quotation/index.vue'
Vue.use(VueRouter);
const routes = [

  {
    path: '/',
    redirect: 'Index',
    component: Home,
    children: [
      {
        path: 'Index',
        name: 'Index',
        component: Index,
      },
      {
        path: 'invite',
        name: 'invite',
        component: invite,
      },
      {
        path: 'whitePaper',
        name: 'whitePaper',
        component: whitePaper,
      },
      {
        path: 'notice',
        name: 'notice',
        component: notice,
      },
      {
        path: 'noticeDetail',
        name: 'noticeDetail',
        component: noticeDetail,
      },
      {
        path: 'feedBack',
        name: 'feedBack',
        component: feedBack,
      },
      {
        path: 'feedBackDetail',
        name: 'feedBackDetail',
        component: feedBackDetail,
      },
      {
        path: 'editFeedback',
        name: 'editFeedback',
        component: editFeedback,
      },
      {
        path: 'quotation',
        name: 'quotation',
        component: quotation,
      },

      {
        path: 'SwapIndex',
        name: 'SwapIndex',
        component: SwapIndex,
        children: [
          {
            path: 'Swap',
            name: 'Swap',
            component: Swap,
          },
          {
            path: 'LiquidityIndex',
            name: 'LiquidityIndex',
            component: LiquidityIndex,
          },
          {
            path: 'pendingOrder',
            name: 'pendingOrder',
            component: pendingOrder,
          },
          {
            path: 'Liquidity',
            name: 'Liquidity',
            component: Liquidity,
          },
          {
            path: 'importMining',
            name: 'importMining',
            component: importMining,
          }
        ],
      },
      {
        path: 'miner',
        name: 'miner',
        component: miner,
      },
      {
        path: 'minerRecord',
        name: 'minerRecord',
        component: minerRecord,
      },
      {
        path: 'airdrop',
        name: 'airdrop',
        component: airdrop,
      },
      {
        path: 'c2c',
        name: 'c2c',
        component: c2c,
      },
      {
        path: 'c2cRecord',
        name: 'c2cRecord',
        component: c2cRecord,
      },


      // {
      //   path: 'Blank',
      //   name: 'Blank',
      //   component: Blank,
      // },
      {
        path: 'FlashExchange',
        name: 'FlashExchange',
        component: FlashExchange,
      },
      {
        path: 'FlashExchangeBill',
        name: 'FlashExchangeBill',
        component: FlashExchangeBill,
      },

      {
        path: 'orders',
        name: 'orders',
        component: orders,
      },
      {
        path: 'blindBox',
        name: 'blindBox',
        component: blindBox,
      },
      {
        path: 'ordersRecord',
        name: 'ordersRecord',
        component: ordersRecord,
      },
      {
        path: 'ordersMerchandise',
        name: 'ordersMerchandise',
        component: ordersMerchandise,
      },
      {
        path: 'ordersRelease',
        name: 'ordersRelease',
        component: ordersRelease,
      },
      {
        path: 'recharge',
        name: 'recharge',
        component: recharge,
      },

      {
        path: 'ordersBill',
        name: 'ordersBill',
        component: ordersBill,
      },
      {
        path: 'ordersMeans',
        name: 'ordersMeans',
        component: ordersMeans,
      },
      {
        path: 'turntable',
        name: 'turntable',
        component: turntable,
      },      
      {
        path: 'turntableRecord',
        name: 'turntableRecord',
        component: turntableRecord,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'hash',
  // mode: 'history',
  // base: './',
  routes,
});

export default router;
