<template>
  <div class="home_page">
    <div class="team">
      <span class="left">{{$t('lang.swap444')}}：{{this.communityInfo[0].name || $t('lang.swap445')}}</span>
      <span
        class="right"
        v-if="this.communityInfo[0].id != this.communityInfo[1].id"
      >{{$t('lang.swap446')}}：{{ this.communityInfo[1].name}}</span>
    </div>
    <div class="recommend">
      <div class="my">
        <div class="top">
          <span class="my_title">{{$t('lang.swap447')}}:</span>
          <div class="invite-open" @click="inviteOpen" v-if="parent_id == 0">
            <span>{{$t('lang.swap448')}}</span>
          </div>
          <div class="invite-open-two" v-else>
            <span>{{$t('lang.swap448')}}</span>
          </div>
        </div>

        <span>{{$t('lang.swap449')}}</span>
      </div>
      <div class="my">
        <span class="my_title">{{$t('lang.swap218')}}:</span>
        <span>{{recommender ? recommender.address.substring(0, 7) + '*****' + recommender.address.substring(recommender.address.length - 7, recommender.address.length) :$t('lang.swap373')}}</span>
      </div>
      <div class="my">
        <span class="my_title">{{$t('lang.swap450')}}:</span>
        <span class="recommend_link" v-if="parent_id != 0">{{invite_link}}</span>
        <span class="recommend_link" v-else>{{$t('lang.swap373')}}</span>
        <van-button
          type="primary"
          class="swapBtn copys"
          @click="copy"
          :data-clipboard-text="invite_link"
          v-if="parent_id != 0"
        >{{$t('lang.swap451')}}</van-button>
      </div>
    </div>

    <div class="detailed">
      <div class="detailed_li">
        <span class="detailed_title">{{$t('lang.swap452')}}</span>
        <span>{{childrenNum || 0}}</span>
      </div>
      <div class="detailed_li">
        <span class="detailed_title">{{$t('lang.swap453')}}</span>
        <span>{{teamnumber || 0}}</span>
      </div>
      <div class="detailed_li">
        <span class="detailed_title">{{$t('lang.swap445')}}</span>
        <span>{{holdNum || 0}}</span>
      </div>
      <!-- <div class="detailed_li">
        <span class="detailed_title">市场业绩</span>
        <span>{{marketNum || 0}}</span>
      </div>-->
    </div>
    <div>
      <div>
        <span class="first-title">{{$t('lang.swap454')}}</span>
      </div>

      <div class="list" v-if="myTeamInfo">
        <div class="li">
          <div class="li_detailed">
            <div class="detailed_li">
              <span class="detailed_title">{{$t('lang.swap334')}}</span>
              <span>{{myTeamInfo.address}}</span>
            </div>
            <div class="detailed_li">
              <span class="detailed_title">{{$t('lang.swap455')}}</span>
              <span>{{myTeamInfo.name || $t('lang.swap445')}}</span>
            </div>
            <div class="detailed_li">
              <span class="detailed_title">{{$t('lang.swap456')}}</span>
              <span>{{myTeamInfo.total_achievement}}U</span>
            </div>
          </div>
          <div class="li_detailed_bottom">
            <div class="detailed_li">
              <span class="detailed_title">{{$t('lang.swap494')}}U</span>
              <span>{{myTeamInfo.self_u_achievement}}</span>
            </div>
            <div class="detailed_li">
              <span class="detailed_title">{{$t('lang.swap494')}}V</span>
              <span>{{myTeamInfo.self_vrt_achievement}}</span>
            </div>
            <div class="detailed_li">
              <span class="detailed_title">{{$t('lang.swap462')}}U</span>
              <span>{{myTeamInfo.team_u_achievement}}</span>
            </div>
            <div class="detailed_li">
              <span class="detailed_title">{{$t('lang.swap462')}}V</span>
              <span>{{myTeamInfo.team_vrt_achievement}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="my_team">
      <span class="first-title">{{$t('lang.swap459')}}</span>
      <van-field
        type="text"
        v-model="search_address"
        :placeholder="$t('lang.swap460')"
        class="van-field"
        input-align="left"
        @input="search"
      ></van-field>
    </div>

    <div class="list">
      <div>
        <div v-if="childrenList.length == 0" class="bare">
          <img src="@/assets/img/barea.png" class="bare_img" />
          <span>{{$t('lang.swap461')}}</span>
        </div>
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text
          @load="inviteChildList"
          :immediate-check="false"
        >
          <div v-for="(item,index) of childrenList" :key="index" class="li">
            <div class="li_detailed">
              <div class="detailed_li">
                <span class="detailed_title">{{$t('lang.swap334')}}</span>
                <span>{{item.address}}</span>
              </div>
              <div class="detailed_li">
                <span class="detailed_title">{{$t('lang.swap455')}}</span>
                <span>{{item.team_level_name ||$t('lang.swap445')}}</span>
              </div>
              <div class="detailed_li">
                <span class="detailed_title">{{$t('lang.swap462')}}{{$t('lang.swap457')}}(USDT)</span>
                <span>{{item.team_u_achievement}}</span>
              </div>
            </div>
            <div class="li_detailed_bottom">
              <div class="detailed_li">
                <span class="detailed_title">{{$t('lang.swap494')}}U</span>
                <span>{{item.self_u_achievement}}</span>
              </div>
              <div class="detailed_li">
                <span class="detailed_title">{{$t('lang.swap494')}}V</span>
                <span>{{item.self_vrt_achievement}}</span>
              </div>
              <div class="detailed_li">
                <span class="detailed_title">{{$t('lang.swap462')}}U</span>
                <span>{{item.team_u_achievement}}</span>
              </div>
              <div class="detailed_li">
                <span class="detailed_title">{{$t('lang.swap462')}}V</span>
                <span>{{item.team_vrt_achievement}}</span>
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </div>
    <van-popup v-model="inviteShow" round>
      <div class="popup">
        <div class="top">
          <span>{{$t('lang.swap448')}}</span>
        </div>
        <div class="center">
          <van-field
            v-model="invite_address"
            :placeholder="$t('lang.swap463')"
            class="van-field"
            input-align="center"
          ></van-field>
        </div>
        <div class="bottom" @click="inviteMyShare">
          <span>{{$t('lang.swap448')}}</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Clipboard from 'clipboard';
import { inviteApi, usApi, newsLetterApi, cardApi } from '@/api/index';
import { mapState } from 'vuex';
import Web3 from 'web3';
export default {
  data() {
    return {
      inviteList: [], //邀请列表
      recommender: {},
      userinfo: [], //我的信息
      domain: '', //域名
      toInput: '',
      parent_id: 0,
      children_num: 0, //直推人数
      team_num: 0, //直推人数
      mining_num: 0, //直推人数
      invite_link: '',
      childrenList: [],
      page: 1,
      pageSize: 10, //页数
      totalPage: 0, //总页数
      loading: false,
      finished: false,
      communityInfo: [],
      childrenNum: 0,
      teamnumber: 0,
      holdNum: 0,
      marketNum: 0,
      myTeamInfo: undefined,
      inviteShow: false,
      invite_address: undefined,
      search_address: undefined,
      isRun: false,
    };
  },
  methods: {
    init() {
      if (this.account) {
        this.inviteInfo();
        this.getUserInfo();
        this.basicConfig();
        this.inviteInfo();
        this.inviteChildList();
        this.getCommunity();
        this.getInviteInfo();
        this.getCardTeamInfo();
      }
    },
    watchLanguage() {
      let _this = this;
      window.addEventListener('setItemEvent', function (e) {
        if (e.key === 'lang') {
          setTimeout(() => {
            _this.communityInfo = [];
            _this.init();
          }, 1000);
        }
      });
    },
    inviteOpen() {
      this.inviteShow = true;
    },
    inviteMyShare() {
      if (this.invite_address == undefined) {
        this.$toast(this.$t('lang.swap464'));
        return;
      } else {
        let web3 = new Web3(window.ethereum);
        web3.eth.personal.sign(web3.utils.fromUtf8('Bind Recommender'), this.account, (err, res) => {
          let data = {
            signature: res,
            parent_addr: this.invite_address,
          };
          usApi.usBind(data, this.account).then(res => {
            if (res.code == 200) {
              this.$toast(this.$t('lang.swap319'));
              this.inviteShow = false;
              this.init();
            } else {
              this.$toast(res.msg);
            }
          });
        });
      }
    },
    getCommunity() {
      cardApi.cardCommunityInfo(this.account).then(res => {
        this.communityInfo = res.data.community_info;
      });
    },
    getInviteInfo() {
      usApi.usInviteInfo(this.account).then(res => {
        this.childrenNum = res.data.children_num;
        this.teamnumber = res.data.team_num;
        this.holdNum = res.data.hold_num;
        this.marketNum = res.data.market_num;
      });
    },
    getCardTeamInfo() {
      cardApi.cardTeamInfo(this.account).then(res => {
        this.myTeamInfo = res.data;
      });
    },
    //邀请列表
    inviteInfo() {
      usApi.usInviteInfo(this.account).then(res => {
        if (res.code == 200) {
          // this.inviteList = res.data.children;
          this.recommender = res.data.recommender;
          this.children_num = res.data.children_num;
          this.team_num = res.data.team_num;
          this.mining_num = res.data.mining_num;
        } else {
          this.$toast(res.msg);
        }
        console.log(res);
      });
    },
    inviteChildList() {
      let data = {
        page: this.page,
        page_size: this.pageSize,
        address: this.search_address,
      };
      usApi.usInviteChildList(data, this.account).then(res => {
        if (res.code == 200) {
          this.childrenList = this.childrenList.concat(res.data.list);
          this.totalPage = Math.ceil(res.data.total_count / this.pageSize);
          this.page++;
          this.loading = false;
          if (this.page > this.totalPage) return (this.finished = true);
        } else {
          this.$toast(res.msg);
        }
      });
    },
    search() {
      if (this.isRun) {
        return;
      }
      this.isRun = true;
      setTimeout(() => {
        this.isRun = false;
        this.page = 1;
        this.childrenList = [];
        this.loading = false;
        this.finished = false;
      }, 2000);
    },
    //我的信息
    getUserInfo() {
      usApi.ucInfo(this.account).then(res => {
        if (res.code == 200) {
          this.userinfo = res.data;
          this.parent_id = res.data.parent_id;
          if (res.data.parent_id != 0) {
            this.invite_link = this.domain + '/#/Index?invite_code=' + res.data.invite_code;
          }
        } else {
          this.$toast(res.msg);
        }
      });
    },
    //域名
    basicConfig() {
      newsLetterApi.basicConfig().then(res => {
        if (res.code == 200) {
          this.domain = res.data.domain;
          this.getUserInfo();
        } else {
          this.$toast(res.msg);
        }
      });
    },
    copy() {
      if (this.invite_link) {
        var clipboard = new Clipboard('.copys');
        clipboard.on('success', e => {
          this.$toast(this.$t('lang.swap222'));
          clipboard.destroy(); // 释放内存
        });
        clipboard.on('error', e => {
          this.$toast(this.$t('lang.swap223'));
          clipboard.destroy(); // 释放内存
        });
      }
    },
  },
  created() {
    this.init();
    this.watchLanguage();
  },
  computed: {
    ...mapState(['account']),
    invited() {
      if (this.account) {
        return this.domain + this.userinfo.invite_code;
      } else {
        return '';
      }
    },
  },
  watch: {
    account() {
      this.init();
    },
  },
};
</script>


<style lang="less" scoped>
.home_page {
  position: relative;
  min-height: 800px;
  background: url('~@/assets/img/index_bg.png');
  background-size: 100% 100%;
  padding: 20px;
}
.popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000;
  padding: 10px 30px;
  width: 300px;
  .top {
    font-size: 16px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #000000;
    padding: 20px;
  }
  .center {
    background: #f3f3f3;
    border-radius: 2px 2px 2px 2px;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    /deep/ .van-field {
      background: #f3f3f3;
    }
  }

  .bottom {
    margin-top: 20px;
    background: #ffc93e;
    border-radius: 2px 2px 2px 2px;
    padding: 10px 70px;
    span {
      font-size: 16px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #ffffff;
    }
  }
}
.team {
  display: flex;
  flex-direction: column;
  .left {
    font-size: 16px;
    font-family: Roboto-Medium, Roboto;
    font-weight: 500;
    color: #ffc93e;
  }
  .right {
    margin-top: 5px;
    font-size: 12px;
    font-family: Roboto-Medium, Roboto;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.6);
  }
}
.recommend {
  color: #fff;
  background: #232b3e;
  border-radius: 4px;
  padding: 10px;
  margin-top: 20px;
  .recommend_top {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    .top_img {
      width: 30px;
      height: 30px;
      display: block;
      margin-right: 10px;
    }
    .top_title {
      display: flex;
      flex-direction: column;
      .hint {
        font-size: 12px;
        color: #ffc93e;
        margin-top: 4px;
      }
    }
  }
  .my {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    margin-top: 20px;
    .top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 5px;
      .invite-open {
        border-radius: 2px 2px 2px 2px;
        opacity: 1;
        border: 1px solid #18d7fc;
        padding: 5px;
        span {
          font-size: 12px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #18d7fc;
        }
      }
      .invite-open-two {
        border-radius: 2px 2px 2px 2px;
        opacity: 1;
        border: 1px solid #8e8e92;
        padding: 5px;
        span {
          font-size: 12px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #8e8e92;
        }
      }
    }
    .my_title {
      color: #999;
      margin-bottom: 6px;
    }
    .recommend_link {
      background: #474e5f;
      text-align: center;
      padding: 6px 10px;
      margin: 10px 0;
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      white-space: nowrap; //溢出不换行
    }
    .swapBtn {
      // width: 100%;
      height: auto;
      background: linear-gradient(180deg, #1ad9f3 0%, #2a88cc 100%);
      border: 0;
      border-radius: 4px;
      padding: 8px 0;
    }
  }
}
.detailed {
  display: flex;
  justify-content: space-between;
  color: #fff;
  background: #232b3e;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  margin: 20px 0;
  .detailed_li {
    .detailed_title {
      color: #999;
      margin-bottom: 6px;
    }
    max-width: 20%;
    display: flex;
    flex-direction: column;
    span {
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      white-space: nowrap; //溢出不换行
    }
  }
}

.first-title {
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #ffffff;
  // margin-top: 10px;
  // line-height: 19px;
}
.list {
  color: #fff;
  // background: #232b3e;
  border-radius: 4px;
  // padding: 10px;
  margin-bottom: 10px;
  .list_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    white-space: nowrap; //溢出不换行
    .van-field {
      background: #333333;
      border-radius: 20px;
      margin-left: 10px;
      padding: 0 10px !important;
      /deep/ .van-field__control {
        font-size: 12px;
        color: #fff;
      }
      /deep/ .van-cell__value--alone {
        color: #fff;
      }
    }
  }
  .li {
    font-size: 14px;
    margin-top: 10px;
    .li_detailed {
      display: flex;
      justify-content: space-between;
      color: #fff;
      background: #232b3e;
      border-radius: 4px;
      padding: 10px;
      font-size: 14px;
      // margin: 10px 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.16);
      .detailed_li {
        .detailed_title {
          color: #999;
          margin-bottom: 6px;
        }
        max-width: 30%;
        display: flex;
        flex-direction: column;
        span {
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          white-space: nowrap; //溢出不换行
        }
      }
    }

    .li_detailed_bottom {
      display: flex;
      justify-content: space-between;
      color: #fff;
      background: #232b3e;
      border-radius: 4px;
      padding: 10px;
      font-size: 14px;
      // margin: 10px 0;
      .detailed_li {
        .detailed_title {
          color: #999;
          margin-bottom: 6px;
        }
        max-width: 20;
        display: flex;
        flex-direction: column;
        span {
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          white-space: nowrap; //溢出不换行
        }
      }
    }
  }
}
.bare {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  padding: 40px 0;
  .bare_img {
    width: 100px;
    height: 80px;
    margin-bottom: 8px;
  }
}

.my_team {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  .van-field {
    width: 70%;
    border-radius: 20px;
  }
  /deep/ .van-field {
    background: #232b3e;
  }

  /deep/ .van-field__control {
    padding: 5px 10px;
    color: #fff;
  }
}
</style>