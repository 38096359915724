<template>
  <div class="home_page">
    <div class="headWrap">
      <span class="jump">{{$t('lang.swap35')}}</span>
      <span @click="switchovers('FlashExchangeBill')" class="checked">{{$t('lang.swap139')}}</span>
    </div>
    <div class="flash_exchange">
      <div class="proportion"
           v-if="exchange_map != undefined && fromCur.token_name && toCur.token_name">
        <span>1{{ fromCur.token_name }} ≈ {{ exchangeRate.toFixed(5) }} {{ toCur.token_name }}</span>
        <span>1{{ toCur.token_name }} ≈ {{ (1 / exchangeRate).toFixed(5) }} {{ fromCur.token_name }}</span>
      </div>
      <div class="currency">
        <div class="box">
          <div v-if="fromCur.token_name" @click="handleGetCurrency()" class="detail">
            <!-- <img :src="fromCur.logo"
            class="currency_img" />-->
            <span>{{ fromCur.token_name }}</span>
            <img src="@/assets/img/rightArrows.png" class="arrows_img" />
          </div>
          <div v-else @click="handleGetCurrency()" class="detail">
            <span>{{$t('lang.swap24')}}</span>
            <img src="@/assets/img/rightArrows.png" class="arrows_img" />
          </div>
        </div>

        <img  src="@/assets/img/group.png" class="exchanges_img" />
        <div class="box">
          <div v-if="toCur.token_name" @click="handleGetRightCurrency()" class="detail">
            <!-- <img :src="toCur.logo"
            class="currency_img" />-->
            <span>{{ toCur.token_name }}</span>
            <img src="@/assets/img/rightArrows.png" class="arrows_img" />
          </div>
          <div v-else @click="handleGetRightCurrency()" class="detail">
            <span>{{$t('lang.swap24')}}</span>
            <img src="@/assets/img/rightArrows.png" class="arrows_img" />
          </div>
        </div>
      </div>
      <div class="field">
        <van-field type="number" v-model="quantity" :placeholder="$t('lang.swap328')" class="van-field" />
        <van-field type="number" v-model="receiver" :placeholder="$t('lang.swap327')" class="van-field" readonly />
      </div>
      <!-- <div>
      <span>{{$t('lang.swap326')}}：{{ quantity - ( quantity * (fee_rate * 1)) }}{{ fromCur.token_name }}</span>
      <span>{{$t('lang.swap327')}}：{{(quantity - quantity * (fee_rate * 1)) * exchangeRate}}{{ toCur.token_name }}</span>

      </div>-->
      <div class="detailed">
        <!-- <span>{{$t('lang.swap326')}}：{{ quantity - ( quantity * (fee_rate * 1)) }}{{ fromCur.token_name }}</span> -->
        <!-- <span>{{$t('lang.swap327')}}：{{(quantity - quantity * (fee_rate * 1)) * exchangeRate}}{{ toCur.token_name }}</span> -->
        <span>{{$t('lang.swap22')}}：{{ balance }}{{ fromCur.token_name }}</span>
        <span>{{$t('lang.swap82')}}：{{ quantity * (fee_rate * 1) }}{{ fromCur.token_name }}</span>
      </div>
      <div>
        <van-button
          type="primary"
          class="confirm"
          @click="exchange"
          loading-text="Waiting..."
          :loading="showWithDraw"
        >{{$t('lang.swap47')}}</van-button>
      </div>

      <van-popup v-if="tokenListShow" v-model="tokenListShow" round>
        <currency-list
          :list="list"
          @listenClose="handleTokenListClose"
          @listenSelecteCurrency="handlerSelecteCurrency"
        />
      </van-popup>
    </div>
    <van-popup v-model="currencyShow" position="bottom">
      <div class="currency_popup">
        <div
          v-for="(item,index) of list"
          :key="index"
          class="li"
          @click="handlerSelecteCurrency(item)"
        >
          <img :src="item.logo" class="li_logo" />
          <span>{{item.token_name}}</span>
        </div>
        <div>
          <van-button type="primary" class="cancel">{{$t('lang.swap48')}}</van-button>
        </div>
      </div>
    </van-popup>

    <van-popup v-model="currencyRgihtShow" position="bottom">
      <div class="currency_popup">
        <div v-for="(item, index) of Rightlist" :key="index" class="li" @click="handlerSelecteRightCurrency(item)">
          <img :src="item.logo" class="li_logo" />
          <span>{{ item.token_name }}</span>
        </div>
        <div>
          <van-button type="primary" class="cancel">{{$t('lang.swap48')}}</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import CurrencyList from './CurrencyList.vue';
import { exchangeApi, walletApi, newsLetterApi } from '../../api/index';
import { mapState } from 'vuex';
import Web3 from 'web3';
export default {
  data() {
    return {
      currencyShow: false, ////选择代币弹窗
      currencyRgihtShow: false, //右边选择代币弹框
      quantity: '', //兑换数量
      tokenListShow: false, //选择代币列表
      tokenSource: 'from',
      fromCur: {
        token_name: '',
        logo: '',
        token_id: '',
      },
      toCur: {
        token_name: '',
        logo: '',
        token_id: '',
      },
      list: [], //币种列表
      Rightlist: [],
      exchange_map: undefined, //兑换列表
      balance: 0,
      fee_rate: 0,
      walletList: 0,
      balance: 0,
      exchangeRate: 0,
      showWithDraw: false,
      t1_id: undefined,
      t2_id: undefined,
      imageDomain: '',
    };
  },
  methods: {
    init() {
      if (this.account) {
        this.basicConfig();
       
      }
    },
    //获取币种信息
    exchangeCondition() {
      exchangeApi.exchangeCondition(this.account).then(res => {
        if (res.code == 200) {
          this.list = res.data.token_list;
          for (let i of this.list) {
            i.logo = this.imageDomain + i.logo;
          }
          // this.fromCur = this.list[0];
          this.exchange_map = res.data.exchange_map;
          this.getWalletList();
        } else {
          this.$toast(res.msg);
        }
      });
    },
    getWalletList() {
      walletApi.getWalletList(this.account).then(res => {
        this.walletList = res.data;
        for (let i of this.walletList) {
          if (i.token_name == this.fromCur.token_name) {
            this.balance = i.usable;
          }
        }
      });
    },
    handleGetCurrency(tokenSource) {
      this.tokenSource = tokenSource;

      this.currencyShow = true;
    },
    handleGetRightCurrency() {
      this.currencyRgihtShow = true;
    },
    handleTokenListClose() {
      this.tokenListShow = false;
    },
    async handlerSelecteCurrency (currency) {
      this.currencyShow = false;
      this.fromCur = currency;
      this.Rightlist = []
      for (let i of this.exchange_map[this.fromCur.token_name]) {
        let obj = {
          token_name:'',
          logo:'',
          token_id:""
        }
        obj.token_name = i.t2_name
        obj.logo = this.imageDomain + i.t2_logo
        obj.token_id = i.t2_id
        this.Rightlist.push(obj)
      }
    },
    async handlerSelecteRightCurrency(currency) {
      this.currencyRgihtShow = false;
      this.toCur = currency;
    },
    exchange() {
      if (this.quantity > this.balance || this.quantity == 0) {
        this.$toast(this.$t('lang.swap140'));
        return;
      }
      if (this.fromCur.token_name == undefined || this.toCur.token_name == undefined) {
        this.$toast(this.$t('lang.swap141'));
        return;
      }
      this.showWithDraw = true;
      let web3 = new Web3(window.ethereum);
      web3.eth.personal.sign(web3.utils.fromUtf8('Exchange'), this.account, (err, res) => {
        let da = {
          t1_id: this.t1_id * 1,
          t2_id: this.t2_id * 1,
          amount: this.quantity * 1,
          signature: res,
        };
        this.showWithDraw = true;
        exchangeApi
          .exchangeDo(da, this.account)
          .then(res => {
            this.showWithDraw = false;
            if (res.code == 200) {
              this.$toast(this.$t('lang.swap142'));
              this.init();
            } else {
              this.$toast(res.msg);
            }
          })
          .catch(e => {
            this.showWithDraw = false;
          });
      });
    },
    //图片域名
    basicConfig() {
      newsLetterApi.basicConfig().then(res => {
        if (res.code == 200) {
          this.imageDomain = res.data.image_domain;
          this.exchangeCondition();
        } else {
          this.$toast(res.msg);
        }
      });
    },
    //跳转
    switchovers(route) {
      this.$router.push({ name: route });
    },
  },
  components: {
    CurrencyList,
  },
  created() {
    this.init();
  },
  watch: {
    account() {
      this.init();
    },
    fromCur() {
      if (this.fromCur.token_name != '' && this.toCur.token_name != '') {
        for (let i of this.exchange_map[this.fromCur.token_name]) {
          if (i.t2_name == this.toCur.token_name) {
            this.fee_rate = i.fee_rate;
            this.exchangeRate = i.exchange_rate;
            this.t1_id = i.t1_id;
            this.t2_id = i.t2_id;
          }
        }
      }
      for (let i of this.walletList) {
        if (i.token_name == this.fromCur.token_name) {
          this.balance = i.usable;
        }
      }
    },
    toCur() {
      if (this.fromCur.token_name != '' && this.toCur.token_name != '') {
        for (let i of this.exchange_map[this.fromCur.token_name]) {
          if (i.t2_name == this.toCur.token_name) {
            this.fee_rate = i.fee_rate;
            this.exchangeRate = i.exchange_rate;
            this.t1_id = i.t1_id;
            this.t2_id = i.t2_id;
          }
        }
      }
      for (let i of this.walletList) {
        if (i.token_name == this.fromCur.token_name) {
          this.balance = i.usable;
        }
      }
    },
  },
  computed: {
    ...mapState(['account', 'net']),
    receiver () {
      return ((this.quantity - this.quantity * (this.fee_rate * 1)) * this.exchangeRate * 1).toFixed(4)
    }
  },
};
</script>

<style lang="less" scoped>
.home_page {
  background: url('~@/assets/img/second_bg.png');
  background-size: 100% 100%;
  padding: 20px;
  min-height: 800px;
}
.headWrap {
  display: flex;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  // width: 100%;

  // position: absolute;
  // right: 0;
  // display: flex;
  // align-items: center;
  // justify-content: flex-end;
  // padding: 0 16px 0 30px;
  margin-top: 20px;
  .jump {
    display: block;
    font-size: 14px;
    color: #ffffff;
    background: #232b3e;
    border: 2px solid #232b3e;
    padding: 10px 20px;
    // border: 2px solid #232b3e;
  }
  .checked {
    // width: 70px;
    display: inline-block;
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;

    border: 2px solid #232b3e;
    // background: #000;

    text-align: center;
    padding: 10px;
  }
}
.flash_exchange {
  background: #232b3e;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1607843137254902);
  padding: 10px 10px;
  // margin-top: 20px;

  .currency {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
    .box {
      display: flex;
      justify-content: center;
      width: 44%;
      background: #474e5f;
      padding: 8px 0;
      .detail {
        display: flex;
        align-items: center;
        color: #fff;
        .currency_img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin-right: 4px;
        }
        .arrows_img {
          display: block;
          width: 5px;
          height: 10px;
          margin: 4px 0 0 20px;
        }
      }
    }
    .exchanges_img {
      width: 20px;
      height: 20px;
      // transform: rotate(90deg);
    }
  }
  .proportion {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #999;
    // border: 1px solid #aaaaaa;
    border-radius: 4px;
    padding: 8px 20px;
    margin: 20px 0;
  }
  .field {
    display: flex;
    justify-content: space-between;
  }
  .van-field {
    width: 46%;
    font-size: 14px !important;
    background: #101622;
    border-radius: 2px;
    padding: 4px 10px !important;

    /deep/ .van-field__control {
      color: rgba(255, 255, 255, 0.6) !important;
    }
  }
  .detailed {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: bold;
    color: #999;
    margin: 10px 0;
    span {
      margin-top: 8px;
    }
  }
  .confirm {
    width: 100%;
    height: 40px;
    border-radius: 6px;
    border: 0;
    background: #f6bb31;
    margin: 10px 0;
  }
}
.currency_popup {
  color: #000;
  padding: 10px 20px;
  .li {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);

    // background: rgba(0,0,0,0.06);
    padding: 14px 0 14px;
    .li_logo {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-right: 6px;
    }
  }
  .cancel {
    width: 100%;
    height: auto;
    color: #999;
    border-radius: 4px;
    background: #fffeff;
    border: 0;
    // padding: 10px 0;
    margin: 20px 0 10px;
  }
}
</style>