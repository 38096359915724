<template>
  <div class="home_page">
    <van-notice-bar left-icon="volume-o"
                    :text="newNotice.content"
                    color="#ffffff"
                    background="rgba(255,255,255,0.2)"
                    v-if="newNotice"
                    mode="link"
                    @click="toNotice" />
    <div class="information">
      <div>
        <div class="information_left">
          <span class="text_top">{{todayPrice}}</span>
          <span class="text_button">VRT{{ $t('lang.swap406') }}</span>
        </div>
        <div class="information_left">
          <span class="text_top-text">{{todayIncome}}</span>
          <span class="text_button green">VRT{{ $t('lang.swap407') }}</span>
        </div>
      </div>
      <div class="reward">
        <div class="information_right">
          <div class="planet">
            <span>{{starIncome}}</span>
          </div>
          <div class="receive"
               @click="getStarIncomeReveiver">
            <span>{{ $t('lang.swap408') }}</span>
          </div>
        </div>
        <div class="information_right">
          <div class="share">
            <span>{{shareIncome}}</span>
          </div>
          <div class="receive"
               @click="getShareIncomeReveiver">
            <span>{{ $t('lang.swap409') }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- <div @click="planetClick(0)"
         class="universal venus">
      <span>{{ $t('lang.swap410') }}</span>
    </div>
    <div @click="planetClick(4)"
         class="universal saturn">
      <span>{{ $t('lang.swap411') }}</span>
    </div>
    <div @click="planetClick(2)"
         class="universal mercury">
      <span>{{ $t('lang.swap412') }}</span>
    </div>
    <div @click="planetClick(1)"
         class="universal jupiter">
      <span>{{ $t('lang.swap413') }}</span>
    </div>
    <div @click="planetClick(3)"
         class="universal mars">
      <span>{{ $t('lang.swap414') }}</span>
    </div> -->
    <div class="star"
         v-if="starOne">
      <img src="@/assets/img/star.gif"
           class="galaxy_img" />
    </div>

    <div class="star-two"
         v-if="starTwo">
      <img src="@/assets/img/star.gif"
           class="galaxy_img" />
    </div>
    <van-popup v-model="popupShow">
      <Popup @close="closePopup"
             :obj="transferObj"></Popup>
    </van-popup>
    <audio ref="audio"
           src="@/assets/music/2.mp3"
           autoplay
           loop
           id="audio"></audio>
    <div class="music">
      <img @click="stopPlay"
           v-show="isPlay"
           src="@/assets/img/music.png"
           class="music_img" />
      <img @click="play"
           v-show="!isPlay"
           src="@/assets/img/musics.png"
           class="music_img" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { basicApi, cardApi, tokenApi, usApi } from '@/api/index';
import Popup from './popup.vue';
import Web3 from 'web3';
export default {
  data () {
    return {
      domain: '', //图片域名
      newNotice: undefined,
      popupShow: false, //星球弹窗
      todayPrice: 0, //今日价格
      todayIncome: 0, //今日收益
      starIncome: 0, //星球收益
      shareIncome: 0, //分享收益
      isReceiveStar: false, //是否可以领取静态收益
      isReceiveShare: false, //是否可以领取分享收益
      cardList: [],
      venusObj: undefined, //金星数据
      jupiterObj: undefined, //木星数据
      mercuryObj: undefined, // 水星数据
      marsObj: undefined, //火星数据
      saturnObj: undefined, //土星数据
      transferObj: undefined, //中转数据
      site: '',
      starOne: false,
      starTwo: false,
      isPlay: false, //音乐
    };
  },
  created () {
    this.init();
    this.site = this.$route.query.invite_code ? this.$route.query.invite_code : '';

  },
  methods: {
    init () {
      if (this.account) {
        this.getNewNotice();
        this.getTodayPrice();
        this.getTodayIncome();
        this.getStarIncome();
        this.getShareIncome();
        this.getReceiverStar();
        this.getReceiverShare();
        this.getCardList();
        this.ucInfo();

      }
    },
    //邀请信息
    ucInfo () {
      usApi.ucInfo(this.account).then(res => {
        console.log(res);
        if (res.code == 200) {
          if (this.site && res.data.parent_id == 0) {
            this.bind();
          }
          this.userinfo = res.data;
        }
      });
    },
    bind () {
      let data = { invite_code: this.site };
      usApi.usBind(data, this.account).then(res => {
        if (res.code === 200) {
        } else {
          this.$toast(res.msg);
        }
      });
    },
    getCardList () {
      cardApi.cardList(this.account).then(res => {
        this.cardList = res.data;
        // this.venusObj = this.cardList[4]
        // this.jupiterObj = this.cardList[3]
        // this.mercuryObj = this.cardList[2]
        // this.marsObj = this.cardList[1]
        // this.saturnObj = this.cardList[0]
      });
    },
    getTodayPrice () {
      tokenApi.tokenVrtPrice(this.account).then(res => {
        this.todayPrice = res.data.today_price;
      });
    },
    getTodayIncome () {
      cardApi.cardTodayIncome(this.account).then(res => {
        this.todayIncome = res.data.income;
      });
    },
    getStarIncome () {
      cardApi.cardSrAmount(this.account).then(res => {
        this.starIncome = res.data.release_amount;
      });
    },

    getShareIncome () {
      cardApi.cardRrAmount(this.account).then(res => {
        this.shareIncome = res.data.release_amount;
      });
    },

    //是否可领取静态收益
    getReceiverStar () {
      cardApi.cardReleased(this.account).then(res => {
        this.isReceiveStar = res.data.released;
        // console.log(this.isReceiveStar, '是否可领取静态收益');
      });
    },
    getStarIncomeReveiver () {

      if (this.starIncome == 0) {
        this.$toast(this.$t('lang.swap416'));
        return;
      } else {
        let web3 = new Web3(window.ethereum);
        web3.eth.personal.sign(web3.utils.fromUtf8('Card Obtain'), this.account, (err, res) => {
          let data = {
            signature: res,
          };
          cardApi.cardObtain(this.account, data).then(res => {
            if (res.code == 200) {
              this.starTwo = true;
              setTimeout(() => {
                this.starTwo = false;
              }, 1000);
              this.$toast(this.$t('lang.swap417'));
              this.init();
            } else {
              this.$toast(res.msg);
            }
          });
        });
      }
    },
    //是否可领取分享收益
    getReceiverShare () {
      cardApi.cardRRReleased(this.account).then(res => {
        this.isReceiveShare = res.data.released;
        // console.log(this.isReceiveShare, '是否可领取分享收益');
      });
    },
    getShareIncomeReveiver () {
      if (this.shareIncome == 0) {
        this.$toast(this.$t('lang.swap416'));
        return;
      } else {
        let web3 = new Web3(window.ethereum);
        web3.eth.personal.sign(web3.utils.fromUtf8('Card Recommender Reward Obtain'), this.account, (err, res) => {
          let data = {
            signature: res,
          };
          cardApi.cardRRobtain(this.account, data).then(res => {
            if (res.code == 200) {
              this.starOne = true;
              setTimeout(() => {
                this.starOne = false;
              }, 1000);
              this.$toast(this.$t('lang.swap417'));
              this.init();
            } else {
              this.$toast(res.msg);
            }
          });
        });
      }
    },
    closePopup () {
      this.popupShow = false;
    },
    planetClick (index) {
      this.transferObj = this.cardList[index];
      this.popupShow = true;
    },
    toNotice () {
      this.$router.push({ name: 'notice' });
    },
    //获取最新公告
    getNewNotice () {
      basicApi.basicNoticeLatest(this.account).then(res => {
        this.newNotice = res.data;
      });
    },
    //行情
    tokenQuote () {
      tokenApi.tokenQuote(this.account).then(res => {
        if (res.code == 200) {
          this.tokenQuoteList = res.data;
        } else {
          this.$toast(res.msg);
        }
      });
    },
    toWeb () {
      this.$router.push({ name: 'miner' });
    },
    toWallet () {
      this.$router.push({ name: 'orders' });
    },
    //邀请信息
    ucInfo () {
      usApi.ucInfo(this.account).then(res => {
        console.log(res);
        if (res.code == 200) {
          if (this.site && res.data.parent_id == 0) {
            this.bind();
          }
          this.userinfo = res.data;
        }
      });
    },
    bind () {
      let data = { invite_code: this.site };
      usApi.usBind(data, this.account).then(res => {
        if (res.code === 200) {
        } else {
          this.$toast(res.msg);
        }
      });
    },
    // 邀请列表
    usInviteInfo () {
      usApi.usInviteInfo(this.account).then(res => {
        if (res.code == 200) {
          this.invitationList = res.data.children;
          this.recommender = res.data.recommender;
        }
      });
    },
    basicConfig () {
      newsLetterApi.basicConfig().then(res => {
        if (res.code == 200) {
          this.domain = res.data.domain;
          this.imageDomain = res.data.image_domain;
          // this.basicBanner();
          // this.tokenQuote();
          // this.getNewNotice()
        } else {
          this.$toast(res.msg);
        }
      });
    },
    // 复制
    copy () {
      if (this.invited) {
        var clipboard = new Clipboard('.copys');
        clipboard.on('success', e => {
          this.$toast(this.$t('lang.swap222'));
          clipboard.destroy(); // 释放内存
        });
        clipboard.on('error', e => {
          this.$toast(this.$t('lang.swap223'));
          clipboard.destroy(); // 释放内存
        });
      }
    },
    //跳转
    switchovers (route) {
      if (route == '') return this.$toast(this.$t('lang.swap109'));
      this.$router.push({ name: route });
      // this.$Router.pushPage({
      //   name: route
      // })
    },
    jump () {
      // console.log('456');
      this.$toast(this.$t('lang.swap109'));
    },
    play () {
      let audio = document.querySelector('#audio');
      audio.play();
      this.isPlay = true
    },
    stopPlay () {
      let audio = document.querySelector('#audio');
      audio.pause();
      audio.currentTime = 0;
      this.isPlay = false
    },
  },
  computed: {
    ...mapState(['account']),
    invited () {
      if (this.account) {
        return this.domain + '/#/Index?invite_code=' + this.userinfo.invite_code;
      } else {
        return '';
      }
    },
  },
  mounted () {

  },
  watch: {
    account () {
      this.init();
    },
  },
  components: {
    Popup,
  },
};
</script>

<style lang="less" scoped>
.home_page {
  position: relative;
  height: 800px;
  background: url('~@/assets/img/indexs_bg.gif');
  background-size: 100% 100%;
  padding: 20px;
  .information {
    display: flex;
    justify-content: space-between;
    .information_left {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      .text_top {
        margin-top: 10px;
        font-size: 35px;
        color: #a0feff;
      }

      .text_top-text {
        font-size: 24px;
        color: #2de1a0;
      }
      .text_button {
        font-size: 14px;
        color: #a0feff;
      }
      .text-button-two {
        font-size: 16px;
        color: #2de1a0;
      }
      .green {
        color: #2de1a0;
      }
      .blue {
      }
    }
    .reward {
      display: flex;
      flex-direction: row;
    }
    .information_right {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      margin-left: 20px;
      .planet {
        width: 80px;
        height: 80px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: url('~@/assets/img/planet_bg.png');
        background-size: 100% 100%;
      }
      .share {
        width: 90px;
        height: 80px;
        font-size: 12px;
        display: flex;
        // align-items: center;
        justify-content: center;
        background: url('~@/assets/img/share_bg.png');
        background-size: 100% 100%;
        span {
          margin-top: 32px;
        }
      }
      .receive {
        width: 84px;
        height: 30px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: url('~@/assets/img/receive_bg.png');
        background-size: 100% 100%;
        margin-top: 6px;
        span {
          font-size: 10px;
        }
      }
    }
  }
  .universal {
    width: 100px;
    height: 60px;
    position: absolute;

    font-size: 12px;
    background: url('~@/assets/img/text_bg.png');
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .venus {
    left: 126px;
    bottom: 420px;
    span {
      color: #eddd98;
    }
  }
  .saturn {
    right: 20px;
    bottom: 480px;
    span {
      color: #f1dcf7;
    }
  }
  .mercury {
    right: 30px;
    bottom: 260px;
    span {
      color: #06f0ff;
    }
  }
  .jupiter {
    left: 10px;
    bottom: 360px;
    span {
      color: #ea9521;
    }
  }
  .mars {
    left: 70px;
    bottom: 150px;
    span {
      color: #ff9488;
    }
  }
  .star {
    position: absolute;
    // background: red;
    top: 140px;
    right: 20px;

    // z-index: 999;
    .galaxy_img {
      // bottom: 500px;
      width: 300px;
      height: 100px;
    }
  }

  .star-two {
    position: absolute;
    // background: red;
    top: 140px;
    right: 100px;

    // z-index: 999;
    .galaxy_img {
      // bottom: 500px;
      width: 300px;
      height: 100px;
    }
  }
  .music {
    position: fixed;
    right: 0;
    bottom: 60px;
    .music_img {
      width: 100px;
      height: 100px;
    }
  }
}
/deep/ .van-popup {
  background: rgba(0, 0, 0, 0) !important;
}
</style>