import request from '../utils/request';


export default {
    //c2c条件
    condition(address) {
        return request.request({
            url: '/c2c/condition',
            method: "get",
            headers: {
                Authorization: address,
                'Lang': localStorage.getItem('lang') || '',
            },
        })
    },

    publish(data, address) {
        return request.request({
            url: '/c2c/publish',
            method: "post",
            headers: {
                Authorization: address,
                'Lang': localStorage.getItem('lang') || '',
            },
            data: data
        })
    },

    //最新成交价
    latestPrice(data,address){
        return request.request({
            url: '/c2c/latest-price',
            method: "get",
            headers: {
                Authorization: address,
                'Lang': localStorage.getItem('lang') || '',
            },
            params: data
        })
    },

    //用户挂单
    userTrades(data, address) {
        return request.request({
            url: '/c2c/user-trades',
            method: "get",
            headers: {
                Authorization: address,
                'Lang': localStorage.getItem('lang') || '',
            },
            params: data
        })
    },

    //市场挂单
    marketTrades(data, address) {
        return request.request({
            url: '/c2c/market-trades',
            method: "get",
            headers: {
                Authorization: address,
                'Lang': localStorage.getItem('lang') || '',
            },
            params: data
        })
    },
    //取消
    cancel(data, address) {
        return request.request({
            url: '/c2c/cancel',
            method: "post",
            headers: {
                Authorization: address,
                'Lang': localStorage.getItem('lang') || '',
            },
            data: data
        })
    },

    //c2c购买
    buy(data, address) {
        return request.request({
            url: '/c2c/buy',
            method: "post",
            headers: {
                Authorization: address,
                'Lang': localStorage.getItem('lang') || '',
            },
            data: data
        })
    },

    //c2c出售
    sell(data, address) {
        return request.request({
            url: '/c2c/sell',
            method: "post",
            headers: {
                Authorization: address,
                'Lang': localStorage.getItem('lang') || '',
            },
            data: data
        })
    },

    //市场挂单
    tradeRecords(data, address) {
        return request.request({
            url: '/c2c/trade-records',
            method: "get",
            headers: {
                Authorization: address,
                'Lang': localStorage.getItem('lang') || '',
            },
            params: data
        })
    },

    
}