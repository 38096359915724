<template>
  <div class="home_page">
    <div class="quotation">
      <div class="quotation-title">
        <span>{{ $t('lang.swap105') }}</span>
      </div>
      <div class="quotation-detail">
        <div class="detail-top">
          <div class="detail-detail">
            <span>{{ $t('lang.swap106') }}</span>
          </div>
          <div class="detail-detail">
            <span>{{ $t('lang.swap36') }}</span>
          </div>
          <div class="detail-right">
            <span>{{ $t('lang.swap107') }}</span>
          </div>
        </div>
        <div class="detail-currency"
             v-for="(item,index) in tokenQuoteList"
             :key="index">
          <div class="currency-left">
            <img :src="imageDomain + item.logo"
                 class="currency_img" />
            <span class="left-currency">{{item.token_name}}</span>
            <span class="left-usdt">/USDT</span>
          </div>
          <div class="currency-center">
            <span>{{ item.last.toFixed(2)}}</span>
          </div>
          <div class="currency-right">
            <div class="right-div">
              <span class="range">{{item.quote_change || '0.00%'}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { mapState } from 'vuex';
import { usApi, newsLetterApi, tokenApi, basicApi } from '@/api/index';
import Clipboard from 'clipboard';
import Swiper from '../../components/BaseSwiper.vue';
export default {
  data () {
    return {
      domain: '', //图片域名
      basicBannerList: '',
      tokenQuoteList: [], //行情
    };
  },
  created () {
    this.init();
  },
  methods: {
    init () {
      if (this.account) {
        this.basicConfig();
      }
    },
    //行情
    tokenQuote () {
      tokenApi.tokenQuote(this.account).then(res => {
        if (res.code == 200) {
          this.tokenQuoteList = res.data;
        } else {
          this.$toast(res.msg);
        }
      });
    },

    basicConfig () {
      newsLetterApi.basicConfig().then(res => {
        if (res.code == 200) {
          this.domain = res.data.domain;
          this.imageDomain = res.data.image_domain;
          this.tokenQuote();
        } else {
          this.$toast(res.msg);
        }
      });
    },
  },
  computed: {
    ...mapState(['account']),
  },
  watch: {
    account () {
      this.init();
    },
  },
  components: {
    Swiper,
  },
};
</script>
  
  <style lang="less" scoped>
.home_page {
  background: url('~@/assets/img/second_bg.png');
  background-size: 100% 100%;
  padding: 20px;
  min-height: 800px;

  .quotation {
    margin-top: 20px;
    background: #232b3e;
    border-radius: 4px 4px 4px 4px;
    // padding: 20px;
    .quotation-title {
      padding: 20px;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
    .quotation-explain {
      padding: 20px;
      span {
        font-size: 12px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.8);
        line-height: 14px;
        letter-spacing: 1px;
      }
    }
    .quotation-detail {
      padding: 20px 20px 0 20px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      .detail-top {
        display: flex;
        flex-direction: row;
        // justify-content: center;
        // align-items: center;
        width: 100%;

        .detail-detail {
          display: flex;
          // padding: 10px 0px;
          flex: 1;
          // justify-content: center;
          align-items: center;
          span {
            color: rgba(255, 255, 255, 0.6);
            font-size: 12px;
          }
          // width: 48%;
        }
        .detail-right {
          display: flex;
          // padding: 10px 0px;
          flex: 1;
          justify-content: flex-end;
          // align-items: flex-end;
          // width: 48%;
          span {
            color: rgba(255, 255, 255, 0.6);
            font-size: 12px;
          }
        }
      }
      .detail-currency {
        padding: 20px 0px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        display: flex;
        flex-direction: row;
        .currency-left {
          display: flex;
          flex: 1;
          justify-content: flex-start;
          align-items: center;
          .currency_img {
            width: 14px;
            height: 14px;
            margin-right: 5px;
          }
          .left-currency {
            font-size: 14px;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: bold;
            color: #ffffff;
            line-height: 16px;
          }
          .left-usdt {
            font-size: 10px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(255, 255, 255, 0.6);
            line-height: 12px;
          }
        }
        .currency-center {
          display: flex;
          // padding: 10px 0px;
          flex: 1;
          // justify-content: center;
          align-items: center;
          span {
            font-size: 14px;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: bold;
            color: #00b2ff;
          }
        }
        .currency-right {
          display: flex;
          // padding: 10px 0px;
          flex: 1;
          justify-content: flex-end;
          .right-div {
            background: #efbb34;
            border-radius: 2px 2px 2px 2px;
            padding: 5px 10px;
            .range {
              font-size: 14px;
              font-family: PingFang SC-Bold, PingFang SC;
              font-weight: bold;
              color: #ffffff;
              line-height: 16px;
            }
          }
        }
      }
    }
  }
}
</style>z