<template>
  <div class="release">
    <div class="closure">
      <img src="@/assets/img/closure.png" class="closure_img" @click="close" />
    </div>
    <div class="release_title">
      <span>{{popupIndex==0?$t('lang.swap388') :$t('lang.swap389')}}{{obj.t1_name}}</span>
      <span class="text">{{$t('lang.swap90')}}:{{obj.price}}</span>
    </div>
    <div>
      <van-field type="number" v-model="quantity" placeholder class="van-field">
        <template #button>
          <span class="field_buttons" @click="allIn">{{$t('lang.swap172')}}</span>
        </template>
      </van-field>
    </div>
    <div class="balance">
      <span>{{$t('lang.swap431')}}：{{obj.remain_amount}}</span>
    </div>
    <div class="balance">
      <span>{{$t('lang.swap432')}}： {{balance}}</span>
    </div>
    <div class="balance">
      <span>{{popupIndex==0?$t('lang.swap433') :$t('lang.swap515')}}:</span>
      <span class="balance_quantity">{{(this.quantity * this.obj.price).toFixed(4)}}</span>
      <span>{{obj.t2_name}}</span>
    </div>
    <div class="detail">
      <span>{{$t('lang.swap430',{buyFee:buy_fee_rate * 100,sellFee:sell_fee_rate * 100})}}</span>
    </div>
    <div>
      <van-button
        type="primary"
        class="defray_button"
        @click="sell"
      >{{popupIndex==0?$t('lang.swap388') :$t('lang.swap389')}}{{obj.t1_name}}</van-button>
    </div>
  </div>
</template>

<script>
import { walletApi, c2cApi } from '@/api/index';
import { mapState } from 'vuex';
import Web3 from 'web3';
export default {
  props: ['popupIndex', 'obj'],

  data() {
    return {
      quantity: 0, //数量
      balance: 0,
      buy_fee_rate: 0,
      sell_fee_rate: 0,
    };
  },
  created() {
    // this.getWalletInfo();
    this.init()
  },
  methods: {
    init(){
      if(this.account){
        this.getWalletList()
      }
    },
    //获取单个币种信息
    getWalletInfo() {
      let data = {
        token_id: this.popupIndex == 0 ? this.obj.t1_id : this.obj.t2_id,
      };
      walletApi.getWalletInfo(data, this.account).then(res => {
        if (res.code == 200) {
          this.balance = res.data.usable;
        } else {
          this.$toast(res.msg);
        }
      });
    },
    // 资产列表
    getWalletList() {
      walletApi.getWalletList(this.account).then(res => {
        this.walletList = res.data;
        for (let i of this.walletList) {
          if (i.token_name == 'VRT') {
            this.vrtObj = i;
          }
          if (i.token_name == 'USDT') {
            this.usdtObj = i;
          }
        }
        this.getCondition()
        this.getWalletInfo();
      });
    },
    getCondition() {
      c2cApi.condition(this.account).then(res => {
        this.list = res.data.token_list;
        this.c2cMap = res.data.trade_map;
        this.usdtList = res.data.trade_map['USDT'];
        for (let i of this.usdtList) {
          if ((i.t2_id == this.usdtObj.token_id && i.t1_id == this.vrtObj.token_id) || (i.t1_id == this.usdtObj.token_id && i.t2_id == this.vrtObj.token_id)) {
            this.sell_fee_rate = i.sell_fee_rate;
            this.buy_fee_rate = i.buy_fee_rate;
          }
        }
      });
    },
    allIn() {
      if (this.balance > this.obj.remain_amount) {
        this.quantity = this.obj.remain_amount;
      } else {
        this.quantity = this.balance;
      }
    },
    sell() {
      
      if (this.quantity == 0) {
        this.$toast(this.$t('lang.swap184'));
        return;
      } else if ((this.quantity * this.obj.price) > this.balance) {
        this.$toast(this.$t('lang.swap29'));
        return;
      } else {
        if (this.popupIndex == 0) {
          let web3 = new Web3(window.ethereum);
          web3.eth.personal.sign(web3.utils.fromUtf8('C2C Sell'), this.account, (err, res) => {
            let data = {
              signature: res,
              id: this.obj.id * 1,
              amount: this.quantity * 1,
            };
            c2cApi.sell(data, this.account).then(res => {
              if (res.code == 200) {
                this.$toast(this.$t('lang.swap203'));
                this.$emit('backtrack');
                this.quantity = 0;
              } else {
                this.$toast(res.msg);
              }
            });
          });
        } else {
          let web3 = new Web3(window.ethereum);
          web3.eth.personal.sign(web3.utils.fromUtf8('C2C Buy'), this.account, (err, res) => {
            let data = {
              signature: res,
              id: this.obj.id * 1,
              amount: this.quantity * 1,
            };
            c2cApi.buy(data, this.account).then(res => {
              if (res.code == 200) {
                this.$toast(this.$t('lang.swap203'));
                this.$emit('backtrack');
                this.quantity = 0;
              } else {
                this.$toast(res.msg);
              }
            });
          });
        }
      }
    },
    close() {
      this.$emit('backtrack');
    },
  },
  computed: {
    ...mapState(['account']),
  },
  watch: {
    obj() {
      this.balance = 0;
      this.getWalletInfo();
      this.quantity = 0
    },
    account(){
      this.init()
    }
  },
};
</script>

<style lang="less" scoped>
.release {
  color: #000;
  padding: 10px 20px 20px;
  .closure {
    display: flex;
    justify-content: flex-end;
    .closure_img {
      width: 20px;
      height: 20px;
      display: block;
    }
  }
  .release_title {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    .text {
      font-size: 12px;
      color: #999;
    }
  }
  .field_title {
    display: block;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    margin: 20px 0 10px;
  }
  .van-field {
    background: #f3f3f3 !important;
    border-radius: 4px;
    padding: 6px 10px !important;
    margin: 20px 0;
    .field_buttons {
      font-size: 12px;
      color: #999;
    }
  }
  .balance {
    font-size: 12px;
    color: #999;
    margin-top: 10px;
    .balance_quantity {
      font-size: 16px;
      color: #000;
    }
  }
  .defray_button {
    width: 100%;
    height: auto;
    border: 0;
    border-radius: 4px;
    background: #ffc83e;
    padding: 10px 0;
    margin-top: 20px;
  }
}
.detail {
  margin-top: 20px;
  font-size: 12px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(35, 176, 223, 0.6);
}
</style>