import request from '../utils/request';

export default {
    //支付条件
    payCondition(address) {
        return request.request({
            url: '/lucky-wheel/pay-condition',
            method: "get",
            headers: {
                'Lang': localStorage.getItem('lang'),
                Authorization: address
            }
        })
    },

    //抽奖
    luckDraw(data, address) {
        return request.request({
            url: '/lucky-wheel/luck-draw',
            method: "post",
            headers: {
                'Lang': localStorage.getItem('lang'),
                Authorization: address
            },
            data: data
        })
    },


    //奖品列表
    luckyList(address) {
        return request.request({
            url: '/lucky-wheel/list',
            method: "get",
            headers: {
                'Lang': localStorage.getItem('lang') || '',
                Authorization: address
            }
        })
    },

    //用户抽奖记录
    userRecords(address) {
        return request.request({
            url: '/lucky-wheel/user/records',
            method: "get",
            headers: {
                'Lang': localStorage.getItem('lang') || '',
                Authorization: address
            }
        })
    },

    //最新中奖列表
    wheelLatest(address) {
        return request.request({
            url: '/lucky-wheel/latest',
            method: "get",
            headers: {
                'Lang': localStorage.getItem('lang') || '',
                Authorization: address
            }
        })
    },

    //抽奖次数
    wheelDrawTime(address) {
        return request.request({
            url: '/lucky-wheel/draw-times',
            method: "get",
            headers: {
                'Lang': localStorage.getItem('lang') || '',
                Authorization: address
            }
        })
    },
    
    //权益抽奖
    rightDraw(data, address) {
        return request.request({
            url: '/lucky-wheel/right-draw',
            method: "post",
            headers: {
                'Lang': localStorage.getItem('lang') || '',
                Authorization: address
            },
            data: data
        })
    }
}