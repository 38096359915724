import request from '../utils/request';

export default {
  //反馈
  basicFeedbackDo(data, address) {
    return request.request({
      url: '/basic/feedback/do',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang'),
        Authorization: address
      },
      data: data
    })
  },

  //反馈列表
  basicFeedbackList(data, address) {
    return request.request({
      url: '/basic/feedback/list',
      method: "get",
      headers: {
        'Lang': localStorage.getItem('lang'),
        Authorization: address
      },
      params: data
    })
  },

  //客服反馈
  basicFeedbackReplyList(data, address) {
    return request.request({
      url: '/basic/feedback/reply-list',
      method: "get",
      headers: {
        'Lang': localStorage.getItem('lang'),
        Authorization: address
      },
      params: data
    })
  },

  //简讯列表
  basicNewsLetterList(data, address) {
    return request.request({
      url: '/basic/news-letter/list',
      method: "get",
      headers: {
        'Lang': localStorage.getItem('lang'),
        Authorization: address
      },
      params: data
    })
  },

  //公告列表
  noticeList(data) {
    return request.request({
      url: '/basic/notice/list',
      method: "get",
      headers: {
        'Lang': localStorage.getItem('lang'),
      },
      params: data,
    })
  },


  //新闻列表
  newsList(data, address) {
    return request.request({
      url: '/basic/news/list',
      method: "get",
      headers: {
        Authorization: address,
        'Lang': localStorage.getItem('lang'),
      },
      params: data,
    })
  },

  //新闻列表详情
  basicNewsDetail(data, address) {
    return request.request({
      url: '/basic/news/detail',
      method: "get",
      headers: {
        'Lang': localStorage.getItem('lang'),
        Authorization: address
      },
      params: data
    })
  },

  //最新公告
  basicNoticeLatest(data, address) {
    return request.request({
      url: '/basic/notice/latest',
      method: "get",
      headers: {
        'Lang': localStorage.getItem('lang'),
        Authorization: address
      },
    })
  },
}