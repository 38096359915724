<template>
  <div class="home_page">
    <div class="backtrack"
         @click="switchovers('notice')">
      <img src="@/assets/img/backtrack.png"
           class="backtrack_img" />
      <span></span>
    </div>
    <div class="title">
      <span>{{arr.title}}</span>
    </div>
    <div class="time">
      <span>{{arr.author}}{{ $t('lang.swap300') }}</span>
      <span>{{arr.created_time}}</span>
    </div>
    <div class="text">
      <span>{{arr.content}}</span>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      arr: undefined,
    };
  },
  methods: {
    //跳转
    switchovers (route) {
      if (route == '') return this.$toast(this.$t('lang.swap109'));
      this.$router.push({ name: route });
      // this.$Router.pushPage({
      //   name: route
      // })
    },
  },
  created () {
    this.arr = JSON.parse(this.$route.query.item);
  },
};
</script>

<style lang="less" scoped>
.home_page {
  background: url('~@/assets/img/second_bg.png');
  background-size: 100% 100%;
  padding: 20px;
  min-height: 800px;
}
.backtrack {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  .backtrack_img {
    display: block;
    width: 20px;
    height: 20px;
  }
}
.title {
  margin-top: 20px;
}
.time {
  font-size: 12px;
  color: #999;
  margin: 10px 0 20px;
  span {
    margin-right: 20px;
  }
}
.text {
  font-size: 12px;
  color: #999;
  background: #232b3e;
  border-radius: 6px;
  padding: 10px;
}
</style>