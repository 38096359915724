<template>
  <div class="header">
    <div span="12"
         class="spread">
      <img src="@/assets/img/spread.png"
           class="spread_img"
           @click="menuShow" />
      <img src="@/assets/img/logo.png"
           class="favicon_img" />
    </div>
    <div span="12"
         class="rightItem">
      <!-- <img src="@/assets/img/wallet.png"
      class="wallet_img" />-->
      <div class="settings"
           style>
        <div class="language_switching edition">
          <img class="language"
               :src="editionLanguage.img" />
          <span>{{ editionLanguage.text }}</span>
        </div>
        <div v-show="editionCondition">
          <div class="multilingual">
            <div v-for="(item, index) of edition"
                 :key="index"
                 @click="editionSwitching(item)"
                 :class="item.text == editionLanguage.text ? 'special' : 'prohibit'"
                 class="multilingual_prohibit">
              <img :src="item.img"
                   class="country" />
              <span>{{ item.text }}</span>
            </div>
          </div>
          <div class="overlay"
               @click="handleAction"></div>
        </div>
      </div>
      <div class="addressBox"
           v-if="account">
        <div class="account">{{ getAccount }}</div>
      </div>

      <van-button type="primary"
                  class="loginBtn"
                  v-else
                  @click="handleLogin">{{$t('lang.swap102')}}</van-button>

      <div class="settings">
        <div class="language_switching"
             @click="switchLanguage">
          <img class="language"
               :src="currentImg" />
        </div>
        <div v-show="multilingualCondition">
          <div class="multilingual">
            <div v-for="(item, index) of actions"
                 :key="index"
                 @click="languageSwitching(item)"
                 :class="item.text == currentLanguage ? 'special' : ''">
              <div>
                <img class="country"
                     :src="item.img" />
                <span>{{ item.text }}</span>
              </div>
            </div>
          </div>
          <div class="overlay"
               @click="handleAction"></div>
        </div>
      </div>
    </div>

    <van-popup v-model="show"
               round
               position="left"
               @close="close"
               class="side">
      <div class="menu">
        <div class="user">
          <div class="invitation">
            <div>
              <img src="@/assets/img/invitations.png"
                   class="invitation_img" />
              <span>{{ $t('lang.swap496') }}</span>
            </div>
            <div class="more"
                 @click="toNav('invite')">
              <span>{{$t('lang.swap156')}}</span>
            </div>
          </div>
          <span class="invite_detail">{{ $t('lang.swap450') }}:</span>
          <div class="address">
            <div class="invitation_address">
              <span>{{invite_link || $t('lang.swap373')}}</span>
            </div>
            <div class="copys"
                 @click="copy"
                 :data-clipboard-text="this.invite_link">
              <span>{{ $t('lang.swap503') }}</span>
            </div>
          </div>
        </div>
        <van-collapse v-model="activeNames">
          <van-collapse-item :name="index + 1"
                             v-for="(item, index) of menuList"
                             :key="index"
                             title-class="menu_li"
                             :is-link="false">
            <template #title>
              <div @click="toNav(item.router, 'title')"
                   class="title">
                <div>
                  <img :src="item.image"
                       class="slot_image" />
                  <span class="font-color">{{ $t(item.name) }}</span>
                </div>
                <span style="color: #fff;">></span>
              </div>
            </template>
          </van-collapse-item>
        </van-collapse>
      </div>
    </van-popup>
    <!-- <Sidebar :SidebarShow="SidebarShow" /> -->
    <!-- <Sidebar :pageShow="pageShow" /> -->
  </div>
</template>
<script>
import { mapState } from 'vuex';

// import Sidebar from '@/components/Sidebar.vue';
import axios from 'axios';
import Clipboard from 'clipboard';
import { inviteApi, usApi, newsLetterApi } from '@/api/index';
export default {
  name: 'Header',
  components: {
    // Sidebar,
  },
  data () {
    return {
      showPopover: false,
      price: 0,
      currentLanguage: '',
      currentImg: undefined,
      actions: [
        { text: '中文简体', lang: 'zh-CN', className: 'langPopover', img: require('@/assets/img/china.png') },
        { text: 'English', lang: 'en-US', className: 'langPopover', img: require('@/assets/img/english.png') },
        { text: '中文繁体', lang: 'zh-HK', className: 'langPopover', img: require('@/assets/img/hongkong.png') },
        { text: '한국어', lang: 'hanguo', className: 'langPopover', img: require('@/assets/img/korea.png') },
        { text: '日本語', lang: 'riben', className: 'langPopover', img: require('@/assets/img/japan.png') },
      ],
      edition: [
        { text: 'BSC', img: require('@/assets/img/subtract_logo.png') },
        { text: 'Tron', img: require('@/assets/img/tron.png') },
      ],
      editionLanguage: { text: 'BSC', img: require('@/assets/img/subtract_logo.png') },
      editionCondition: false,
      show: false,
      menuList: [
        {
          image: require('@/assets/img/slider/home.png'),
          name: 'lang.swap97',
          router: 'Index',
        },
        {
          image: require('@/assets/img/slider/mining.png'),
          name: 'lang.swap497',
          router: 'miner',
        },
        {
          image: require('@/assets/img/slider/box.png'),
          name: 'lang.swap498',
          router: 'turntable',
        },
        {
          image: require('@/assets/img/slider/notice.png'),
          name: 'lang.swap499',
          router: 'notice',
        },
        {
          image: require('@/assets/img/slider/email.png'),
          name: 'lang.swap500',
          router: 'feedBack',
        },
        {
          image: require('@/assets/img/slider/understand.png'),
          name: 'lang.swap501',
          router: 'whitePaper',
        },
        {
          image: require('@/assets/img/slider/government.png'),
          name: 'lang.swap502',
          router: '',
        },
        //下拉菜单
        // {
        //   image: require('@/assets/img/home_pages.png'),
        //   name: 'lang.swap85',
        //   router: 'Index',
        // },
        // // {
        // //   image: require('@/assets/img/rechange.png'),
        // //   name: 'lang.swap1',
        // //   router: 'FlashExchange',
        // // },
        // {
        //   image: require('@/assets/img/rightsAndInterests.png'),
        //   name: 'lang.swap81',
        //   router: 'StakePool',
        // },
        // {
        //   image: require('@/assets/img/crowdFunding.png'),
        //   name: 'lang.swap178',
        //   router: 'StartingCenter',
        // },
        // {
        //   image: require('@/assets/img/government.png'),
        //   name: 'lang.swap193',
        //   router: 'govern',
        // },
        // {
        //   image: require('@/assets/img/notices.png'),
        //   name: 'lang.swap128',
        //   router: 'NoticeIndex',
        // },
        // {
        //   image: require('@/assets/img/feedback.png'),
        //   name: 'lang.swap172',
        //   router: 'Feedback',
        // },
        // {
        //   image: require('@/assets/img/understand.png'),
        //   name: 'lang.swap194',
        //   router: 'Understand',
        // },
        // {
        //   image: require('@/assets/img/gameFi.png'),
        //   name: 'lang.swap202',
        //   router: 'GameFi',
        // },
        // {
        //   image: require('@/assets/img/gameFi.png'),
        //   name: 'lang.swap202',
        //   router: 'GameFi',
        // },
      ],
      activeNames: ['0'],
      multilingualCondition: false,
      invite_link: '',
      pageShow: true,
      domain: '',
    };
  },
  computed: {
    ...mapState(['account']),
    getAccount () {
      if (this.account) {
        return '0x...' + this.account.substring(this.account.length - 4, this.account.length);
      } else {
        return '';
      }
    },
  },
  created () {
    this.$i18n.locale = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN';
    // this.currentLanguage = this.$i18n.locale == 'zh' ? '中文' : 'English';
    for (let i in this.actions) {
      if (this.actions[i].lang == this.$i18n.locale) {
        this.currentLanguage = this.actions[i].text;
        this.currentImg = this.actions[i].img;
      }
    }
    this.init();
  },
  mounted () { },
  methods: {
    // 获取邀请地址
    init () {
      if (this.account) {
        this.basicConfig();
      }
    },
    //域名
    basicConfig () {
      newsLetterApi.basicConfig().then(res => {
        if (res.code == 200) {
          this.domain = res.data.domain;
          this.getUserInfo();
        } else {
          this.$toast(res.msg);
        }
      });
    },
    //我的信息
    getUserInfo () {
      usApi.ucInfo(this.account).then(res => {
        if (res.code == 200) {
          // this.userinfo = res.data;
          // this.parent_id = res.data.parent_id;
          if (res.data.parent_id != 0) {
            this.invite_link = this.domain + '/#/Index?invite_code=' + res.data.invite_code;
          }
        } else {
          this.$toast(res.msg);
        }
      });
    },
    // 复制
    copy () {
      if (this.invite_link) {
        var clipboard = new Clipboard('.copys');
        clipboard.on('success', e => {
          this.$toast(this.$t('lang.swap222'));
          clipboard.destroy(); // 释放内存
        });
        clipboard.on('error', e => {
          this.$toast(this.$t('lang.swap223'));
          clipboard.destroy(); // 释放内存
        });
      }
    },
    handleLogin () {
      this.$store.dispatch('setWebProvider');
    },
    editionSwitching (item) {
      this.$toast(this.$t('lang.swap190'));
      // this.editionLanguage = item
      // this.editionCondition = false
    },
    languageSwitching (item) {
      // let lang = this.lang.$i18n.locale === 'zh' ? 'en' : 'zh';
      if (item.lang == 'hanguo' || item.lang == 'riben') {
        this.$toast(this.$t('lang.swap348'));
        return
      }
      this.currentLanguage = item.text;
      this.currentImg = item.img;
      this.$i18n.locale = item.lang;
      localStorage.setItem('lang', item.lang);
      this.multilingualCondition = false;
    },
    changeLaguages () {
      let lang = this.$i18n.locale === 'zh' ? 'en' : 'zh';
      this.$i18n.locale = lang;
    },
    menuShow () {
      this.show = true;
      this.init();
    },
    close () {
      this.show = false;
      // console.log('close');
    },
    toNav (router, title) {
      // if (title) {
      //   if (router != '') {
      //     this.$router.push({ name: router });
      //     this.close();
      //   }
      // } else {
      if (this.$route.name == router) return;
      if (router != '') {
        this.$router.push({ name: router });
        this.close();
      } else {
        this.$toast(this.$t('lang.swap109'));
      }
      // }
    },
    //语言弹窗的状态
    switchLanguage () {
      this.multilingualCondition = !this.multilingualCondition;
    },
    editionSwitchLanguage () {
      this.editionCondition = !this.editionCondition;
    },
    handleAction () {
      this.multilingualCondition = false;
      this.editionCondition = false;
    },
    handleScroll () {
      this.pageShow = !this.pageShow;
    },
  },
  watch: {
    account () {
      this.init();
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  // position: relative;
  height: 50px;
  // box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: space-between;
  // border: 1px solid #F39714;
  background: #000000;
  border-left: 0;
  border-right: 0;
  padding: 0 16px;
  z-index: 99999;
  .rightItem {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .language_switching {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 400;
      color: #595b5b;
      // background: #1263f1;
      border-radius: 16px;
      padding: 4px 10px;
      margin-left: 20px;
    }
    .rightIcon {
      height: 20px;
      width: 20px;
      margin-right: 10px;
      cursor: pointer;
    }
    .loginBtn {
      height: 28px;
      font-weight: bold;
      background: #ffffff;
      // padding: 6px 10px 6px 20px;
      // line-height: 28px;
      color: #333333;
    }
  }
  .wallet_img {
    width: 40px;
    height: 40px;
    transform: translateX(40%) translateY(0);
    z-index: 99;
  }
  .addressBox {
    max-width: 150px;
    font-size: 20px;
    display: flex;
    align-items: center;
  }
  .walletBox {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: -12px;
    z-index: 10;
    .wallet {
      height: 30px;
      width: 30px;
    }
  }
  .account {
    font-family: Kanit, sans-serif;
    font-size: 12px;
    font-weight: bold;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: #000000;
    padding: 6px 20px 6px 20px;
    // line-height: 28px;
    color: #f6bb31;
    font-weight: bold;
    border-radius: 2px;
    border: 1px solid #f6bb31;
  }
  .spread {
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    .favicon_img {
      display: block;
      width: 50px;
      height: 20px;
      margin-left: 10px;
      // margin: 0 10px;
    }
    .spread_img {
      display: block;
      width: 24px;
      height: 24px;
      // margin: 0 10px;
    }
  }
  /deep/ .van-collapse-item,
  .van-hairline--top-bottom {
    border: 0 !important;
    position: static;
  }

  /deep/ .van-cell {
    padding: 0 !important;
    background-color: #000;
  }

  /deep/ .van-collapse-item__content {
    padding: 0;
    position: static;
  }
  /deep/ .van-collapse-item {
    padding: 16px 14px !important;
  }
  /deep/ .van-collapse-item--border::after {
    border: 0 !important;
  }
  .side {
    height: 100%;
    width: 80%;
    // border: 1px solid #ffd0c1;
    .user {
      font-size: 18px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      background: linear-gradient(180deg, #ffc061 0%, #040404 100%);
      // box-shadow: 0px 3px 6px 1px #ffd85e;
      border: 1px solid #ffd361;
      border-radius: 2px;
      padding: 10px 14px;
      margin: 0 14px 30px;
      .invitation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        div {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .invitation_img {
          width: 26px;
          height: 26px;
        }
        .more {
          font-size: 12px;
          border-radius: 16px;
          // border: 1px solid #ffffff;
          padding: 2px 12px;
          color: rgba(255, 255, 255, 0.8);
        }
      }
      .invite_detail {
        width: 52px;
        height: 15px;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.8);
      }
      .address {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        color: #333333;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        margin-top: 5px;
        .invitation_address {
          background: #fff;
          border-radius: 2px;
          padding: 4px 10px;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          white-space: nowrap; //溢出不换行
          width: 75%;
        }
        .copys {
          color: #fff;
          border-radius: 2px;
          padding: 4px 10px;
          margin-left: 10px;
          border: 1px solid #ffffff;
          // width: 58px;
          // height: 27px;
          .span {
            width: 24px;
            height: 17px;
            font-size: 12px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(255, 255, 255, 0.8);
          }
        }
      }
    }
  }
  .menu {
    height: 100%;
    overflow: auto;
    background: #142338;

    border-radius: 0px 16px 16px 0px;
    padding: 14px 0 60px;

    .menu_li {
      background: #15213b !important;
      div {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #333;
      }
      .title {
        display: flex;
        background: #15213b;

        justify-content: space-between;
        align-items: center;
        font-weight: bold;
      }
    }
    .slot_image {
      width: 20px;
      height: 20px;
      margin-right: 18px;
      // background-color: #fff;
    }
    .font-color {
      font-size: 14px;
      font-family: Inter-Regular, Inter;
      color: #ffffff;
    }
    .menu_list {
      // color: #606266;
      font-size: 12px;
      padding: 8px 0 8px 30px;
    }
  }
  .settings {
    display: flex;
    position: relative;
    font-weight: bold;
    font-family: YouSheBiaoTiYuan-Regular, YouSheBiaoTiYuan;
    .language_switching {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;

      border-radius: 16px;
      padding: 0px 8px 0 8px;
      margin-left: 10px;
    }
    .language {
      width: 30px;
      height: 30px;
      padding: 4px;
    }
    .multilingual {
      position: absolute;
      top: 34px;
      right: 0;
      font-size: 14px;
      width: 92px;
      color: #ffffff;
      background: #171717;
      box-shadow: 0px 2px 6px 0px rgba(16, 27, 82, 0.5);
      border-radius: 6px;
      text-align: center;
      padding: 2px;
      z-index: 999;
      div {
        padding: 4px 1px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        .country {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
      }
      .special {
        color: #f39714;
      }
      .prohibit {
        color: #999;
      }
    }
    .overlay {
      position: fixed;
      z-index: 998;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.144);
      margin-top: 48px;
    }
  }
  .edition {
    // border: 1px solid #ffd361;
    padding: 5px 20px !important;
  }
  .multilingual_prohibit {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 6px 0 !important;
  }
}
@media screen and (min-width: 1000px) {
  .header {
    .side {
      height: 100%;
      width: 20%;
      border: 1px solid #ffd0c1;
    }
  }
}
// @media screen and (min-width: 700px) {
//   .header {
//     .spread {
//       cursor: pointer;
//       .favicon_img {
//         // display: none;
//       }
//       .spread_img {
//         display: block;
//         width: 20px;
//         height: 16px;
//         margin: 0 10px;
//       }
//     }
//   }
// }
</style>
