<template>
  <div class="index_popup">
    <div v-show="swapPopupData.swapQuantity =='0'">
      <div class="head">
        <span>{{ $t('lang.swap27') }}</span>
        <img src="@/assets/img/cancel.png"
             class="cancel"
             @click="close" />
      </div>
      <div class="content">
        <div class="currency">
          <div>
            <img :src="fromCur.logoUrl"
                 class="fromCur_img">
            <span>{{ fromCur.symbol }}</span>
          </div>
          <img src="@/assets/img/trade.png"
               class="trade_img">
          <div>
            <img :src="toCur.logoUrl"
                 class="fromCur_img">
            <span>{{ toCur.symbol }}</span>
          </div>
        </div>
        <div class="iconSet">
          <span class="iconSet_text">{{ $t('lang.swap8') }}</span>
          <span>{{ slippage }}%</span>
        </div>
        <div class="slipWrap">
          <span class="slipWrap_text">{{ $t('lang.swap91') }}</span>
          <span v-if="portion <= 0.01">{{less}} 0.01%</span>
          <vspan v-if="portion > 0.01 && portion <= 1">{{portion.toFixed(2)}}%</vspan>
          <span v-if="portion > 1 && portion <= 2">{{portion.toFixed(2)}}%</span>
          <span v-if="portion > 2 && portion <= 5">{{portion.toFixed(2)}}%</span>
          <span v-if=" portion > 5">{{portion.toFixed(2)}}%</span>
        </div>
        <div class="button">
          <van-button type="primary"
                      class="confirmBtn"
                      @click="cancelButton">
            <span>{{ $t('lang.swap90') }}</span>
          </van-button>
        </div>
      </div>
    </div>
    <div v-show="swapPopupData.swapQuantity =='1'">
      <div class="head">
        <span>{{swapPopupData.swapTitle}}</span>
        <img src="@/assets/img/cancel.png"
             class="cancel"
             @click="close" />
      </div>
      <div class="content">
        <div class="img">
          <img src="@/assets/img/exclamatory_mark.png"
               class="exclamatory_mark" />
        </div>
        <div class="text">
          <span>{{swapPopupData.swapContent}}</span>
        </div>
        <div class="button">
          <van-button type="primary"
                      class="confirmBtn"
                      @click="close">
            <span>{{ $t('lang.swap154') }}</span>
          </van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { accSub, significantDigits, accMul, toFixed } from '@/utils/format.js';
export default {
  props: ['swapPopupData', 'fromCur', 'toCur', 'portion',],
  data () {
    return {
      less: '<',
    }
  },
  methods: {
    cancelButton () {
      this.$emit('cancelButton')
    },
    close () {
      this.$emit('switch')
    }
  },
  computed: {
    ...mapState(['slippage',]),
  }
}
</script>

<style lang="less" scoped>
.index_popup {
  width: 350px;
  background: #171717;
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #ffffff;
    padding: 16px 22px;
    .cancel {
      width: 24px;
      cursor: pointer;
    }
  }
  .content {
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.30196078431372547);
    padding: 10px 24px 16px;
    .img {
      display: flex;
      justify-content: center;
      .exclamatory_mark {
        width: 50px;
      }
    }
    .currency {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      color: #ffffff;
      margin: 20px 0 30px;
      .trade_img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }
      div {
        display: flex;
        align-items: center;
      }
      .fromCur_img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 8px;
      }
    }
    .text {
      font-size: 12px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      text-align: center;
      margin: 10px 0 20px;
    }
    .iconSet {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;
      .iconSet_text {
        color: #878995;
      }
    }
    .slipWrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      color: #65fce4;
      margin: 12px 0 20px;
      .slipWrap_text {
        color: #878995;
      }
    }
    .button {
      display: flex;
      justify-content: space-around;
      margin-top: 20px;
      .confirmBtn {
        width: 100%;
        height: auto;
        font-size: 14px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #000000;
        background: linear-gradient(321deg, #fdde34 0%, #65fce4 100%);
        border-radius: 12px;
        border: 0;
        padding: 14px 24px;
      }
    }
  }
}
</style>
