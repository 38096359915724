<template>
  <div class="annal">
    <div class="record">
      <div class="name">
        <span>时间</span>
        <span>奖品</span>
        <span>状态</span>
      </div>
      <div v-if="translateList.length == 0"
           class="nothing">
        <img src="@/assets/img/nothingBlank.png"
             class="nothing_img">
        <!-- <span>{{$t('lang.swap118')}}</span> -->
      </div>
      <van-list v-else
                v-model="loading"
                :finished="finished"
                finished-text=" "
                @load="getList"
                class="list">
        <div v-for="(item,index) of translateList"
             :key="index"
             class="record_li">
          <div class="li_left">
            <div class="left">
              <span>{{item.created_time.substring(0,10)}}</span>
            </div>
            <div class="center">
              <span>{{item.name}}</span>
            </div>
            <div class="right">
              <span>{{item.status_txt}}</span>
            </div>

          </div>
          <!-- <div class="li_right">
          <span class="expenses">4</span>
        </div> -->
        </div>
      </van-list>
    </div>
  </div>

</template>

<script>
import { mapState } from 'vuex';
import { turntableApi } from '@/api/index';
export default {
  data () {
    return {
      translateList: [],
      page: 1, //页码
      pageSize: 10, //页数
      totalPage: 0, //总页数
      loading: false,
      finished: false,
    }
  },
  methods: {
    init(){
      if(this.account){
        this.getList()
      }
    },
    getList(){
      turntableApi.userRecords(this.account).then(res => {
        if (res.code == 200) {
          // this.feedBackList = res.data.list
          this.translateList = this.translateList.concat(res.data.list);
          this.totalPage = Math.ceil(res.data.total_count / this.pageSize);
          this.page++;
          this.loading = false;
          if (this.page > this.totalPage) return (this.finished = true);
        } else {
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    this.init();
  },
  computed: {
    ...mapState(['account']),
  },
  watch: {
    account() {
      this.init();
    },
  },
}
</script>

<style lang="less" scoped>
.annal {
  height: 100%;
  background: url('~@/assets/img/second_bg.png');
  background-size: 100% 100%;
  padding: 20px;
  // min-height: 800px;
}
.record {
  background: #252a3f;
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.11);
  border-radius: 4px;
  padding: 10px 0;
  // margin: 20px;
  .name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 10px;
    border-bottom: 1px solid #2e2e2e;
    span {
      // width: 33%;
      // text-align: center;
      font-size: 14px;
    }
  }
  .choose {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    border-bottom: 3px solid #f39714;
  }
  .line {
    border-bottom: 1px solid #d6e0f2;
  }
  .list {
    padding: 0 10px;
  }
  .record_li {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    // margin-top: 16px;
    padding: 10px 0;
    .li_left {
      display: flex;
      flex: 1;
      // justify-content: space-between;
      // flex-direction: column;
      font-size: 14px;
      color: #d1d1d1;
      .left {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .center {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .right {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
    .li_right {
      display: flex;
      // flex-direction: column;
      // align-items: flex-end;
      font-size: 14px;
      font-weight: bold;
      .income {
        color: #f39714;
      }
      .expenses {
        color: #999;
        font-size: 12px;
        margin-top: 6px;
      }
    }
  }
  .nothing {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    // height: 60px;
    padding-bottom: 40px;
    .nothing_img {
      width: 200px;
    }
  }
}
</style>