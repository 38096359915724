<template>
  <div>
    <div class="planet_popup">
      <!-- <img :src="imageDomain + obj.logo" class="planet_img" /> -->
      <van-image :src="imageDomain + obj.logo" class="planet_img" fit="cover"/>
      <img src="@/assets/img/close.png" class="close_img" @click="closePopup" />
      <div class="header">
        <span v-if="obj.level == 1" class="saturn">{{obj.name}}</span>
        <span v-else-if="obj.level == 2" class="mars">{{obj.name}}</span>
        <span v-else-if="obj.level == 3" class="mercury">{{obj.name}}</span>
        <span v-else-if="obj.level == 4" class="jupiter">{{obj.name}}</span>
        <span v-else-if="obj.level == 5" class="venus">{{obj.name}}</span>
      </div>
      <div class="planet_universal" style="color:#efbb34">
        <span>{{$t('lang.swap465')}}:</span>
        <span v-if="obj.level == 1">{{$t('lang.swap466')}}</span>
        <span v-else-if="obj.level == 2">{{$t('lang.swap467')}}</span>
        <span v-else-if="obj.level == 3">{{$t('lang.swap468')}}</span>
        <span v-else-if="obj.level == 4">{{$t('lang.swap469')}}</span>
        <span v-else-if="obj.level == 5">{{$t('lang.swap470')}}</span>
        <span></span>
      </div>
      <div class="planet_universal">
        <span>{{$t('lang.swap471')}}:</span>
        <span>{{obj.price}}</span>
        <span>USDT</span>
      </div>
      <div class="planet_universal">
        <span>{{$t('lang.swap472')}}:</span>
        <span>{{obj.capacity}}</span>
        <span>VRT</span>
      </div>
      <div class="detriment" @click="toMiner">
        <span>{{$t('lang.swap473')}}</span>
      </div>
    </div>
    <!-- <div class="goMiner" @click="toMiner">
      <div class="botton">
        <span>进入查看</span>
      </div>
    </div>-->
  </div>
</template>

<script>
import { newsLetterApi } from '../../api/index';
export default {
  props: ['obj'],
  data() {
    return {
      imageDomain: '',
    };
  },
  methods: {
    toMiner() {
      this.$router.push({ name: 'miner' });
    },
    closePopup() {
      this.$emit('close');
    },
    basicConfig() {
      newsLetterApi.basicConfig().then(res => {
        if (res.code == 200) {
          // console.log(res.data);
          this.imageDomain = res.data.image_domain;
          // this.basicBanner();
        } else {
          this.$toast(res.msg);
        }
      });
    },
  },
  created() {
    this.basicConfig();
  },
};
</script>

<style lang="less" scoped>
.planet_popup {
  position: relative;
  width: 260px;
  height: 380px;
  background: url('~@/assets/img/planet_popupbg.png');
  background-size: 100% 100%;
  padding: 140px 40px 20px;
  .close_img {
    position: absolute;
    width: 40px;
    height: 40px;
    top: -7px;
    right: -6px;
    z-index: 9999;
  }
  .planet_img {
    position: absolute;
    left: 50%;
    top: 50px;
    width: 60px;
    height: 60px;
    transform: translateX(-50%) translateY(0);
  }
  .header {
    margin-top: 10px;
    text-align: center;
    .mars {
      color: #ff9488;
    }

    .jupiter {
      color: #ea9521;
    }

    .venus {
      color: #eddd98;
    }
    .saturn {
      color: #f1dcf7;
    }
    .mercury {
      color: #06f0ff;
    }
  }
  .planet_universal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    margin-top: 10px;
    span {
      width: 30%;
    }
  }
  .detriment {
    position: absolute;
    left: 50%;
    bottom: 35px;
    width: 90px;
    text-align: center;
    // height: 60px;
    font-size: 12px;
    transform: translateX(-50%) translateY(0);
  }
  .goMiner {
    position: absolute;
    left: 50%;
    bottom: 28px;
    width: 90px;
    text-align: center;
    // height: 60px;
    font-size: 12px;
    transform: translateX(-50%) translateY(0);
  }
}
.goMiner {
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .botton {
    padding: 5px 10px;
    border-radius: 3px 3px 3px 3px;
    border: 1px solid #18d7fc;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-size: 14px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #18d7fc;
    }
  }
}
</style>